import React from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import channelSelection from '../assets/img/channel_selection.png';
import './common.scss';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const validationSchema = yup.object().shape({
  channel: yup
    .object()
    .shape({
      value: yup.string().required(),
      label: yup.string().required(),
    })
    .nullable()
    .required('Channel is required'),
});

const GetChannels: React.FC = () => {
  const channels = useSelector((state: any) => state.cartreducer.channelData);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const channelOptions = channels?.map((channel: any) => ({
    value: channel.uid,
    label: channel.name,
  }));

  return (
    <>
      <h5 className="text-center mt-3 mr-3">Select Channel</h5>
      <div className="centerItems mt-3">
        <img src={channelSelection} alt="Select Channel" />
      </div>

      <Formik
        initialValues={{ channel: null }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          dispatch({ type: 'CHANNEL_UID', payload: values.channel });
          navigate(`${process.env.PUBLIC_URL}/conversations`);
        }}
      >
        {({ setFieldValue }) => (
          <Form>
            <div className="centerItems mt-3">
              <Field name="channel">
                {() => (
                  <Select
                    options={channelOptions}
                    placeholder="Select Channel"
                    isClearable={true}
                    onChange={(selectedOption: any) =>
                      setFieldValue('channel', selectedOption ? selectedOption : null)
                    }
                    styles={{
                      container: (provided) => ({
                        ...provided,
                        width: '300px',
                      }),
                    }}
                  />
                )}
              </Field>
            </div>
            <div className="text-center mt-3">
              <ErrorMessage name="channel" component="div" className="text-danger" />
            </div>

            <div className="text-center mt-3">
              <Button type="submit" className="sendButton setChannel">
                Start Conversation
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default GetChannels;
