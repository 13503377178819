import React, { useEffect, useState,useRef } from "react";
import { Row, Col, Label } from "reactstrap";
import { Field, ErrorMessage, Form, FieldProps, FormikProps } from "formik";
import Select from "react-select";
import "./WhatsAppChannel.scss";
import ChannelDetails from "./ChanelDetails";
import {
  getWhatsappProfile,
} from "../../services/WhatsAppchannelService";
import { useSelector } from "react-redux";
import { WhatsAppCategory } from "../../common/category";
import { useWhtasappData } from "./whatsappContext";
import { useFormikContext } from "formik";
import { Form as FormBootstrap } from "react-bootstrap";

export interface WhatsAppPrfileClasses<T> {
  component: T;
}

export interface WhatsAppPrfileProps {
  WhatsappProData: (getData: any) => void;
  getFilepath: (fileData: any) => void;
  values: any;
  isInvalidAccess: boolean;
}

export const WhatsAppPrfile = (props: WhatsAppPrfileProps) => {
  const { values } = props;
  const { isInvalidAccess } = props;
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [text, setText] = useState('');
  const { data, setWhatsAppData } = useWhtasappData();
  const { setFieldValue } = useFormikContext();
  const [profileImg, setProfilePath] = useState(data.profile);
  const [isLoading,setLoading] = useState(false);
  const [whatsappProfile, getProfileData] = useState({
    address: "",
    description: "",
    email: "",
    tagline: "",
    website: [],
    vertical: "",
    profilePictureLink: "",
  });
  
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const channelData = useSelector((state: any) => state.cartreducer.channelUid);

  const whatsapp_profile = async () => {
    setLoading(true);
    const channelId = channelData?.value;
    if (channelId) {
      const profileData = await getWhatsappProfile(channelId);
      getProfileData(profileData);
      setLoading(false)
    }
    if (data) {
      setProfilePath(data.profile);
    }
  };

  const handleFileChange = async (files: FileList | null) => {
    if (files && files.length > 0) {
      const file = files[0];
      props.getFilepath(file as any);
      const updatedFiles: { imgUrl: string; imgName: string; image: File }[] =
        [];
      for (let i = 0; i < files.length; i++) {
        const newFile = files[i];
        const imgUrl = URL.createObjectURL(newFile);
        updatedFiles.push({
          imgUrl: imgUrl,
          imgName: newFile.name,
          image: newFile,
        });
        setProfilePath(imgUrl);
        getProfileData((prevState) => ({
          ...prevState,
          profilePictureLink: imgUrl,
        }));
        setWhatsAppData({ profile: imgUrl });
      }
    }
  };
  useEffect(() => {
    whatsapp_profile();
  }, [channelData]);

  useEffect(() => {
    if (whatsappProfile) {
      setProfilePath(whatsappProfile.profilePictureLink);
      props.WhatsappProData(whatsappProfile);
    }
  }, [whatsappProfile, data]);

  const handleOpenFileDialog = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  useEffect(() => {
    adjustTextareaHeight();
  }, [text]);
const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
    }
  };
  return (
    <div>
       {isLoading ? 
    <div className="centerItemsWithWidth">
    <div className="spinner-border text-success" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>:
      <Form>
          <Row className="pt-4">
            <Col
              md="2"
              lg="2"
              xl="2"
              className="whatsapp-img-container"
            >
              {profileImg ? (
                <img src={profileImg} className="whatsapp-profile-img"  onClick={handleOpenFileDialog}/>
              ) : (
                <span className="whatsapp-profile-sample" onClick={handleOpenFileDialog}>
                  Upload your Logo
                </span>
              )}
            </Col>
            <Col xs="12" md="6" lg="6" xl="6" className="upload-file-container">
              <div>
                <div className='form-label mb-0' style={{color:'#808080'}}> Accepted file type: jpeg, png</div>
                <div className='form-label mt-0 text-center' style={{color:'#808080'}}>
                within 1MB size
                  </div>
                <Label className={`upload-select upload-files w-100 ${isInvalidAccess ? "disabledState" : ""} `}>
                  <input
                    ref={fileInputRef}
                    type="file"
                    className="hideFileInput"
                    accept=".jpeg,.jpg,.png"
                    onChange={(event) => handleFileChange(event.target.files)}
                    disabled={isInvalidAccess}
                  />
                  Upload files
                </Label>
              </div>
            </Col>
          </Row>
          <div className="profile-fields">
            <Row className="">
              <Col sm="12" md="6" lg="6" xl="6">
                <Label>Business Description</Label>
                <Field
                  type="text"
                  name="businessDescription"
                  id="businessDescription"
                  onChange={(event: any) => {
                    const { name, value } = event.target;
                    setFieldValue(name, value);
                  }}
                  className="form-control "
                  placeholder="Enter  business description"
                  value={values.businessDescription ? values?.businessDescription :''}
                />
                {/* <ErrorMessage
                  className="text-danger pt-1"
                  name="businessDescription"
                  component="div"
                /> */}
              </Col>
              <Col sm="12" md="6" lg="6" xl="6">
                <Label>Address</Label>
                <Field name="address">
                        {({ field }: FieldProps & { form: FormikProps<any> }) => (
                <textarea
                  ref={textareaRef}
                  {...field}
                  id="address"
                  onChange={(event: any) => {
                    const { name, value } = event.target;
                    setFieldValue(name, value);
                    adjustTextareaHeight();
                  }}
                  className="form-control p-2 addressTxt"
                  placeholder="Enter address"
                  rows={1}
                  value = {values.address ? values.address :'' }
                  />
                )}
                </Field>
                {/* <ErrorMessage
                  className="text-danger pt-1"
                  name="address"
                  component="div"
                /> */}
              </Col>
            </Row>
            <Row>
              <Col sm="12" md="6" lg="6" xl="6">
                <Label>Category</Label>
                <Field name="category">
                  {({ field, form }: any) => (
                    <Select
                      {...field}
                      name="category"
                      options={
                        WhatsAppCategory?.map((category: any) => ({
                          value: category.label,
                          label: category.label,
                        })) ?? []
                      }
                      className=""
                      value={{
                        label: values.category,
                        value: values.category,
                      }}
                      onChange={(selectedOption: any) => {
                        // countryBusiness(selectedOption.label);
                        setFieldValue("category", selectedOption.value);
                      }}
                    />
                  )}
                </Field>
              </Col>
              <Col sm="12" md="6" lg="6" xl="6">
                <Label>Website URL <span className="redstar_required"></span></Label>
                <Field
                  type="text"
                  name="websiteUrl"
                  id="websiteUrl"
                  onChange={(event: any) => {
                    const { name, value } = event.target;
                    setFieldValue(name, value);
                  }}
                  className="form-control "
                  placeholder="Enter website URL"
                  value={values.websiteUrl ? values.websiteUrl:''}
                />
                <ErrorMessage
                  className="text-danger pt-1"
                  name="websiteUrl"
                  component="div"
                />
              </Col>
            </Row>
            <Row>
              <Col sm="12" md="6" lg="6" xl="6">
                <Label>Email  <span className="redstar_required"></span></Label>
                <Field
                  type="text"
                  name="email"
                  id="email"
                  onChange={(event: any) => {
                    const { name, value } = event.target;
                    setFieldValue(name, value);
                  }}
                  className="form-control "
                  placeholder="Enter email address"
                  value={values.email ? values.email :''}
                />
                <ErrorMessage
                  className="text-danger pt-1"
                  name="email"
                  component="div"
                />
              </Col>
              <Col sm="12" md="6" lg="6" xl="6">
                <Label>Tag line</Label>
                <Field
                  type="text"
                  name="tagline"
                  id="tagline"
                  onChange={(event: any) => {
                    const { name, value } = event.target;
                    setFieldValue(name, value);
                  }}
                  className="form-control "
                  placeholder="Enter tagline"
                  value={values.tagline ? values.tagline:''}
                />
                {/* <ErrorMessage
                  className="text-danger pt-1"
                  name="tagline"
                  component="div"
                /> */}
              </Col>
            </Row>
          </div>
      </Form>
}
    </div>
  );
};

export default WhatsAppPrfile;
