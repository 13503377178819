import { Formik } from "formik";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import * as yup from "yup";
import DatePicker from "react-date-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import Avatar from "react-avatar";
import moment from "moment";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import ReactSelect from "react-select";
import styles from "./AppointmentForm.module.scss";
import * as appointmentService from "../../services/appointmentService";
import Contacts from "../Conversations/contact";
import { toast, toastAlert } from "../../common/alert";
import { Alert } from "react-bootstrap";
import {
  getTimeSlots,
  GetTimeSlotInterface,
} from "../../services/appointmentService";

interface FormData {
  name: string;
  description: string;
  date: string;
  appoinment: string | { startTime: string | null; endTime: string | null };
  status: "PENDING" | "OPEN" | "CLOSE" | "CANCELLED" | "COMPLETED";
  contact: { id: number };
  settings: {
    enableContactReschedule: boolean;
  };
  emergencyMember: boolean;
}

type ErrorProps = {
  message: string;
};

function Error({ message }: ErrorProps) {
  return (
    <div className="invalid-feedback" style={{ display: "block" }}>
      {message}
    </div>
  );
}

interface ITimeSlot {
  startTime: string;
  endTime: string;
}

interface SelectTimeSlot {
  startTime: string;
  endTime: string;
}

export function generateTimeSlots(calendar: {
  availableHour: ITimeSlot[];
  duration: number;
  bufferTime: number;
}): ITimeSlot[] {
  const timeSlots: ITimeSlot[] = [];

  calendar.availableHour.forEach((hour) => {
    let currentTime = moment(hour.startTime, "HH:mm:ss");

    while (true) {
      const endTime = currentTime
        .clone()
        .add(calendar.duration, "milliseconds");

      // Change isSameOrAfter to isAfter to include the last slot where endTime equals hour.endTime
      if (endTime.isAfter(moment(hour.endTime, "HH:mm:ss"))) {
        break;
      }

      timeSlots.push({
        startTime: currentTime.format("HH:mm:ss"),
        endTime: endTime.isSameOrAfter(moment(hour.endTime, "HH:mm:ss"))
          ? hour.endTime
          : endTime.format("HH:mm:ss"),
      });

      // Here we need to check if we should add bufferTime or not
      if (!endTime.isSame(moment(hour.endTime, "HH:mm:ss"))) {
        currentTime = endTime.clone().add(calendar.bufferTime, "milliseconds");
      } else {
        // If the endTime is same as the hour.endTime, we do not add the bufferTime
        currentTime = endTime;
      }
    }
  });
  return timeSlots;
}

type AppointmentFormProps = {
  onCancel: () => void;
  onSubmit: () => void;
  create?: boolean;
  data?: any;
  memId?: any;
  memUId?: any;
  calendar: any;
  holidayList: undefined | appointmentService.IHolidayList;
};

export type IgnoreDate = { dateStr: string; strict: boolean };

function AppointmentForm({
  data,
  onSubmit,
  onCancel,
  create,
  calendar,
  memId,
  memUId,
  holidayList,
}: AppointmentFormProps) {
  const [openSelectCotnact, setOpenSelectContact] = useState(false);
  const [isEmergency, setisEmergency] = useState(false);
  const [openAddCotnact, setOpenAddContact] = useState(false);

  const [contact, setContact] = useState<null | any>(null);
  const [timeslotdata, setTimeSlotData] = useState<SelectTimeSlot[]>([]);
  const [valuedata, setValuedata] = useState<SelectTimeSlot | null>(null);
  const [previousValue, setPreviousValue] = useState({
    startTime: null,
    endTime: null
  });
  const timeref = useRef<SelectTimeSlot[]>([]);
  const [dateValue, setDateValue] = useState<{
    startTime: string;
    endTime: string;
  }>();
  const [test, setTest] = useState(false);

  const channelData = useSelector((state: any) => state.cartreducer.channelUid);
  const channelId = channelData?.value;

  const fetchData = async (selectedDate: string) => {
    const requestData: GetTimeSlotInterface = {
      date: selectedDate,
      memberUids: [memUId.toString()],
    };
    try {
      const data = await getTimeSlots(requestData);
      const gettingData: SelectTimeSlot[] = data.map((item: any) => ({
        startTime: moment(item.startTime, ["hh:mm A", "HH:mm:ss"]).format(
          "HH:mm:ss"
        ),
        endTime: moment(item.endTime, ["hh:mm A", "HH:mm:ss"]).format(
          "HH:mm:ss"
        ),
      }));
      //   setValuedata(gettingData[0])
      setTimeSlotData(gettingData);
      timeref.current = gettingData;

      return gettingData[0];
    } catch (err) {
    } finally {
    }
  };
  //   console.log(fetchData(moment().add(1, 'days').format('YYYY-MM-DD')));

  const timeSlot = useMemo(() => {
    if (calendar) {
      return generateTimeSlots(calendar);
    }
    return null;
  }, [calendar]);

  const filteredData = timeSlot?.filter((slot: any) =>
    timeref.current.some(
      (enabledslot: any) =>
        enabledslot.startTime === slot.startTime &&
        enabledslot.endTime === slot.endTime
    )
  );

  const getFalseDays = (
    startDate: string,
    endDate: string,
    daysOfWeek: boolean[]
  ): string[] => {
    const falseDays: string[] = [];
    const start = new Date(startDate);
    const end = new Date(endDate);

    let currentDate = new Date(start);
    while (currentDate <= end) {
      const dayOfWeek = (currentDate.getDay() + 6) % 7;
      if (!daysOfWeek[dayOfWeek]) {
        falseDays.push(currentDate.toISOString().split("T")[0]);
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return falseDays;
  };
  const ignoreDates = useMemo(() => {
    const dates: IgnoreDate[] = [];

    if (holidayList)
      holidayList.listItem.forEach((item) =>
        dates.push({
          dateStr: item.date,
          strict: false,
        })
      );
    calendar?.unavailableDate?.forEach((dateStr: string) =>
      dates.push({
        dateStr,
        strict: true,
      })
    );
    const { startDate, endDate } = calendar?.dateRange;
    if (calendar?.availableHour && calendar?.availableHour?.length > 0) {
      const combinedAvailableDays = Array(7).fill(false);

      calendar?.availableHour?.forEach((availableHour: any) => {
        availableHour?.day.forEach((day: boolean, index: number) => {
          combinedAvailableDays[index] = combinedAvailableDays[index] || day;
        });
      });

      const falseDays = getFalseDays(startDate, endDate, combinedAvailableDays);

      falseDays.forEach((dateStr) =>
        dates.push({
          dateStr,
          strict: true,
        })
      );
    }

    return dates;
  }, [holidayList, calendar]);

  const defaultFormData = useMemo(() => {
    if (data?.original) {
      return {
        ...data.original,
        appoinment: {
          startTime: data?.original?.scheduledAt?.startTime,
          endTime: data?.original?.scheduledAt?.endTime,
        },
        contact: data?.original?.contact
          ? {
              id: data?.original?.contact.id,
            }
          : {
              id: 0,
            },
        emergencyMember: data?.original?.emergencyMember,
      };
    }
    if (data) {
      return {
        ...data,
        appoinment: {
          startTime: data?.scheduledAt?.startTime,
          endTime: data?.scheduledAt?.endTime,
        },
        contact: data?.contact
          ? {
              id: data?.contact.id,
            }
          : {
              id: 0,
            },
        emergencyMember: data?.emergencyMember,
      };
    } else {
      return {
        contact: {
          id: 0,
        },
        name: "",
        description: "",
        date: moment().format("YYYY-MM-DD"),
        appoinment: null,
        status: "PENDING",
        emergencyMember: false,
        settings: {
          enableContactReschedule:
            calendar.defaultSettings.enableContactReschedule,
        },
      };
    }
  }, [timeslotdata]);
  useEffect(() => {
    fetchData(defaultFormData?.date);
  }, []);

  const memberId = useSelector((state: any) => state.cartreducer.business?.id);
  const memberUid = useSelector(
    (state: any) => state.cartreducer.business?.uid
  );

  const onSubmitHandler = async (values: FormData) => {
    const currentTime = moment().toISOString();

    const transformedData = {
      ...values,
      appoinment: previousValue,
      member: {
        id: memId,
        uid: memUId,
      },
      scheduledAt: {
        date: values?.date,
        startTime: values.appoinment
          ? (
              values.appoinment as {
                startTime: string | null;
                endTime: string | null;
              }
            ).startTime
          : null,
        endTime: values.appoinment
          ? (
              values.appoinment as {
                startTime: string | null;
                endTime: string | null;
              }
            ).endTime
          : null,
      },
      createdat: currentTime,
    };
    if (
      !values.emergencyMember &&
      values.appoinment === null &&
      values.status !== "CANCELLED"
    ) {
      toastAlert("top-end", "Time is required", "error");
      return;
    }

    const today = moment().format("YYYY-MM-DD");
    const currentDate = moment().format("HH:mm:ss");

    let isValid: boolean = true;
    if (
      transformedData.date === today &&
      dateValue &&
      dateValue.startTime < currentDate
    ) {
      isValid = false;
    }

    let isPendingValid: boolean = true;

    if (moment(transformedData.date).isAfter(today) || moment(transformedData.date).isSame(today)) {
      isPendingValid = true;
    } else {
      isPendingValid = false;
    }

    try {
      if (Boolean(create) && isValid) {
        await appointmentService.createAppointment(transformedData, channelId);
        toast("success", "Appointment Created Successfully");
      } else if(transformedData.status === "PENDING" && !isPendingValid) {
        toast("error", "Please select valid date and time slot for book an appointment");
      }
      else if (isValid || transformedData.status === "COMPLETED") {
        await appointmentService.updateAppointment(
          {
            ...data?.original?.appointment,
            ...transformedData,
          },
          channelId
        );
        onCancel();
        toast("success", "Appointment Updated Successfully");
      } else if (
        (transformedData.date === today &&
        dateValue &&
        dateValue.startTime < currentDate)
      ) {
        toast(
          "error",
          "Please select a time later than the current time for today's appointments."
        );
      }

      if (isValid) {
        onCancel();
      }
    } catch (error) {
      const appointmentDateTime = new Date(
        transformedData?.date +
          "T" +
          (typeof transformedData?.appoinment === "object"
            ? transformedData?.appoinment?.startTime
            : "")
      );
      const currentDate = new Date();
      if (appointmentDateTime < currentDate) {
        toast("error", "Appointment date should be greater than current date");
      } else {
        toast(
          "error",
          "message" in (error as any).response.data
            ? (error as any).response.data.message
            : String(
                "Appointment failed to " + Boolean(create) ? "create" : "update"
              )
        );
      }
    }
    onSubmit();
  };

  const schema = useMemo(() => {
    return yup.object().shape({
      contact: yup.object().shape({
        id: yup
          .number()
          .required("Contact is required")
          .min(1, "Contact is required"),
      }),
      name: yup
        .string()
        .max(50, "Appointment Name cannot be longer than 50 characters")
        .required("Appointment Name is required"),
      // description: yup.string().required('Description is required'),
      date: test
        ? yup
            .string()
            .nullable(true)
            .test("is-available", "Date is Unavailable", (dateString) => {
              // Allow null or empty without checking unavailable dates
              if (!dateString) return true;
              return !calendar.unavailableDate.includes(dateString);
            })
        : yup
            .string()
            .required("Date is required")
            .test("is-available", "Date is Unavailable", (dateString) => {
              return !calendar.unavailableDate.includes(dateString);
            }),

      // appoinment: isEmergency
      //     ? yup.mixed().nullable(true).oneOf([null], 'Appointment must be null') // Allows null if test is true
      //     : yup.object().shape({
      //         startTime: yup.string().required('Start Time is required').min(1, 'Start Time is required'),
      //         endTime: yup.string().required('End Time is required'),
      //     }),
      status: yup.string().required("Status is required"),
      settings: yup.object().shape({
        enableContactReschedule: yup.boolean(),
      }),
    });
  }, [test]);
  const today = useMemo(() => moment().startOf("day"), []);
  const parsedIgnoreDates = useMemo(() => {
    return ignoreDates.map((ignoreDateObj) => ({
      ...ignoreDateObj,
      parsedDate: moment(ignoreDateObj.dateStr, "YYYY-MM-DD"),
    }));
  }, [ignoreDates]);

  const isDateIgnored = (date: any) => {
    return parsedIgnoreDates.some((ignoreDateObj) => {
      const isStrictMatch =
        ignoreDateObj.strict && date.isSame(ignoreDateObj.parsedDate, "day");
      const isNonStrictMatch =
        !ignoreDateObj.strict &&
        date.month() === ignoreDateObj.parsedDate.month() &&
        date.date() === ignoreDateObj.parsedDate.date();
      return isStrictMatch || isNonStrictMatch;
    });
  };

  const isTileDisabled = (tile: any) => {
    if (tile.view !== "month") return false;

    const tileDate = moment(tile.date);
    const isToday = tileDate.isSame(today, "day");
    const isTodayIgnored = parsedIgnoreDates.some((dateObj) => {
      return dateObj.strict
        ? today.isSame(dateObj.parsedDate, "day")
        : today.month() === dateObj.parsedDate.month() &&
            today.date() === dateObj.parsedDate.date();
    });

    if (isToday && !isTodayIgnored) return false;
    return tileDate.isBefore(today) || isDateIgnored(tileDate);
  };

  useEffect(() => {
    if (data?.contact) {
      setContact(data?.contact);
    }
  }, [data]);

  return (
    <Container>
      <Formik
        validationSchema={schema}
        onSubmit={onSubmitHandler}
        initialValues={defaultFormData as FormData}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          touched,
          errors,
          setFieldValue,
          dirty
        }) => {
          setDateValue(
            values?.appoinment as
              | { startTime: string; endTime: string }
              | undefined
          );
          const startDate = moment(calendar.dateRange.startDate).startOf("day");
          const now = moment().startOf("day");

          const dateToLog = now.isBefore(startDate)
            ? startDate.toDate()
            : now.toDate();

          const handleSomeEvent = () => {
            if (values.status === "CANCELLED") {
              setTest(true);
              setFieldValue("appoinment", null);
              setFieldValue("date", null);
            }
          };

          return (
            <Form onSubmit={handleSubmit}>
              {contact && !create && (
                <div>
                  <Label className={styles.closeLabel}>
                    Contact<span className="required"></span>
                  </Label>
                  <div className={styles.selectedContact}>
                    <div className={styles.data}>
                      <Avatar
                        fgColor="#fff"
                        name={contact.name}
                        round
                        size="25"
                        textSizeRatio={2.5}
                      />
                      <span className={styles.name}>{contact.name}</span>
                    </div>
                  </div>
                </div>
              )}

              {openSelectCotnact ? (
                <div className="mt-4">
                  <Contacts
                    // noCaption
                    handleClose={() => setOpenSelectContact(false)}
                    status="SELECT"
                    onSelect={(contact: any) => {
                      setContact(contact);
                      setFieldValue("contact.id", contact.id);
                      setOpenSelectContact(false);
                    }}
                  ></Contacts>
                </div>
              ) : (
                <>
                  {!calendar ? (
                    <Alert color="info">
                      <FontAwesomeIcon icon={faInfoCircle} className="mr-1" />
                      Set up your calendar in order to update the appointment
                      schedule.
                    </Alert>
                  ) : null}
                  {create ? (
                    <FormGroup className="mt-2">
                      <Label>
                        Contact <span className="required"></span>
                      </Label>
                      {values.contact.id < 1 ? (
                        <>
                          <Button
                            // color='primary'
                            className="d-block signUpBtn"
                            onClick={() => setOpenSelectContact(true)}
                          >
                            Select Contact
                          </Button>
                          {/* <Button
                                                    // color='primary'
                                                    className='d-block signUpBtn'
                                                    onClick={() => setOpenAddContact(true)}
                                                >
                                                    Add Contact
                                                </Button> */}
                        </>
                      ) : contact ? (
                        <div className={styles.selectedContact}>
                          <div className={styles.data}>
                            <Avatar
                              fgColor="#fff"
                              name={contact.name}
                              round
                              size="25"
                              textSizeRatio={2.5}
                            />
                            <span className={styles.name}>{contact.name}</span>
                          </div>
                          <Button
                            type="button"
                            color="white"
                            onClick={() => {
                              setContact(null);
                              setFieldValue("contact.id", 0);
                            }}
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </Button>
                        </div>
                      ) : null}
                      {touched.contact?.id && errors.contact?.id ? (
                        <Error message={errors.contact?.id} />
                      ) : null}
                    </FormGroup>
                  ) : null}
                  <FormGroup className="mt-2">
                    <Label>
                      Appointment Name <span className="required"></span>
                    </Label>
                    <Input
                      type="text"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      valid={touched.name && !errors.name}
                      invalid={Boolean(touched.name && errors.name)}
                    />
                    {Boolean(touched.name && errors.name) ? (
                      <FormFeedback>{errors.name}</FormFeedback>
                    ) : null}
                  </FormGroup>
                  <FormGroup className="mt-2">
                    <Label>Description</Label>
                    <Input
                      type="textarea"
                      name="description"
                      value={values.description}
                      maxLength={150}
                      onChange={handleChange}
                      valid={touched.description && !errors.description}
                      invalid={Boolean(
                        touched.description && errors.description
                      )}
                    />
                    {/* {Boolean(touched.description && errors.description) ? (
                                            <FormFeedback>
                                                {errors.description}
                                            </FormFeedback>
                                        ) : null} */}
                  </FormGroup>
                  {!create && (
                    <FormGroup className="mt-2">
                      <Label>Selected Contact</Label>
                      <Input
                        type="text"
                        name="selectedcontact"
                        disabled={true}
                        value={
                          data?.original
                            ? data?.original?.contact?.name
                            : data?.contact?.name
                        }
                        maxLength={150}
                      />
                    </FormGroup>
                  )}
                  <Row className="mt-2">
                    {values.status !== "CANCELLED" &&
                      values.status !== "COMPLETED" && (
                        <Col md={6}>
                          <FormGroup>
                            <Label>
                              Appointment Date{" "}
                              <span className="required"></span>
                            </Label>
                            <div
                              className={(() => {
                                const cssStyles = [
                                  "form-control",
                                  styles.calenderInput,
                                ];
                                if (!calendar) cssStyles.push(styles.disabled);
                                return cssStyles;
                              })().join(" ")}
                            >
                              {/* <FontAwesomeIcon icon={faCalendar} className='mr-2' /> */}
                              {calendar ? (
                                <DatePicker
                                  dayPlaceholder="DD"
                                  monthPlaceholder="MM"
                                  yearPlaceholder="YYYY"
                                  tileDisabled={isTileDisabled}
                                  // tileDisabled={tile => {
                                  //     return tile.view === 'month' && ignoreDates.some(ignoreDateObj => {
                                  //         const a = moment(tile.date);
                                  //         const b = moment(ignoreDateObj.dateStr, 'YYYY-MM-DD');
                                  //         const today = moment().startOf('day');

                                  //         const isToday = a.isSame(today, 'day');
                                  //         const isTodayIgnored = ignoreDates.some(dateObj => {
                                  //             const ignoreDate = moment(dateObj.dateStr, 'YYYY-MM-DD');
                                  //             return dateObj.strict ? today.isSame(ignoreDate, 'day') : (today.month() === ignoreDate.month() && today.date() === ignoreDate.date());
                                  //         });

                                  //         if (isToday && !isTodayIgnored) {
                                  //             return false;
                                  //         }
                                  //         if (ignoreDateObj.strict && a.format('YYYY-MM-DD') === ignoreDateObj.dateStr) {
                                  //             return true;
                                  //         } else if (!ignoreDateObj.strict && (a.month() === b.month() && a.date() === b.date())) {
                                  //             return true;
                                  //         }
                                  //         return a.isBefore(moment());
                                  //     });
                                  // }}

                                  onChange={(date) => {
                                    if (date instanceof Date) {
                                      const selecteddate: string =
                                        moment(date).format("YYYY-MM-DD");
                                      fetchData(selecteddate);

                                      setFieldValue(
                                        "date",
                                        moment(date).format("YYYY-MM-DD")
                                      );
                                    } else {
                                      setFieldValue("date", "");
                                    }
                                  }}
                                  minDate={moment(values.date).isBefore(new Date()) ? undefined : moment().startOf('day').toDate()}
                                  // maxDate={
                                  //   calendar.dateRange.endDate
                                  //     ? new Date(calendar.dateRange.endDate)
                                  //     : undefined
                                  // }
                                  // calendarIcon={true}
                                  calendarIcon={
                                    <FontAwesomeIcon icon={faCalendar} />
                                  }
                                  value={
                                    values.date
                                      ? moment(values.date).format("YYYY-MM-DD")
                                      : undefined
                                  }
                                />
                              ) : (
                                <>{moment(values.date).format("YYYY-MM-DD")}</>
                              )}
                            </div>
                            {Boolean(touched.date && errors.date) &&
                            typeof errors.date === "string" ? (
                              <Error message={errors.date} />
                            ) : null}
                          </FormGroup>
                        </Col>
                      )}
                    {values.status !== "CANCELLED" &&
                      values.status !== "COMPLETED" &&
                      !values.emergencyMember && (
                        <Col md={6}>
                          <FormGroup className="">
                            <Label>
                              Time Slot <span className="required"></span>
                            </Label>
                            {calendar && timeSlot ? (
                              <ReactSelect
                                styles={{
                                  menuList: (baseStyles, state) => ({
                                    ...baseStyles,
                                    maxHeight: 180,
                                  }),
                                }}
                                value={(() => {
                                  const slot = values.appoinment as any;
                                  const time = timeslotdata[0];
                                  if (slot) {
                                    return {
                                      value: JSON.stringify(slot),
                                      label: `${moment(
                                        slot.startTime,
                                        "HH:mm:ss"
                                      ).format("h:mm a")} to ${moment(
                                        slot.endTime,
                                        "HH:mm:ss"
                                      ).format("h:mm a")}`,
                                    };
                                  }
                                  return null;
                                })()}
                                onChange={(selectedOption: any) => {
                                    const previousValue = values.appoinment as any;
                                    const newValue = JSON.parse(selectedOption.value);
                                    
                                    if (newValue !== previousValue) {
                                      setPreviousValue(newValue);
                                    } else {
                                      setPreviousValue({
                                        startTime: null,
                                        endTime: null
                                      });
                                    }

                                  setFieldValue(
                                    "appoinment",
                                    JSON.parse(selectedOption.value)
                                  );
                                }}
                                // options={timeslotdata}
                                options={timeslotdata?.map((slot) => ({
                                  value: JSON.stringify(slot),
                                  label: `${moment(
                                    slot?.startTime,
                                    "HH:mm:ss"
                                  ).format("h:mm a")} to ${moment(
                                    slot.endTime,
                                    "HH:mm:ss"
                                  ).format("h:mm a")}`,
                                }))}
                              />
                            ) : (
                              <div
                                className={[
                                  "form-control",
                                  styles.calenderInput,
                                  styles.disabled,
                                ].join(" ")}
                              >
                                {moment(
                                  (values.appoinment as any).startTime,
                                  "HH:mm:ss"
                                ).format("h:mm a")}{" "}
                                to{" "}
                                {moment(
                                  (values.appoinment as any).endTime,
                                  "HH:mm:ss"
                                ).format("h:mm a")}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                      )}
                  </Row>
                  {!create ? (
                    <FormGroup className="mt-2">
                      <Label>
                        Status <span className="required"></span>
                      </Label>
                      <Input
                        type="select"
                        name="status"
                        value={values.status}
                        onChange={handleChange}
                        valid={touched.status && !errors.status}
                        invalid={Boolean(touched.status && errors.status)}
                        disabled={values.contact.id === 0}
                      >
                        {["PENDING", "COMPLETED", "CANCELLED"].map((val) => (
                          <option key={val} value={val}>
                            {val.charAt(0).toUpperCase()}
                            {val.slice(1, val.length).toLowerCase()}
                          </option>
                        ))}
                      </Input>
                      {Boolean(touched.status && errors.status) ? (
                        <FormFeedback>{errors.status}</FormFeedback>
                      ) : null}
                    </FormGroup>
                  ) : null}
                  <div className="my-2">
                    <h5>Settings</h5>
                  </div>
                  <FormGroup switch className="my-3">
                    <Input
                      type="switch"
                      name="settings.enableContactReschedule"
                      checked={values.settings.enableContactReschedule}
                      onClick={handleChange}
                    />
                    <Label check>
                      Allow rescheduling
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        id="AllowReschedulingInfo"
                        className="text-muted ml-2"
                      />
                      <UncontrolledTooltip target="AllowReschedulingInfo">
                        Enable rescheduling for your customers to easily change
                        their appointments. You can still reschedule
                        appointments even if this option is disabled.
                      </UncontrolledTooltip>
                    </Label>
                  </FormGroup>
                  {create && (
                    <>
                      <div className="my-2">
                        <h5>Walk-in</h5>
                      </div>
                      <FormGroup switch className="my-3">
                        <Input
                          type="switch"
                          name="emergencyMember"
                          checked={values.emergencyMember}
                          onChange={() => setisEmergency(!isEmergency)}
                          onClick={handleChange}
                        />
                        <Label check>
                          Emergency Appointments
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            id="AllowEmergency"
                            title="Appointments can be scheduled at any time, without restrictions on specific dates or time slots."
                            className="text-muted ml-2"
                          />
                          {/* <UncontrolledTooltip target='AllowEmergency'>
                                                Enabling this feature,Appointments can be booked anytime,regardless of set dates or time slots,offering greater flexibility in scheduling.
                                            </UncontrolledTooltip> */}
                        </Label>
                      </FormGroup>
                    </>
                  )}
                </>
              )}
              <FormGroup className="mt-2">
                {!openSelectCotnact ? (
                  <Button
                    className="signUpBtn submitButton mb-2 pt-2"
                    type="submit"
                    onClick={handleSomeEvent}
                    disabled={!dirty}
                    style={{
                      cursor: dirty ? "pointer" : "not-allowed",
                      // opacity: dirty ? 1 : 0.4,
                    }}
                  >
                    {create ? "Create" : "Save"}
                  </Button>
                ) : null}

                <Button
                  type="button"
                  className="cancelButton ml-2 mt-1"
                  onClick={
                    openSelectCotnact
                      ? () => setOpenSelectContact(false)
                      : onCancel
                  }
                >
                  Cancel
                </Button>
              </FormGroup>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
}

AppointmentForm.defaultProps = {
  create: false,
  data: null,
};

export default AppointmentForm;
