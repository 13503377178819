import React, { useEffect, useState, useRef } from "react";
import { Col, Row, Label } from "reactstrap";
import { Button } from "react-bootstrap";
import {
  createTicket,
  getTicketConfig,
  getTicketFields,
} from "../../services/ticketService";
import { useSelector } from "react-redux";
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
  FieldProps,
  FormikProps,
} from "formik";
import * as Yup from "yup";
import Select, { SingleValue } from "react-select";
import {
  getAllTeam,
  getTeam,
  getTeamBasedOnMember,
} from "../../services/contactMemberService";
import { useNavigate } from "react-router-dom";
import { ContactsGetAll } from "../../services/campaignService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "./ticketing.scss";
import { toast } from "../../common/alert";
import { BsCalendar } from "react-icons/bs";
import CommonPopover from "../../common/commonPopover";
import Calendar from "react-calendar";
import moment from "moment";
import Loader from "../../shade/Loaders/smallLoader";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { IoTicketOutline } from "react-icons/io5";
import { getAllMembers } from "../../services/contactMemberService";

type TicketField = {
  name: string;
  label: string;
  position: number;
  deleted: boolean;
  dropDown: boolean;
  type: string;
  options: any[];
  isDefault: boolean;
};

type TicketOptions = {
  label: string;
  value: string;
};

type CreateTicketProps = {};

const CreateTicket: React.FC<CreateTicketProps> = ({ }) => {
  const channelUid = useSelector(
    (state: any) => state.cartreducer.channelUid.value
  );
  const [isLoading, setIsLoading] = useState(false);
  const [ticketFields, setTicketFields] = useState<TicketField[]>([]);
  const [teams, setTeams] = useState<any[]>([]);
  const [members, setMembers] = useState<any[]>([]);
  const businessId = useSelector(
    (state: any) => state.cartreducer.business?.business?.uid
  );
  const memberData = useSelector((state: any) => state.cartreducer.memberData);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [clearContactsForSearch, setClearContactsForSearch] = useState(false);
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState<boolean[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [contacts, setContacts] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [charCount, setCharCount] = useState<{ [key: string]: number }>({});
  const contactSearchRef = useRef<NodeJS.Timeout | null>(null);
  const [selectedTeam, setSelectedTeam] = useState<TicketOptions | null>(null);
  const [selectedMember, setSelectedMember] = useState<TicketOptions | null>(
    null
  );
  const [initialTeam, setInitialTeam] = useState<TicketOptions | null>(null);
  const [initialMember, setInitialMember] = useState<TicketOptions | null>(
    null
  );
  const [teamAndMemberField, setTeamAndMemberField] = useState<TicketField[]>(
    []
  );
  const userProfile = useSelector((state: any) => state.cartreducer.business);
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

  const togglePopover = (index: number) => {
    setShowDatePicker((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const backToTicketList = () => {
    navigate("/ticketing/viewTicket");
  };

  useEffect(() => {
    getDefaultTicketFields();
  }, []);

  useEffect(() => {
    setClearContactsForSearch(true);
  }, [searchTerm]);

  useEffect(() => {
    if (clearContactsForSearch) {
      getContacts(0);
      setClearContactsForSearch(false);
    }
  }, [clearContactsForSearch]);

  useEffect(() => {
    if (memberData.length) setMembers(memberData);
  }, [memberData]);

  const getContacts = async (page: number) => {
    try {
      const contactPayload = {
        uid: businessId,
        limit: 10,
        page: page,
        search: searchTerm,
      };
      const contact = await ContactsGetAll(contactPayload);
      const newContacts = contact?.data.list.map((con: any) => {
        const countryCodeWithoutPlus = con.countryCode.replace('+', '');
        const trimmedMobileNumber = con.mobileNumber.slice(countryCodeWithoutPlus.length);
        return {
          label: con.name,
          value: con.uid,
          mobileNumber: trimmedMobileNumber,
          countryCode: con.countryCode,
        };
      });
      if (page === 0) {
        setContacts(newContacts);
      } else {
        setContacts((prevContacts) => [...prevContacts, ...newContacts]);
      }
      if (newContacts.length < 10) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    } catch {
      setClearContactsForSearch(false);
      setContacts([]);
      setHasMore(false);
    }
  };

  const getTeams = async () => {
    try {
      const payload = {
        businessUid: businessId,
        page: 0,
        limit: 1000,
      };
      const team = await getAllTeam(payload, "ticketing");

      setTeams(
        team.data.map((teamMember: any) => ({
          label: teamMember.name,
          value: teamMember.uid,
        }))
      );
    } catch {
      setTeams([]);
    }
  };

  const getMembers = async (teamId: string) => {
    try {
      const team = await getTeam(teamId, "ticketing");
      setMembers(
        team.members
          .filter((member: any) => member.status === "ACTIVE")
          .map((member: any) => ({
            label: member.name,
            value: member.uid,
          }))
      );
    } catch {
      setMembers([]);
    }
  };

  const getDefaultTicketFields = async () => {
    setIsLoading(true);
    const fields = await getTicketFields(channelUid);

    if (fields) {
      fields?.data?.sort(
        (a: TicketField, b: TicketField) => a.position - b.position
      );

      const teamAndMemberField = fields?.data?.filter(
        (field: TicketField) => field.name === "team" || field.name === "member"
      );

      const filteredFields = fields?.data?.filter(
        (field: TicketField) =>
          !field.deleted && field.name !== "team" && field.name !== "member"
      );

      setTeamAndMemberField(teamAndMemberField);
      setTicketFields(filteredFields);
      setShowDatePicker(new Array(filteredFields.length).fill(false));
      setIsLoading(false);
    }
  };

  const loadTicketConfig = async (channelUid: string) => {
    const config = await getTicketConfig(channelUid);
    if (config) {
      setInitialTeam({
        label: config.team.name,
        value: config.team.uid,
      });
      setSelectedTeam({
        label: config.team.name,
        value: config.team.uid,
      });

      if (config.member) {
        setInitialMember({
          label: config.member.name,
          value: config.member.uid,
        });
        setSelectedMember({
          label: config.member.name,
          value: config.member.uid,
        });
      }

      await getMembers(config.team.uid);
    }
  };

  //   useEffect(() => {
  //     if (channelUid) {
  //       loadTicketConfig(channelUid);
  //     }
  //   }, [channelUid]);

  const getTeamsForMember = async (memberUid: string) => {
    try {
      const payload = { memberUid: [memberUid] };
      const teamResponse = await getTeamBasedOnMember(payload);
      setTeams(
        teamResponse.map((team: any) => ({
          label: team.name,
          value: team.uid,
        }))
      );
    } catch {
      setTeams([]);
    }
  };

  const preselectMember = () => {
    const matchedMember = memberData.find(
      (member: any) => member.uid === userProfile.uid
    );
    if (matchedMember) {
      setInitialMember({
        label: matchedMember.name,
        value: matchedMember.uid,
      });
      setSelectedMember({
        label: matchedMember.name,
        value: matchedMember.uid,
      });
      getTeamsForMember(matchedMember.uid);
    }
  };

  useEffect(() => {
    // if (userProfile.role.type === "MEMBER") {
    //   preselectMember();
    // } else if (userProfile.role.type === "OWNER") {
    //loadTicketConfig(channelUid);
    getTeams();
    getAllMem();
    //}
  }, [userProfile]);

  const getAllMem = async () => {
    const mem = await getAllMembers(businessId, 1000, 0, 'ticketing');
    if (mem)
      setMembers(
        mem.dataDTO
          .map((member: any) => ({
            label: member.name,
            value: member.uid,
          }))
      );
  }

  const initialValues = {
    contacts: "",
    team: initialTeam ? initialTeam.value : "",
    member: initialMember ? initialMember.value : "",
    ...ticketFields.reduce(
      (
        values: Record<string, string>,
        field: { name: string },
        index: number
      ) => {
        values[`${field.name}-${index}`] = "";
        if (field.name === "status") {
          values[`${field.name}-${index}`] = "Open"; // Set initial value for Status field
        }
        return values;
      },
      {}
    ),
  };

  const validationSchema = Yup.object({
    contacts: Yup.string().required("Contact is required"),
    team: Yup.string().required("Team is required"),
    // member: Yup.string().required("Member is required"),
    ...ticketFields.reduce(
      (
        schema: { [key: string]: Yup.StringSchema },
        field: TicketField,
        index: number
      ) => {
        let fieldSchema = Yup.string();

        if (
          (field.isDefault &&
            field.type !== "team" &&
            field.type !== "member") ||
          field.name === "subject"
        ) {
          fieldSchema = fieldSchema.required(`${field.label} is required`);
        }

        if (field.name === "subject") {
          fieldSchema = fieldSchema.max(
            80,
            `${field.label} must be at most 80 characters`
          );
        }

        schema[`${field.name}-${index}`] = fieldSchema;
        return schema;
      },
      {}
    ),
  });
  
  const handleSubmit = async (values: any) => {
    setIsSubmitLoading(true);
    const updatedTicketFields = ticketFields.map(
      (ticket: any, index: number) => {
        const cleanName = ticket.name.replace(/-\d+|\d+/g, "");
        let value = "";
        Object.keys(values).forEach((key) => {
          if (`${cleanName}-${index}` === `${key}`) {
            value = values[key];
          }
        });
        return {
          ticketField: {
            ...ticket,
            name: cleanName,
          },
          value: value,
        };
      }
    );

    const teamAndMemberPayload = teamAndMemberField.map(
      (field: any, index: number) => {
        let value: string = "";
        if (field.name === "team") {
          value = selectedTeam?.value || "";
        } else if (field.name === "member") {
          value = selectedMember?.value || "";
        }

        return {
          ticketField: {
            ...field,
            name: field.name,
          },
          value: value,
        };
      }
    );


    const ticketpayload = {
      contactUid: values.contacts,
      channelUid: channelUid,
      creatorUid: userProfile.uid,
      fields: updatedTicketFields.concat(teamAndMemberPayload),
    };

    try {
      const res = await createTicket(ticketpayload);
      setIsSubmitLoading(false);
      toast("success", `#${res.id}- Ticket Created Successfully`);
      navigate(`${process.env.PUBLIC_URL}/ticketing/viewTicket`);
    } catch (error) {
      setIsSubmitLoading(false);
      toast("error", "Failed to create ticket");
    }
  };

  const teamMemberSelectStyles = {
    control: (provided: any) => ({
      ...provided,
      width: "95%",
    }),
    menu: (provided: any) => ({
      ...provided,
      width: "95%",
    }),
  };

  const renderField = (
    field: TicketField,
    formikProps: FieldProps,
    index: number
  ) => {
    const { field: formikField, form } = formikProps;
    const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const regex = /^\d*$/;
      if (regex.test(value)) {
        form.setFieldValue(formikField.name, value);
        setCharCount((prev) => ({
          ...prev,
          [formikField.name]: value.length,
        }));
      }
    };

    const renderSelectField = (options: any[], field?: string) => {
      if (field !== "member") {
        return options
          ?.filter((option: any) => !option.deleted)
          .map((opt: any) => ({
            label: opt.label,
            value: opt.label,
          }));
      } else {
        return options?.map((opt: any) => ({
          label: opt.name,
          value: opt.uid,
        }));
      }
    };

    if (field.dropDown && field.type !== "team" && field.type !== "member") {
      const teamKey = Object.keys(form.values).find((key) =>
        key.startsWith("Team")
      );

      return (
        <div>
          <div>
            <Label htmlFor={field.name}>{field.label}</Label>
          </div>
          <Select
            options={renderSelectField(field.options)}
            name={formikField.name}
            value={renderSelectField(field.options).find(
              (option) => option.value === formikField.value
            )}
            onChange={(option: SingleValue<TicketOptions>) => {
              form.setFieldValue(formikField.name, option?.value);
            }}
            onBlur={formikField.onBlur}
            styles={teamMemberSelectStyles}
            placeholder={`Select ${field.name}`}
            isDisabled={field.name === "status"}
          />
        </div>
      );
    } else if (field.name === "description" || field.name === "paragraph") {
      return (
        <div>
          <div className="d-flex justify-content-between">
            <div>
              <Label htmlFor={field.name}>{field.label}</Label>
            </div>
            <div className="small charCount">
              {charCount[formikField.name] || 0}/200
            </div>
          </div>
          <textarea
            {...formikField}
            className="form-control ticketFieldsWidth"
            placeholder={`Enter ${field.name}`}
            maxLength={200}
            onChange={(e) => {
              formikField.onChange(e);
              setCharCount((prev) => ({
                ...prev,
                [formikField.name]: e.target.value.length,
              }));
            }}
          />
        </div>
      );
    } else if (field.type === "date") {
      return (
        <>
          <div>
            <div>
              <Label htmlFor={field.name}>{field.label}</Label>
            </div>

            <div
              style={{
                border: "1px solid #cacaca",
                padding: "8px 8px 8px 3px",
                width: "95%",
                borderRadius: "5px",
              }}
              onClick={() => togglePopover(index)}
            >
              <span id={`calendarIcon-${index}`}>
                <BsCalendar />
              </span>
              <span className="value ml-2">
                {formikField.value || (
                  <span className="ticketplaceholder">Select date</span>
                )}
              </span>
              {formikField.value && (
                <span
                  className="ms-2"
                  onClick={() => form.setFieldValue(formikField.name, "")}
                >
                  <IoIosCloseCircleOutline />
                </span>
              )}
            </div>
          </div>
          <div>
            <CommonPopover
              isOpen={showDatePicker[index]}
              target={`calendarIcon-${index}`}
              toggle={() => togglePopover(index)}
              placement="bottom"
              component="ticket"
            >
              <div className="border-none">
                <Calendar
                  onChange={(value: any) => {
                    const formattedDate = moment(value).format("DD-MM-YYYY");
                    form.setFieldValue(formikField.name, formattedDate);
                    togglePopover(index);
                  }}
                />
              </div>
            </CommonPopover>
          </div>
        </>
      );
    } else if (field.type === "number") {
      return (
        <div>
          <div className="d-flex justify-content-between">
            <div>
              <Label htmlFor={field.name}>{field.label}</Label>
            </div>
            <div className="small charCount">
              {charCount[formikField.name] || 0}/100
            </div>
          </div>
          <input
            type="number"
            {...formikField}
            className="form-control ticketFieldsWidth"
            onChange={handleNumberChange}
            placeholder={`Enter ${field.name}`}
            maxLength={100}
          />
        </div>
      );
    } else if (field.type === "checkbox") {
      return (
        <div>
          <div className="mt-2">
            <div>
              <Label htmlFor={field.type}>Checkbox</Label>
            </div>
            <div className="ms-4 mt-1">
              <input
                type="checkbox"
                {...formikField}
                className="form-check-input"
                id={formikField.name}
                checked={formikField.value}
              />
              <Label
                htmlFor={formikField.name}
                className="form-check-label ms-2"
              >
                {field.label}
              </Label>
            </div>
          </div>
        </div>
      );
    } else if (field.type !== "team" && field.type !== "member") {
      return (
        <div>
          <div className="d-flex justify-content-between">
            <div>
              <Label htmlFor={field.name}>{field.label}</Label>
            </div>
            <div className="small charCount">
              {charCount[formikField.name] || 0}/100
            </div>
          </div>
          <input
            type="text"
            {...formikField}
            className="form-control ticketFieldsWidth"
            onChange={(e) => {
              formikField.onChange(e);
              setCharCount((prev) => ({
                ...prev,
                [formikField.name]: e.target.value.length,
              }));
            }}
            placeholder={`Enter ${field.name}`}
            maxLength={100}
          />
        </div>
      );
    }
  };

  const handleTeamChange = async (
    option: SingleValue<TicketOptions>,
    formikProps: FormikProps<any>
  ) => {
    const { setFieldValue } = formikProps;
    // if (option && option.value !== selectedTeam?.value) {
    if (option) {
      setSelectedTeam(option); // Update selected team
      setFieldValue("team", option.value);
      //if (userProfile.role.type === "OWNER") {
      setSelectedMember(null); // Reset selected member
      await getMembers(option.value);
      setFieldValue("member", ""); // Clear member only if a different team is selected
      //}
    }
  };
  const numberMask = localStorage.getItem('numberMask') === 'true';
  return (
    <Row className="pt-0 pe-3 ps-3 pb-3" style={{ height: "100vh" }}>
      <Col md="12">
        {isLoading ? (
          <div className="loader-container">
            <Loader />
          </div>
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            validateOnChange={true}
            enableReinitialize={true}
          >
            {({ isSubmitting, setFieldValue, dirty, values }) => (
              <Form>
                <div className="ticket-create-header">
                  <Row>
                    <Col md="6" className="mb-2">
                      <div>
                        <div className="d-flex">
                          <span className="cursor-pointer">
                            <FontAwesomeIcon
                              icon={faArrowLeft}
                              aria-hidden="true"
                              onClick={backToTicketList}
                            />{" "}
                          </span>
                          <span className="pl-2">
                            <h5>New Ticket</h5>
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col md="6" className="mb-2">
                      <div>
                        <div style={{ cursor: "not-allowed" }}>
                          <Button
                            type="submit"
                            disabled={!dirty || isSubmitting}
                            style={{
                              cursor: dirty ? "pointer" : "not-allowed",
                              opacity: dirty ? 1 : 0.4,
                            }}
                            className="sendButton float-right"
                          >
                            <div className="d-flex">
                              <div className="m-auto">
                                {isSubmitLoading ? "Wait" : <><IoTicketOutline size={20} /> Create Ticket</>}
                              </div>
                              {isSubmitLoading && <div className="loadingVia"></div>}
                            </div>
                          </Button>
                        </div>
                        <div>
                          <Button
                            className="cancelButton float-right me-2"
                            onClick={() =>
                              navigate(
                                `${process.env.PUBLIC_URL}/ticketing/viewTicket`
                              )
                            }
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="ticket-create-body">
                  <Row>
                    <Col md="6">
                      <div className="form-group mt-1">
                        <Label htmlFor="contacts">Contact</Label>
                        <Select
                          options={contacts}
                          name="contacts"
                          classNamePrefix="select"
                          formatOptionLabel={(option) => (
                            <Row>
                              <Col md="3">{option.label}</Col>
                              <Col md="9" className="text-muted">
                                <div className="d-flex">
                                  <div>
                                    <img
                                      src="/images/whatsapp-icon.svg"
                                      alt="WhatsApp"
                                      width="20"
                                      className="me-2"
                                    />
                                  </div>
                                  <div className={`${numberMask ? "numberMasking_Blur" : ''}`}>{`+${option.countryCode} ${option.mobileNumber}`}</div>
                                </div>
                              </Col>
                            </Row>
                          )}
                          onChange={(option) =>
                            setFieldValue(
                              "contacts",
                              option ? option.value : null
                            )
                          }
                          filterOption={(option, rawInput) => {
                            return (
                              option.data.label
                                .toLowerCase()
                                .includes(rawInput.toLowerCase()) ||
                              `+${option.data.countryCode} ${option.data.mobileNumber}`.includes(
                                rawInput
                              )
                            );
                          }}
                          styles={teamMemberSelectStyles}
                          onInputChange={(inputValue: string) => {
                            setSearchTerm(inputValue);
                          }}
                          onMenuScrollToBottom={() => {
                            if (hasMore) {
                              setPage((prevPage) => prevPage + 1);
                              getContacts(page + 1);
                            }
                          }}
                          onFocus={() => {
                            setIsSearchEnabled(true);
                          }}
                          onBlur={() => {
                            setIsSearchEnabled(false);
                          }}
                          placeholder="Select Contact"
                        />
                        <ErrorMessage
                          name="contacts"
                          component="div"
                          className="text-danger pt-2"
                        />
                      </div>
                    </Col>
                    {ticketFields.map((field: TicketField, index: number) => {
                      if (field.type !== "team" && field.type !== "member") {
                        return (
                          <Col md="6" key={`${field.name}-${index}`}>
                            <div className="form-group mt-1">
                              <Field name={`${field.name}-${index}`}>
                                {(formikProps: FieldProps) =>
                                  renderField(field, formikProps, index)
                                }
                              </Field>
                              <ErrorMessage
                                name={`${field.name}-${index}`}
                                component="div"
                                className="text-danger pt-2"
                              />
                            </div>
                          </Col>
                        );
                      }
                    })}
                    <Col md="6">
                      <div className="form-group mt-1">
                        <Label htmlFor="team">Assign Team</Label>
                        <Select
                          options={teams}
                          name="team"
                          classNamePrefix="select"
                          //value={selectedTeam}
                          onChange={(option) =>
                            handleTeamChange(option, {
                              setFieldValue,
                              values: initialValues,
                            } as FormikProps<any>)
                          }
                          styles={teamMemberSelectStyles}
                          placeholder="Select Team"
                        />
                        <ErrorMessage
                          name="team"
                          component="div"
                          className="text-danger pt-2"
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group mt-1">
                        <Label htmlFor="member">Assign Member</Label>
                        <Select
                          options={members}
                          name="member"
                          classNamePrefix="select"
                          value={selectedMember}
                          onChange={(option) => {
                            setFieldValue("member", option?.value);
                            setSelectedMember(option);
                          }}
                          styles={teamMemberSelectStyles}
                          placeholder="Select Member"
                          menuPlacement="top"
                        // isDisabled={
                        //   !selectedTeam || userProfile.role.type === "MEMBER"
                        // }
                        isDisabled={values.team === ""}
                        />
                        {/* <ErrorMessage
                          name="member"
                          component="div"
                          className="text-danger pt-2"
                        /> */}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </Col>
    </Row>
  );
};

export default CreateTicket;
