import React, { useEffect, useState } from "react";
import { Button, Card, Dropdown, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Col, Container, Popover, PopoverBody, Row } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faFolder } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAppSelector } from "../../botFlows/hooks";
import { createRAG, deleteRAG, getAllRAGFolder, updateRAG } from "../../services/ragService";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { showAlertYesNo } from "../../common/alertYesNo";
import styles from './rag.module.scss';
import { toast } from "../../common/alert";

interface KnowledgeFolder {
    name: string;
    documents: File[];
    status: string;
    description: string;
}

const RAG = () => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [folders, setFolders] = useState<KnowledgeFolder[]>([]);
    const [selectedFolder, setSelectedFolder] = useState(null);
    const businessUid = useSelector((state: any) => state.cartreducer.business?.business?.uid);
    const [formData, setFormData] = useState({
        uid: "",
        name: "",
        description: ""
    })

    const [popoverOpen, setPopoverOpen] = useState(false);
    const [popoverTarget, setPopoverTarget] = useState(null);
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [isInvalidAccess, setIsInvalidAccess] = useState(false);
    const [tooltip, setTooltip] = useState(false);
    const roleRules = useSelector((state: any) => state.cartreducer.roleRules);

    useEffect(() => {  
      if(roleRules) {
        const isInvalidAccess = roleRules.canViewKnowledgeBase === true && roleRules.canManageKnowledgeBase === false;
        setIsInvalidAccess(isInvalidAccess);
      }
    }, [roleRules]);

    // Function to toggle the popover
    const togglePopover = (e: any, id: any) => {
        e.stopPropagation(); // Prevent triggering the folder click
        if (popoverTarget === id) {
            setPopoverOpen(false);
            setPopoverTarget(null);
        } else {
            setPopoverOpen(true);
            setPopoverTarget(id);
        }
    };

    const validationSchema = Yup.object({
        name: Yup.string().required("Knowledge name is required"),
        description: Yup.string()
    });

    const createRAGFolder = async (body: any) => {
        const response = await createRAG(body);
        getAllRAG();
    }

    const handleFolderDelete = async (folder: any) => {
        const alertResponse = await showAlertYesNo(
            "question",
            "Are you sure you want to delete your knowledge folder ?"
        );
        if (alertResponse.isConfirmed) {
            await deleteRAG(folder?.uid);
            getAllRAG();
        }
    };

    const getAllRAG = async () => {
        const body = {
            businessUid: businessUid,
            page: 0,
            limit: 1000
        }
        const allRAG = await getAllRAGFolder(body);
        setFolders(allRAG?.data);
    }

    const updateRAGFolder = async (uid: string, requestBody: any) => {
        const res = await updateRAG(uid, requestBody);
        if (res === "SUCCESS") {
            toast('success', "Knowledge Folder updated successfully");
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: formData,
        validationSchema,
        onSubmit: (values: any) => {
            if (businessUid && !values?.uid) {
                const body = {
                    businessUid: businessUid,
                    name: values?.name,
                    description: values?.description,
                    status: "UPLOADED"
                }
                createRAGFolder(body);
                handleAddFolder(values?.knowledgeName);
            }
            else if (values?.uid) {
                if (selectedFolder) {
                    (selectedFolder as any).name = values?.name;
                    (selectedFolder as any).description = values?.description;
                }
                updateRAGFolder(values?.uid, selectedFolder);
                setShowModal(false);
            }
        },
        validateOnBlur: true,
    });

    useEffect(() => {
        if (showModal) {
            formik.resetForm();
        }
    }, [showModal]);

    useEffect(() => {
        getAllRAG();
    }, [])

    const handleAddFolder = (name: string) => {
        setFolders([...folders, { name: name, documents: [], status: 'UPLOADED', description: '' }]);
        setShowModal(false);
    };

    const navigate = useNavigate();

    const handleFolderClick = (folders: KnowledgeFolder) => {
        navigate(`/RAG/${folders.name}`, { state: { folders, isInvalid : isInvalidAccess } });
    };

    const handleEdit = (folder: any) => {
        setFormData({ name: folder?.name, description: folder?.description, uid: folder?.uid });
        setSelectedFolder(folder);
        setShowModal(true);
    }

    const handleAddKnowledge = () => {
        if (folders?.length < 5 && !isInvalidAccess) {
            setShowModal(true);
            setFormData({
                uid: "",
                name: "",
                description: ""
            });
        }
    };

    return (
        <Container>
            <Row>
                <span>
                    <h5 className="pt-4 ">Knowledge Base</h5>
                </span>
            </Row>
            <Row className="align-items-center">
                <Col xs="auto" className="d-flex align-items-center">
                <OverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip id="tooltip-name">{isInvalidAccess ? "You don't have permission" : "You can only create up to 5 folders."}</Tooltip>
                }
                show={tooltipVisible}
            >
                <div className={`${!isInvalidAccess ? "pointer" : "notAllowed"}`}>
                <div
                    onClick={handleAddKnowledge}
                    onMouseEnter={() => {
                        console.log("mouse enter")
                        if (folders?.length >= 5) {
                          setTooltipVisible(true);
                        } 
                        if (isInvalidAccess) {
                          setTooltipVisible(true);
                        }
                    }}
                    onMouseLeave={() => { setTooltipVisible(false); setTooltip(false) }}
                    className={`${styles.plusDiv} ${(folders?.length >= 5 || isInvalidAccess) ? styles.disabled : ''}`}
                >
                    <span className={styles.plus}>
                        + Add Knowledge
                    </span>
                </div>
                </div>
            </OverlayTrigger>

                </Col>
                {folders.map((folder, index) => (
                    <Col key={index} md="auto" className={` pt-3`}>
                        <Card
                            className={styles.card}
                            onClick={() => handleFolderClick(folder)}
                        >
                            <Card.Header
                                className={styles.cardHeader}
                            >
                                <div className={styles.batch}>
                                    <span
                                        className="badge badge-pill badge-info"
                                        style={{
                                            backgroundColor:
                                                folder?.status === 'COMPLETED'
                                                    ? '#07c760'
                                                    : folder?.status === 'UPLOADED'
                                                        ? '#f0ad4e'
                                                        : folder?.status === 'ERROR'
                                                            ? '#dc3545'
                                                            : '#17a2b8',
                                            padding: '5px 10px',
                                            color: 'white',
                                        }}
                                    >
                                        {folder?.status}
                                    </span>
                                    <span
                                        id={`popover_${index}`}
                                        className={styles.popover}
                                        onClick={(e) => togglePopover(e, `popover_${index}`)}
                                    >
                                        <FontAwesomeIcon icon={faEllipsisV} />
                                    </span>
                                    <Popover
                                        placement="right"
                                        isOpen={popoverOpen && popoverTarget === `popover_${index}`}
                                        target={`popover_${index}`}
                                        toggle={() => setPopoverOpen(false)}
                                        trigger="legacy"
                                    >
                                        <PopoverBody className="text-center">
                                            <div
                                                className={`p-1 ${isInvalidAccess ? styles.noPointer : ""} `}
                                                id={styles.popoverBody}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleEdit(folder);
                                                    setPopoverOpen(false);
                                                }}
                                            >
                                                Edit
                                            </div>

                                            <div
                                                className="p-2"
                                                id={styles.popoverBody}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleFolderClick(folder);
                                                    setPopoverOpen(false);
                                                }}
                                            >
                                                Upload
                                            </div>

                                            {/* Delete Action */}
                                            <div
                                                className={`p-1 ${isInvalidAccess ? styles.noPointer : ""} `}
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevent triggering the folder click
                                                    handleFolderDelete(folder); // Ensure handleFolderDelete function is called
                                                    setPopoverOpen(false); // Close popover after clicking
                                                }}
                                            >
                                                Delete
                                            </div>
                                        </PopoverBody>
                                    </Popover>

                                </div>
                            </Card.Header>

                            {/* Card Body with status label */}
                            <Card.Body>
                                <div>
                                    <span className={styles.folderName}>{folder.name}</span>
                                    <br />
                                    <span>{folder?.description?.length > 120 ? folder?.description?.substring(0, 100) + "..." : folder?.description}</span>
                                </div>
                            </Card.Body>
                            <Card.Footer className={styles.cardFooter}>
                            </ Card.Footer>
                        </Card>
                    </Col>
                ))}
            </Row>

            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Header closeButton>
                    {formData?.uid ? <h5> Edit Knowledge</h5> : <h5>Add New Knowledge</h5>}
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={formik.handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Knowledge Name <span className="required"></span></Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter knowledge name"
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.name && !!formik.errors.name}
                                isValid={formik.touched.name && !formik.errors.name}
                            />
                            {formik.touched.name && formik.errors.name ? (
                                <div className="invalid-feedback d-block">
                                    {typeof formik.errors.name === "string" && formik.errors.name}
                                </div>
                            ) : null}
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                type="description"
                                placeholder="Enter description"
                                name="description"
                                value={formik.values?.description}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </Form.Group>
                        <div className="text-center mt-3">
                            <Button
                                variant=""
                                type="button"
                                className="cancelButton mr-3"
                                onClick={() => setShowModal(false)}
                            >
                                Cancel
                            </Button>
                            <Button type="submit" variant="" className="sendButton">
                                {formik.values?.uid ? "Update" : "Create"}
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default RAG;
