import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Radium, { StyleRoot, Style } from "radium";
import {
  Dropdown,
  Button,
  Spinner,
  OverlayTrigger,
  Overlay,
  Popover,
  Form,
  ListGroup,
  Row,
  Col,
  Tooltip,
} from "react-bootstrap";
import {
  Alert,
  Card,
  CardBody,
  CardSubtitle,
  Offcanvas,
  OffcanvasBody,
  Input,
} from "reactstrap";
import { useQuery } from "@tanstack/react-query";
import * as originalMoment from "moment";
import { extendMoment } from "moment-range";
import DateRangePicker from "react-daterange-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import * as ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import {
  BsCalendar,
  BsFunnel,
  BsFunnelFill,
  BsInfoCircleFill,
  BsThreeDotsVertical,
} from "react-icons/bs";

import "./Campaign.scss";
import { processTemplateVariable } from "./PreviewWhatsapp";
import excelIcon from "../../assets/img/excel.png";
import campaignInfoStyle from "./CampaignInfo.module.scss";
import * as campaignServices from "../../services/campaignService";
import TemplateCard from "../Template/TemplateCard";
import Loader from "../../shade/Loaders/smallLoader";
import { showAlertYesNo } from "../../common/alertYesNo";
import { getBots } from "../../botFlows/services/bots";
import getAdjustedDateRange from "../../utils/getAdjustedDateRange";
import { toast } from "../../common/alert";
import SearchBox from "../../botFlows/components/ui/searchBox";
import { useNavigate } from "react-router-dom";
import SyncButton from "../../common/syncButton";
import campaignContext, { IRepeatCampaignData } from "./campaignContext";
import NoDataFallback from "../../common/noDataFallback";
import Smile from "../../assets/img/svgs/Smile.svg";
import BizTable from "../../common/BizTable";
import axios from "../../utils/axios";
import { sliceText } from "../../common/sliceText";
import TooltipReference from "../../common/tooltip";

const moment = extendMoment(originalMoment);

interface StatusFilterListProps {
  filters: string[];
  setFilters: React.Dispatch<React.SetStateAction<string[]>>;
  onClose: () => void;
}

const StatusFilterList: React.FC<StatusFilterListProps> = (props) => {
  const [filterSearch, setFilterSearch] = useState<string>("");
  const [appliedFilters, setAppliedFilters] = useState<string[]>([]);

  useEffect(() => {
    setAppliedFilters(props.filters);
  }, [props.filters]);

  const DEFAULT_FILTERS = ["Cancelled", "Completed", "Pending", "Failed"];

  const applyFilterHandler = () => {
    props.setFilters(appliedFilters);
    props.onClose();
  };

  const onCancelHandler = () => {
    props.onClose();
  };
  const filteredStatuses = DEFAULT_FILTERS.filter(status =>
    status.toLowerCase().startsWith(filterSearch.toLowerCase())
  );

  return (
    <ListGroup variant="flush">
      <ListGroup.Item>
        <Form.Control
          autoFocus
          className="w-auto mb-2"
          placeholder="Type to filter"
          onChange={(e) => setFilterSearch(e.target.value)}
          value={filterSearch}
          size="sm"
        />
        {props?.filters?.length > 0 &&(
            <Button
                      variant="link"
                      className="p-0 float-end"
                      onClick={() => props.setFilters([])}
                    >
                      Reset
                    </Button>
                    )}
      </ListGroup.Item>
      {/* {DEFAULT_FILTERS.filter((status) =>
        status.toLowerCase().startsWith(filterSearch.toLowerCase())
      ).map((status) => {
        return (
          <StatusFilterListItem
            key={`StatusFilterItem-${status}`}
            value={appliedFilters}
            setFilters={setAppliedFilters}
            label={status}
          />
        );
      })} */}
         {filteredStatuses.length > 0 ? (
        filteredStatuses.map((status) => (
          <StatusFilterListItem
            key={`StatusFilterItem-${status}`}
            value={appliedFilters}
            setFilters={setAppliedFilters}
            label={status}
          />
        ))
      ) : (
        <ListGroup.Item className="text-center">
          No data found
        </ListGroup.Item>
      )}
      <ListGroup.Item className="d-flex">
        <Button
          size="sm"
          className="cancelButton w-50 p-1"
          onClick={onCancelHandler}
        >
          Cancel
        </Button>
        <Button
          size="sm"
          className="sendButton p-1 w-50 ml-2"
          onClick={applyFilterHandler}
        >
          Apply
        </Button>
      </ListGroup.Item>
    </ListGroup>
  );
};

interface StatusFilterListItemProps {
  label: string;
  value: string[];
  setFilters: React.Dispatch<React.SetStateAction<string[]>>;
}

enum DateFilterType {
  Past7Days = "Past7Days",
  Past30Days = "Past30Days",
  Past90Days = "Past90Days",
  ThisWeek = "ThisWeek",
  PreviousWeek = "PreviousWeek",
  ThisMonth = "ThisMonth",
  PreviousMonth = "PreviousMonth",
  CustomRange = "CustomRange",
}

const StatusFilterListItem: React.FC<StatusFilterListItemProps> = (props) => {
  const isFilterApplied = props.value.find((val) => val === props.label)
    ? true
    : false;

  const toggleFilter = () => {
    if (isFilterApplied) {
      props.setFilters((current: string[]) => {
        return current.filter((filter: string) => filter !== props.label);
      });
    } else {
      props.setFilters((current: string[]) => {
        const updatedFilters = [...current];
        updatedFilters.push(props.label);
        return updatedFilters;
      });
    }
  };

  return (
    <ListGroup.Item onClick={toggleFilter} style={{ cursor: "pointer" }}>
      <Form.Check checked={isFilterApplied} label={props.label} />
    </ListGroup.Item>
  );
};

interface CampaignProps {
  campaign: any;
}
const CampaignReplyTooltip: React.FC<CampaignProps> = ({ campaign }) => {
  if (!campaign.buttonMap || typeof campaign.buttonMap !== "object") {
    return null;
  }

  function getKey(name: string) {
    if (name in campaign.buttonMap) {
      return formatPrefixWithZero(campaign.buttonMap[name]);
    }
    return formatPrefixWithZero(0);
  }

  const otherReplies: number =
    campaign.repliedCount -
    Object.keys(campaign.buttonMap).reduce((sum: number, buttonKey: string) => {
      return sum + campaign.buttonMap[buttonKey];
    }, 0);

  return (
    <div className="campaign-history-replied-stats-tooltip">
      <Popover.Body>
        <table className="campaign-history-replied-stats">
          <thead>
            <tr className="mb-2">
              <th>Buttons</th>
              <th>Replied Count</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(campaign.buttonMap).map((key) => (
              <tr key={key}>
                <td>{key}</td>
                <td>{getKey(key)}</td>
              </tr>
            ))}
            {otherReplies > 0 && (
              <tr>
                <td>Other replies</td>
                <td>{formatPrefixWithZero(otherReplies)}</td>
              </tr>
            )}
          </tbody>
        </table>
      </Popover.Body>
    </div>
  );
};

const ReplyAssignedTo: React.FC<CampaignProps> = ({ campaign }) => {
  const memberData = useSelector((state: any) => state.cartreducer.memberData);
  const businessId = useSelector(
    (state: any) => state.cartreducer.business?.business?.uid
  );
  const getBotsQuery = useQuery({
    queryKey: ["bots", "?q="],
    enabled: false,
    queryFn: () => getBots(""),
  });
  useEffect(() => {
    if (campaign.replyAssignTo === 0) {
      getBotsQuery.refetch();
    }
  }, []);

  const loading =
    campaign.replyAssignTo === 0
      ? getBotsQuery.isLoading
      : memberData && memberData.length > 0;
  const data =
    campaign.replyAssignTo === 0 ? getBotsQuery.data.bots : memberData;

  if (loading || data === null) return null;
  if (campaign.replyAssignTo === 0) {
    const replyBot = data.find((bot: any) => {
      return bot.id === campaign.replyAssignId;
    });
    if (!replyBot) return null;
    return (
      <div className={campaignInfoStyle.item}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="16"
          viewBox="0 0 12 16"
          fill="none"
        >
          <path
            d="M10.2223 9.87468C11.066 9.87468 11.75 10.6241 11.75 11.5485V12.2218C11.75 13.0354 11.4258 13.8084 10.8624 14.3384C9.79949 15.3382 8.27836 15.8268 6.31612 15.8268C4.35422 15.8268 2.83412 15.3383 1.77297 14.3388C1.21036 13.8088 0.886719 13.0363 0.886719 12.2234V11.5485C0.886719 10.6241 1.57069 9.87468 2.41441 9.87468H10.2223ZM6.24725 0.953335L6.31635 0.948242C6.57415 0.948242 6.78721 1.15814 6.82093 1.43046L6.82558 1.50617L6.82504 2.06336L9.20177 2.06373C10.0455 2.06373 10.7295 2.81311 10.7295 3.73752V7.08851C10.7295 8.01291 10.0455 8.76229 9.20177 8.76229H3.43047C2.58675 8.76229 1.90278 8.01291 1.90278 7.08851V3.73752C1.90278 2.81311 2.58675 2.06373 3.43047 2.06373L5.80658 2.06336L5.80711 1.50617C5.80711 1.22371 5.99869 0.99028 6.24725 0.953335ZM4.78794 4.29545C4.31947 4.29545 3.93971 4.71153 3.93971 5.2248C3.93971 5.73807 4.31947 6.15415 4.78794 6.15415C5.25641 6.15415 5.63618 5.73807 5.63618 5.2248C5.63618 4.71153 5.25641 4.29545 4.78794 4.29545ZM7.8384 4.29545C7.36993 4.29545 6.99017 4.71153 6.99017 5.2248C6.99017 5.73807 7.36993 6.15415 7.8384 6.15415C8.30687 6.15415 8.68664 5.73807 8.68664 5.2248C8.68664 4.71153 8.30687 4.29545 7.8384 4.29545Z"
            fill="#007EC3"
          />
        </svg>
        Replied to Bot:{" "}
        <a href={"/bots/" + replyBot.uid} target="_blank">
          {replyBot.name}
        </a>
      </div>
    );
  } else {
    const replyContact = data.dataDTO.find((contact: any) => {
      return contact.id === campaign.replyAssignId;
    });
    if (!replyContact) return null;
    return (
      <div className={campaignInfoStyle.item}>
        Replied to Contact: {replyContact.name}
      </div>
    );
  }
};

/**
 * For formatting the number to prefix with '0' in campaign history
 */
const formatPrefixWithZero = (number: number): string => {
  if (number < 10) {
    return `0${number}`;
  }
  return `${number}`;
};

interface CampaignListProps {
  onCreateCampaign: () => void;
}

const CampaignList: React.FC<CampaignListProps> = (props) => {
  const channelUid = useSelector(
    (state: any) => state.cartreducer.channelUid?.value
  );
  const businessId = useSelector(
    (state: any) => state.cartreducer.business?.business?.id
  );
  const business = useSelector(
    (state: any) => state.cartreducer.business?.business
  );
  const channelData = useSelector((state: any) => state.cartreducer.channelUid);
  const memberData = useSelector(
    (state: any) => state.cartreducer.mappedMemberDataWithUid
  );
  const [statusFilters, setStatusFilters] = useState<string[]>([]);
  const [showFilter, setShowFilter] = useState(false);
  const filtertarget = useRef(null);
  const [search, setSearch] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const { setRepeatCampaign } = useContext(campaignContext);

  const navigate = useNavigate();

  const createToCampaign = () => {
    setRepeatCampaign(null);
    navigate("newcampaign");
    props.onCreateCampaign();
  };

  //Daterange
  const today = moment().clone().startOf("day");
  const [dateRangeValue, setDateRangeValue] = useState(
    moment.range(
      today.clone().subtract(7, "days").startOf("day"),
      today.endOf("day")
    )
  );

  const [dateFilter, setDateFilter] = useState(DateFilterType.Past7Days);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [campaignDetail, setCampaignDetail] = useState<null | any>(null);
  const { start: adjustedStart, end: adjustedEnd } = getAdjustedDateRange(
    dateRangeValue.start,
    dateRangeValue.end
  );
  const datepickertarget = useRef(null);

  // const handleClickOutside = (event: MouseEvent) => {
  //   // The event target is cast to Node, and the ref check is now correctly typed
  //   if (datepickertarget.current && !datepickertarget.current.contains(event.target as Node)) {
  //     setShowDatePicker(false);
  //   }
  // };

  // useEffect(() => {
  //   // Add event listener for clicks
  //   document.addEventListener('mousedown', handleClickOutside);
  //   return () => {
  //     // Clean up event listener on component unmount
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, []);
  const [dashBoardCounts, setDashboardCounts] = useState({
    totalRecipients: 0,
    totalSentCount: 0,
    totalDeliveredCount: 0,
    totalReadCount: 0,
    totalFailedCount: 0,
    totalRepliedCount: 0,
    totalReactionCount: 0,
    totalClicksCount: 0,
    buttonMap: null,
  });
  const [campaignOverviewQuery, setCampaignOverviewQuery] = useState([]);
  const [campaignOverviewCount, setCampainOverewCount] = useState(0);
  const [campaignFilterQuery, setCampaignFilterQuery] = useState([]);
  const [campaignSyncQuery, setCampaignSyncQuery] = useState([]);
  const [isFilter,setIsFilter] = useState(false);

  // const campaignOverviewQuery = useQuery({
  //   queryKey: [
  //     "campaign-overview",
  //     channelUid,
  //     `${adjustedStart.valueOf()}:${adjustedEnd.valueOf()}`,
  //   ],
  //   queryFn: () =>
  //     campaignServices.getCampaignOverview(
  //       channelUid,
  //       adjustedStart.valueOf(),
  //       adjustedEnd.valueOf()
  //     ),
  // });

  const dashboardForCampaign = async () => {
    const startTime = adjustedStart.valueOf();
    const endTime = adjustedEnd.valueOf();
    const timePayload = {
      fromTimeUTC: startTime,
      toTimeUTC: endTime,
      search: search?.length > 0 ? search : null,
      status:statusFilters
    };
    const dashboardForCampaign =
      await campaignServices.getCampaignOverviewDashboard(
        channelUid,
        timePayload
      );
    if (dashboardForCampaign) {
      setDashboardCounts(dashboardForCampaign);
    }
    else{
      setDashboardCounts({
        totalRecipients: 0,
        totalSentCount: 0,
        totalDeliveredCount: 0,
        totalReadCount: 0,
        totalFailedCount: 0,
        totalRepliedCount: 0,
        totalReactionCount: 0,
        totalClicksCount: 0,
        buttonMap: null,
      })
    }
  };
  // useEffect(() => {
  //   dashboardForCampaign();
  // }, [channelUid, adjustedStart.valueOf(), adjustedEnd.valueOf()]);
  const prevStartTimeRef = useRef(0);
  const prevEndTimeRef = useRef(0);
  const fetchData = useCallback(
    async (
      pageIndex: number,
      pageSize: number,
      searchText: string,
      currentPage: number
    ): Promise<{ data: any; totalPages: number }> => {
      const startTime = adjustedStart.valueOf();
      const endTime = adjustedEnd.valueOf();
      const shouldReset = isFilter || statusFilters.length > 0;
      
      const isDateRangeChanged = prevStartTimeRef.current !== startTime || prevEndTimeRef.current !== endTime;
      prevStartTimeRef.current = startTime;
      prevEndTimeRef.current = endTime;

    
      const dashboradPayload = {
        fromTimeUTC:  startTime,
        toTimeUTC: endTime,
        page: isDateRangeChanged || shouldReset ? 0 : currentPage,
        limit: pageSize,
        broadcastName: search,
        status: statusFilters,
      };
      const response = await campaignServices.getCampaignOverview(
        channelUid,
        dashboradPayload
      );
      dashboardForCampaign();
      if (response) {
        // setLoading(false);
        setCampaignOverviewQuery(response.broadCastList);
        setCampainOverewCount(response?.totalBroadcastCount);
        return {
          data: response.broadCastList,
          totalPages: response?.totalBroadcastCount,
        };
      } else {
        setCampaignOverviewQuery([]);
        setCampainOverewCount(0);
        return { data: [], totalPages: 0 };
      }
      // }
      //   else{
      //     return {data:[],totalPages:0}
      //   }
    },
    [channelUid,dateRangeValue,search,statusFilters]
  );
  // const campaignFilterQuery = useQuery({
  //   queryKey: [
  //     "campaign-filter",
  //     channelUid,
  //     `${adjustedStart.valueOf()}:${adjustedEnd.valueOf()}`,
  //     `?q=${searchQuery}&filters=${statusFilters.join(",")}`,
  //   ],
  //   queryFn: () => {
  //     return campaignServices.getCampaignFilter(
  //       channelUid,
  //       adjustedStart.valueOf(),
  //       adjustedEnd.valueOf(),
  //       searchQuery,
  //       statusFilters
  //     );
  //   },
  //   enabled: false,
  // });
  const campaignCountQuery = useQuery({
    queryKey: ["campaign-count", channelUid],
    queryFn: () => campaignServices.getBroadcastCount(channelUid),
  });
  const totalCreditsQuery = useQuery({
    queryKey: ["total-credits", businessId],
    queryFn: () => campaignServices.getTotalCredits(businessId),
  });
  // const campaignSyncQuery = useQuery({
  //   queryKey: [
  //     "campaign-sync",
  //     channelUid,
  //     campaignDetail && "uid" in campaignDetail ? campaignDetail.uid : "",
  //   ],
  //   queryFn: () => {
  //     return campaignServices.getCampaignFilter(
  //       channelUid,
  //       undefined,
  //       undefined,
  //       undefined,
  //       undefined,
  //       campaignDetail && "uid" in campaignDetail ? campaignDetail.uid : ""
  //     );
  //   },
  //   enabled: false,
  // });

  const campaignTotal = (value: any) => {
    const numericValue = parseFloat(value.replace(/[^\d.-]/g, ""));
    return isNaN(numericValue) ? 0 * 1000 : numericValue * 1000;
  };

  // useEffect(() => {
  //   let timer = setTimeout(() => {
  //     if (search.trim() !== "") {
  //       setSearchQuery(search);
  //     }
  //   }, 600);

  //   return () => clearTimeout(timer);
  // }, [search]);

  // useEffect(() => {
  //   if (searchQuery && searchQuery.trim() !== "") {
  //     // campaignFilterQuery.refetch();
  //     fetchData(0, 20, "", 0);
  //   }
  // }, [searchQuery]);
  // useEffect(() => {
  //   if (statusFilters.length > 0) {
  //     setIsFilter(true);
  //     // campaignFilterQuery.refetch();
  //     fetchData(0, 20, "", 0);
  //   }
  // }, [statusFilters]);

  let isCampaignCreationEnabled = false;
  let isCampaignCreationEnabled_Plan = false;
  if (totalCreditsQuery.isLoading || totalCreditsQuery.isError) {
    isCampaignCreationEnabled = true;
  } else if (business?.freeTrial) {
    isCampaignCreationEnabled = true;
    isCampaignCreationEnabled_Plan = true;
  } else if (
    (totalCreditsQuery.data as { totalCredits: number }).totalCredits > 1
  ) {
    isCampaignCreationEnabled = true;
  }

  if (
    (totalCreditsQuery.data as { broadcastAvailable: boolean })
      ?.broadcastAvailable === true
  ) {
    isCampaignCreationEnabled_Plan = true;
  }

  const cancelBroadcastHandler = async (broadcastUid: string) => {
    const { isConfirmed } = await showAlertYesNo(
      null,
      "Do you want to cancel the campaign?"
    );
    if (isConfirmed) {
      try {
        await campaignServices.cancelBroadcast(channelUid, broadcastUid);
        toast("success", "Campaign cancelled successfully");
        // campaignOverviewQuery.refetch();
        fetchData(0, 20, "", 0);
        // campaignFilterQuery.refetch();

        campaignCountQuery.refetch();
      } catch (error) {
        // const axiosError = error as AxiosError<{ message: string }>;
        toast("error", "Campaign failed to cancel");
      }
    }
  };

  const repeatCampaignHandler = async (campaign: any) => {
    // try {
    //     await campaignServices.repeatCampaign(channelUid, campaign)
    //     toast("success", "Campaign repeated successfully");
    //     campaignFilterQuery.refetch();
    //     campaignCountQuery.refetch();
    // } catch (error) {
    //     // const axiosError = error as AxiosError<{ message: string }>;
    //     toast("error", "Campaign failed to repeat");
    // }

    let scheduledAt = campaign.scheduledAt;
    if (
      campaign.type === "immediate" ||
      (scheduledAt > 0 && moment(scheduledAt).isBefore(moment()))
    ) {
      scheduledAt = parseInt(new Date().getTime().toString());
    }
    const timestamp = moment().format("DD MMM YYYY h:mma");

    function extractCampaignName(inputString: string): string {
      var regex = /\((\d{2} [A-Za-z]{3} \d{4} \d{1,2}:\d{2}[APap][Mm])\)/;
      var match = regex.exec(inputString);

      if (match && match[1]) {
        var name = inputString.replace(match[0], "").trim();

        return name;
      } else {
        return inputString;
      }
    }

    const campaignData: IRepeatCampaignData = {
      name: extractCampaignName(campaign.name) + " (" + timestamp + ")",
      type: campaign.type,
      replyAssignTo: campaign.replyAssignTo,
      replyAssignId: campaign.replyAssignId,
      scheduledAt,
      templateId: campaign.template.id,
      templateVariables: campaign.templateText,
      communityList: campaign.communityList,
      selectedTemplate: campaign.template,
      forceSent: campaign.forceSent,
    };
    setRepeatCampaign(campaignData);
    setCampaignDetail(null);
    navigate("newcampaign");
    props.onCreateCampaign();
  };

  // const options = {
  //   headerAlign: "center",
  //   noDataText: "No records found",
  //   sortIndicator: true,
  //   hidePageListOnlyOnePage: true,
  //   clearSearch: false,
  //   alwaysShowAllBtns: true,
  //   withFirstAndLast: true,
  //   sizePerPage: 10,
  //   paginationPosition: "bottom",
  // };

  const columns = [
    {
      id: "selection",
      Header: (
        <input
          type="checkbox"
          hidden={true}
          // checked={this.state.allChecked}
          // onChange={this.toggleAllCheckboxes}
        />
      ),
      accessor: "checkbox",
      // Cell: this.checkboxCellRenderer,
      sticky: "left",
    },
    {
      Header: "Campaign Name",
      accessor: "name",
      sticky: "left",
      Cell: ({ cell, row }: any) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => setCampaignDetail(row.original)}
        >
          <div>{cell.value}</div>
          <div>
            {row.original.communityList.some(
              (c: any) => c.uploadMethod === "Group"
            ) && (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                >
                  <path
                    d="M13.25 13.3332H5.125V1.6665H13.25C13.9375 1.6665 14.5 2.1915 14.5 2.83317V12.1665C14.5 12.8082 13.9375 13.3332 13.25 13.3332Z"
                    fill="#ededf5"
                  />
                  <path
                    d="M4.5 1.6665H5.125V13.3332H4.5C3.8125 13.3332 3.25 12.8082 3.25 12.1665V2.83317C3.25 2.1915 3.8125 1.6665 4.5 1.6665Z"
                    fill="#666E7D"
                  />
                  <path
                    d="M9.5 7.49984C10.1904 7.49984 10.75 6.9775 10.75 6.33317C10.75 5.68884 10.1904 5.1665 9.5 5.1665C8.80964 5.1665 8.25 5.68884 8.25 6.33317C8.25 6.9775 8.80964 7.49984 9.5 7.49984Z"
                    fill="#666E7D"
                  />
                  <path
                    d="M11.6875 9.24967C11.6875 9.24967 11.0938 8.08301 9.5 8.08301C7.90625 8.08301 7.3125 9.24967 7.3125 9.24967V9.83301H11.6875V9.24967Z"
                    fill="#666E7D"
                  />
                </svg>
                {/* Assuming a placeholder for Group Icon */}
                <span className="ml-1" style={{ color: "#8c8ea0" }}>
                  {row.original.communityList
                    .filter((c: any) => c.uploadMethod === "Group")
                    .map((c: any) => c.name)
                    .join(", ")
                    .slice(0, 10)}
                </span>
              </>
            )}
            {row.original.communityList.some(
              (c: any) => c.uploadMethod === "Excel"
            ) && (
              <>
                {/* Placeholder for Excel Icon */}
                <img alt="" src={excelIcon} width={15} />
                <span className="ml-1" style={{ color: "#8c8ea0" }}>
                  {row.original.communityList
                    .filter((c: any) => c.uploadMethod === "Excel")
                    .map((c: any) => c.name)
                    .join(", ")
                    .slice(0, 10)}
                </span>
              </>
            )}
          </div>
        </div>
      ),
    },
    {
      Header: "Scheduled At",
      accessor: "createdAt",
      Cell: ({ cell, row }: any) => (
        <>
          {moment(
            row.original.type === "immediate"
              ? cell.value
              : row.original.scheduledAt
          ).format("DD/MM/YYYY")}
        </>
      ),
      // headerAlign: 'center',
      // align: 'center'
    },
    {
      Header: "Created By",
      accessor: "creatorId",
      Cell: ({ cell }: any) => <>{memberData.get(cell.value || "")}</>,
      // headerAlign: 'left',
      // align: 'left'
    },
    {
      Header: "Recipients",
      accessor: "totalCount",
      Cell: ({ cell, row }: any) =>
        formatPrefixWithZero(
          row?.original?.broadCastMessageList?.totalRecipients ?? "0"
        ),
      // headerAlign: 'center',
      // align: 'center',
      // width: '8%'
    },
    {
      Header: "Sent",
      accessor: "sentCount",
      Cell: ({ cell, row }: any) =>
        formatPrefixWithZero(
          row?.original?.broadCastMessageList?.totalSentCount ?? "0"
        ),
      // headerAlign: 'center',
      // align: 'center',
      // width: '8%'
    },
    {
      Header: "Delivered",
      accessor: "deliveredCount",
      Cell: ({ cell, row }: any) =>
        formatPrefixWithZero(
          row?.original?.broadCastMessageList?.totalDeliveredCount ?? "0"
        ),
      // headerAlign: 'center',
      // align: 'center',
      // width: '8%'
    },
    {
      Header: "Read",
      accessor: "readCount",
      Cell: ({ cell, row }: any) =>
        formatPrefixWithZero(
          row?.original?.broadCastMessageList?.totalReadCount ?? "0"
        ),
      // headerAlign: 'center',
      // align: 'center',
      // width: '8%'
    },
    {
      Header: "Replied",
      accessor: "repliedCount",
      Cell: ({ cell, row }: any) => (
        <span>
          {formatPrefixWithZero(
            row?.original?.broadCastMessageList?.totalRepliedCount ?? "0"
          )}
          {/* Placeholder for overlay and tooltip */}
          {row?.original?.broadCastMessageList && row?.original?.broadCastMessageList?.buttonMap !== null && (
            <OverlayTrigger
              trigger={["hover", "hover"]}
              placement="bottom"
              overlay={
                <Popover>
                  <CampaignReplyTooltip
                    campaign={row?.original?.broadCastMessageList}
                  />
                </Popover>
              }
            >
              <Button variant="link" className="pe-0 ps-1 pt-0">
                <BsInfoCircleFill color="#909EBD" size={14} />
              </Button>
            </OverlayTrigger>
          )}
        </span>
      ),
      // headerAlign: 'center',
      // align: 'center',
      // width: '8%'
    },
    {
      Header: "Clicked",
      accessor: "clickedCount",
      Cell: ({ cell, row }: any) =>
        formatPrefixWithZero(
          row?.original?.broadCastMessageList?.totalClicksCount ?? "0"
        ),
      // headerAlign: 'center',
      // align: 'center',
      // width: '8%'
    },
    {
      Header: "Failed",
      accessor: "failedCount",
      Cell: ({ cell, row }: any) =>
        formatPrefixWithZero(
          row?.original?.broadCastMessageList?.totalFailedCount ?? "0"
        ),
      // headerAlign: 'center',
      // align: 'center',
      // width: '8%'
    },
    {
      Header: "Status",
      accessor: "edit",
      Cell: ({ cell, row }: any) => renderEditColumn(cell.value, row.original),
      // headerAlign: 'center',
      // align: 'center',
      // width: '8%'
    },
    {
      Header: "Action",
      accessor: "actions",
      Cell: ({ cell, row }: any) => (
        <>
          <OverlayTrigger
            placement="bottom"
            overlay={
              !isCampaignCreationEnabled ? (
                <Tooltip placement="bottom" id="no-coins">
                  You don't have enough credits
                </Tooltip>
              ) : !isCampaignCreationEnabled_Plan ? (
                <Tooltip placement="bottom" id="no-coins">
                  Maximum Campaign limit exceeded for Standard plan.
                </Tooltip>
              ) : (
                <></>
              )
            }
          >
            <span
              style={{
                cursor: !isCampaignCreationEnabled
                  ? "not-allowed"
                  : !isCampaignCreationEnabled_Plan
                  ? "not-allowed"
                  : "pointer",
              }}
            >
              <Button
                variant="light"
                className={`campaignActionButton`}
                onClick={() => repeatCampaignHandler(row?.original)}
                disabled={
                  !isCampaignCreationEnabled || !isCampaignCreationEnabled_Plan
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                >
                  <path
                    d="M10.5 3.75L12 5.25L10.5 6.75"
                    stroke="#333"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.5 5.25H5C4.33757 5.25198 3.70283 5.51601 3.23442 5.98442C2.76601 6.45283 2.50198 7.08757 2.5 7.75V8.25M6.5 12.25L5 10.75L6.5 9.25"
                    stroke="#333"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.5 10.75H12C12.6624 10.748 13.2972 10.484 13.7656 10.0156C14.234 9.54717 14.498 8.91243 14.5 8.25V7.75"
                    stroke="#333"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Button>
            </span>
          </OverlayTrigger>
          <StyleRoot style={{ display: "inline" }}>
            <Style
              scopeSelector=".campaignRowDownloadExcelButton"
              rules={
                row?.original?.status === "Pending"
                  ? {
                      cursor: "not-allowed",
                      border: "none",
                    }
                  : {}
              }
            />
            <OverlayTrigger
              trigger={["hover", "hover"]}
              placement="bottom"
              overlay={<Tooltip>Download Excel</Tooltip>}
            >
              <Button
                variant="light"
                className={[
                  "campaignActionButton",
                  "campaignRowDownloadExcelButton",
                ].join(" ")}
                disabled={row?.original?.status === "Pending"}
                onClick={() => exportCampaignToExcelHandler(row?.original)}
              >
                <img alt="Report" src={excelIcon} width={20} />
              </Button>
            </OverlayTrigger>
          </StyleRoot>
        </>
      ),
      // headerAlign: 'center',
      // align: 'center',
      // width: '125px'
    },
  ] as any;

  const renderEditColumn = (cell: any, rowData: any) => {
    const tooltipContent = (
      <Tooltip>
        Failed reason{" "}
        {rowData.failedMessage ? `: ${rowData.failedMessage}` : "not provided"}
      </Tooltip>
    );
    return (
      <>
        <div className="d-flex">
          <div className={`${rowData.status === "Pending" && "ps-4"} w-100`}>
            {rowData.status === "Completed" ? (
              <div className="badgeCon completed">Completed</div>
            ) : rowData.status === "Pending" ? (
              <div className="badgeCon pending ">Pending</div>
            ) : rowData.status === "Failed" ? (
              <>
                <div className="badgeCon failed">Failed</div>
                <OverlayTrigger
                  placement="bottom"
                  trigger={["hover", "hover"]}
                  overlay={tooltipContent}
                >
                  <svg
                    stroke="currentColor"
                    fill="red"
                    strokeWidth="0"
                    viewBox="0 0 16 16"
                    focusable="false"
                    className="css-anu9qi ms-2"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
                  </svg>
                </OverlayTrigger>
              </>
            ) : (
              <div className="badgeCon failed">Cancelled</div>
            )}
          </div>

          {rowData.status === "Pending" ? (
            <Dropdown>
              <Dropdown.Toggle variant="link" className="campaign-item-action">
                <BsThreeDotsVertical />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => cancelBroadcastHandler(rowData.uid)}
                >
                  Cancel
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : null}
        </div>
      </>
    );
  };
  const changeDateFilterHandler = (event: any) => {
    const filter = event.target.value as DateFilterType;
    setSearch("");
    setIsFilter(false);
    // setStatusFilters([])
    switch (filter) {
      case DateFilterType.Past7Days:
        setDateRangeValue(
          moment.range(
            today.clone().subtract(7, "days").startOf("day"),
            today.endOf("day")
          )
        );
        setShowDatePicker(false);
        break;

      case DateFilterType.Past30Days:
        setDateRangeValue(moment.range(getStartDate(30), today.endOf("day")));
        setShowDatePicker(false);
        break;

      case DateFilterType.Past90Days:
        setDateRangeValue(moment.range(getStartDate(90), today.endOf("day")));
        setShowDatePicker(false);
        break;

      case DateFilterType.ThisWeek:
        setDateRangeValue(
          moment.range(today.clone().startOf("week"), today.endOf("day"))
        );
        setShowDatePicker(false);
        break;

      case DateFilterType.PreviousWeek:
        setDateRangeValue(
          moment.range(
            getStartDate(1, "week"),
            getStartDate(1, "week").endOf("week")
          )
        );
        setShowDatePicker(false);
        break;

      case DateFilterType.ThisMonth:
        setDateRangeValue(
          moment.range(today.clone().startOf("month"), today.endOf("day"))
        );
        setShowDatePicker(false);
        break;

      case DateFilterType.PreviousMonth:
        setDateRangeValue(
          moment.range(
            getStartDate(1, "month"),
            getStartDate(1, "month").endOf("month")
          )
        );
        setShowDatePicker(false);
        break;

      case DateFilterType.CustomRange:
        setShowDatePicker(true);
        break;

      default:
        break;
    }
    setDateFilter(filter);
  };
  let campaignOverview = <Spinner />;
  const isFiltersApplied =
    searchQuery.trim() !== "" || statusFilters.length > 0;
  const loading = false;
  
  // isFiltersApplied
  //   ? // campaignFilterQuery.isLoading
  //     campaignOverviewQuery?.length === 0
  //   : // campaignFilterQuery?.length === 0
  //     "";
  // //  campaignOverviewQuery.isLoading;
  // const data = isFiltersApplied
  //   ? //  campaignFilterQuery.data
  //     campaignFilterQuery
  //   : "";
  //  campaignOverviewQuery?.data?.broadCastList;

  if (channelUid)
    if (
      // campaignOverviewQuery.isLoading
      // ||
      campaignCountQuery.isLoading
    ) {
      return <Loader />;
    }
  const isError =
    // campaignOverviewQuery.isError ||
    // campaignFilterQuery.isError ||
    campaignCountQuery.isError;
  if (!loading) {
    campaignOverview = (
      <div className="campaignStatusCon">
        <div className="stats">
          <div className="status">
            <div className="statusCount">
              {!isError ? dashBoardCounts?.totalRecipients : "--"}
            </div>
            <p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
              >
                <path
                  d="M10.9857 9.84369C10.343 8.71574 9.33949 7.83686 8.13664 7.34853C8.7348 6.89991 9.17665 6.27445 9.39959 5.56076C9.62253 4.84706 9.61527 4.08131 9.37882 3.37197C9.14238 2.66264 8.68874 2.04568 8.08217 1.60848C7.4756 1.17129 6.74684 0.936035 5.99914 0.936035C5.25143 0.936035 4.52268 1.17129 3.91611 1.60848C3.30953 2.04568 2.8559 2.66264 2.61945 3.37197C2.38301 4.08131 2.37574 4.84706 2.59868 5.56076C2.82162 6.27445 3.26347 6.89991 3.86164 7.34853C2.65879 7.83686 1.65525 8.71574 1.01257 9.84369C0.972751 9.90772 0.946209 9.97911 0.934526 10.0536C0.922844 10.1281 0.926258 10.2042 0.944568 10.2773C0.962877 10.3505 0.995706 10.4192 1.0411 10.4794C1.0865 10.5396 1.14354 10.5901 1.20884 10.6278C1.27413 10.6655 1.34634 10.6897 1.42117 10.699C1.49601 10.7083 1.57194 10.7024 1.64446 10.6817C1.71697 10.661 1.78459 10.626 1.84329 10.5787C1.902 10.5313 1.95058 10.4727 1.98617 10.4062C2.83554 8.93807 4.33554 8.06244 5.99914 8.06244C7.66273 8.06244 9.16273 8.93853 10.0121 10.4062C10.0892 10.5302 10.2115 10.6193 10.3532 10.6548C10.4948 10.6903 10.6447 10.6692 10.7712 10.5962C10.8976 10.5231 10.9907 10.4038 11.0307 10.2634C11.0707 10.1229 11.0546 9.97243 10.9857 9.84369ZM3.56164 4.49994C3.56164 4.01785 3.70459 3.54658 3.97243 3.14574C4.24027 2.74489 4.62095 2.43247 5.06635 2.24798C5.51174 2.0635 6.00184 2.01523 6.47467 2.10928C6.9475 2.20333 7.38182 2.43548 7.72271 2.77637C8.0636 3.11726 8.29575 3.55158 8.3898 4.02441C8.48385 4.49724 8.43558 4.98734 8.25109 5.43273C8.0666 5.87813 7.75418 6.25881 7.35334 6.52665C6.95249 6.79448 6.48123 6.93744 5.99914 6.93744C5.3529 6.9367 4.73334 6.67965 4.27639 6.22269C3.81943 5.76573 3.56238 5.14618 3.56164 4.49994Z"
                  fill="#888888"
                />
              </svg>{" "}
              &nbsp;Recipients
            </p>
          </div>
          <div className="status">
            <div className="statusCount">
              {!isError ? dashBoardCounts?.totalSentCount : "--"}
            </div>
            <p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
              >
                <path
                  d="M2.0625 6.5625L4.6875 9.1875L9.9375 3.5625"
                  stroke="#888888"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                />
              </svg>{" "}
              &nbsp;Sent
            </p>
          </div>
          <div className="status">
            <div className="statusCount">
              {!isError ? dashBoardCounts?.totalDeliveredCount : "--"}
            </div>
            <p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
              >
                <path
                  d="M1.3125 7.3125L3.1875 9.1875M5.8125 6.1875L7.6875 4.3125M4.3125 7.3125L6.1875 9.1875L10.6875 4.3125"
                  stroke="#888888"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                />
              </svg>{" "}
              &nbsp;Delivered
            </p>
          </div>
          <div className="status">
            <div className="statusCount">
              {!isError ? dashBoardCounts?.totalReadCount : "--"}
            </div>
            <p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
              >
                <path
                  d="M1.3125 7.3125L3.1875 9.1875M5.8125 6.1875L7.6875 4.3125M4.3125 7.3125L6.1875 9.1875L10.6875 4.3125"
                  stroke="#007EC3"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                />
              </svg>{" "}
              &nbsp;Read
            </p>
          </div>
          <div className="status">
            <div className="statusCount">
              {!isError ? dashBoardCounts?.totalRepliedCount : "--"}
            </div>
            <p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
              >
                <path
                  d="M4 2.5L1.5 5L4 7.5"
                  stroke="#888888"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M1.5 5H5.5C8.2615 5 10.5 7.2385 10.5 10V10.5"
                  stroke="#888888"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                />
              </svg>{" "}
              &nbsp;Replied
            </p>
          </div>
          <div className="status">
            <div className="statusCount">
              {!isError ? dashBoardCounts?.totalFailedCount : "--"}
            </div>
            <p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
              >
                <g clipPath="url(#clip0_2402_33718)">
                  <path
                    d="M8.17086 4.6609C8.21889 4.61612 8.25763 4.56232 8.28487 4.50257C8.31211 4.44281 8.32731 4.37828 8.32961 4.31265C8.33191 4.24703 8.32125 4.18159 8.29826 4.12008C8.27527 4.05856 8.24039 4.00218 8.19561 3.95415C8.15083 3.90612 8.09703 3.86738 8.03727 3.84014C7.97752 3.81291 7.91299 3.7977 7.84736 3.7954C7.78173 3.79311 7.71629 3.80376 7.65478 3.82675C7.59327 3.84974 7.53689 3.88462 7.48886 3.9294L6.02586 5.2934L4.66186 3.8299C4.57059 3.73642 4.44636 3.68238 4.31576 3.67934C4.18515 3.67629 4.05854 3.72448 3.96301 3.81361C3.86749 3.90273 3.81064 4.0257 3.80463 4.1562C3.79862 4.28671 3.84393 4.41438 3.93086 4.5119L5.29486 5.9749L3.83136 7.3389C3.78163 7.38323 3.74126 7.43705 3.71262 7.49719C3.68399 7.55734 3.66766 7.6226 3.66459 7.68915C3.66153 7.75569 3.67179 7.82218 3.69478 7.8847C3.71777 7.94722 3.75302 8.00452 3.79847 8.05323C3.84391 8.10194 3.89863 8.14108 3.95941 8.16834C4.02019 8.19561 4.08581 8.21045 4.15241 8.21201C4.219 8.21356 4.28524 8.20179 4.34723 8.17739C4.40921 8.15298 4.46569 8.11644 4.51336 8.0699L5.97636 6.7064L7.34036 8.1694C7.3844 8.22005 7.43819 8.26131 7.49852 8.29072C7.55884 8.32014 7.62448 8.33711 7.6915 8.34062C7.75853 8.34413 7.82557 8.33411 7.88864 8.31116C7.95171 8.28821 8.00952 8.25279 8.05861 8.20702C8.1077 8.16126 8.14707 8.10607 8.17439 8.04476C8.2017 7.98345 8.21639 7.91727 8.21758 7.85017C8.21877 7.78306 8.20643 7.7164 8.18131 7.65416C8.15619 7.59193 8.1188 7.53538 8.07136 7.4879L6.70786 6.0249L8.17086 4.6609Z"
                    fill="#888888"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.5 6C0.5 2.9625 2.9625 0.5 6 0.5C9.0375 0.5 11.5 2.9625 11.5 6C11.5 9.0375 9.0375 11.5 6 11.5C2.9625 11.5 0.5 9.0375 0.5 6ZM6 10.5C5.40905 10.5 4.82389 10.3836 4.27792 10.1575C3.73196 9.93131 3.23588 9.59984 2.81802 9.18198C2.40016 8.76412 2.06869 8.26804 1.84254 7.72207C1.6164 7.17611 1.5 6.59095 1.5 6C1.5 5.40905 1.6164 4.82389 1.84254 4.27792C2.06869 3.73196 2.40016 3.23588 2.81802 2.81802C3.23588 2.40016 3.73196 2.06869 4.27792 1.84254C4.82389 1.6164 5.40905 1.5 6 1.5C7.19347 1.5 8.33807 1.97411 9.18198 2.81802C10.0259 3.66193 10.5 4.80653 10.5 6C10.5 7.19347 10.0259 8.33807 9.18198 9.18198C8.33807 10.0259 7.19347 10.5 6 10.5Z"
                    fill="#888888"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2402_33718">
                    <rect width="12" height="12" fill="white" />
                  </clipPath>
                </defs>
              </svg>{" "}
              &nbsp;Failed
            </p>
          </div>
          <div className="status">
            <div className="statusCount">
              {!isError ? dashBoardCounts?.totalReactionCount : "--"}
            </div>
            <p>
              <img src={Smile} alt="Smile"></img> &nbsp;Reactions
            </p>
          </div>
          <div className="status">
            <div className="statusCount">
              {!isError ? dashBoardCounts?.totalClicksCount : "--"}
            </div>
            <p>
              <svg
                stroke="currentColor"
                fill="none"
                stroke-width="2"
                viewBox="0 0 24 24"
                stroke-linecap="round"
                stroke-linejoin="round"
                focusable="false"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="5" y="2" width="14" height="20" rx="7"></rect>
                <path d="M12 6v4"></path>
              </svg>{" "}
              &nbsp;Clicks
            </p>
          </div>
        </div>
      </div>
    );
  }

  let campaignHistory = <Spinner />;

  if (isError || !channelUid) {
    campaignHistory = (
      <NoDataFallback message="You have no campaigns to view" />
    );
  } else if (!loading) {
    campaignHistory = (
      <div className="row campaignTable">
        {/* <BootstrapTable
          data={data.broadcastDetails.filter((data: any) =>
            statusFilters.find((status) => status === data.status)
          )}
          pagination={data.broadcastDetails.length > 10}
          version="4"
          striped
          hover
          options={options as any}
        >
          <TableHeaderColumn dataField="id" isKey hidden>
            #
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="name"
            dataAlign="left"
            columnClassName="text-wrap"
            dataFormat={(value, rowData: any) => {
              return (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => setCampaignDetail(rowData)}
                >
                  <div>{value}</div>
                  <div>
                    {rowData.communityList.find(
                      (community: any) => community.uploadMethod === "Group"
                    ) ? (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                        >
                          <path
                            d="M13.25 13.3332H5.125V1.6665H13.25C13.9375 1.6665 14.5 2.1915 14.5 2.83317V12.1665C14.5 12.8082 13.9375 13.3332 13.25 13.3332Z"
                            fill="#ededf5"
                          />
                          <path
                            d="M4.5 1.6665H5.125V13.3332H4.5C3.8125 13.3332 3.25 12.8082 3.25 12.1665V2.83317C3.25 2.1915 3.8125 1.6665 4.5 1.6665Z"
                            fill="#666E7D"
                          />
                          <path
                            d="M9.5 7.49984C10.1904 7.49984 10.75 6.9775 10.75 6.33317C10.75 5.68884 10.1904 5.1665 9.5 5.1665C8.80964 5.1665 8.25 5.68884 8.25 6.33317C8.25 6.9775 8.80964 7.49984 9.5 7.49984Z"
                            fill="#666E7D"
                          />
                          <path
                            d="M11.6875 9.24967C11.6875 9.24967 11.0938 8.08301 9.5 8.08301C7.90625 8.08301 7.3125 9.24967 7.3125 9.24967V9.83301H11.6875V9.24967Z"
                            fill="#666E7D"
                          />
                        </svg>
                        <span
                          className="ml-1"
                          style={{ color: "#8c8ea0" }}
                          title={rowData.communityList
                            .filter(
                              (community: any) =>
                                community.uploadMethod === "Group"
                            )
                            .map((community: any) => community.name)
                            .join(", ")}
                        >
                          {rowData.communityList
                            .filter(
                              (community: any) =>
                                community.uploadMethod === "Group"
                            )
                            .map((community: any) => community.name)
                            .join(", ")
                            .slice(0, 10)}
                        </span>
                      </>
                    ) : null}
                    {rowData.communityList.find(
                      (community: any) => community.uploadMethod === "Excel"
                    ) ? (
                      <>
                        <img alt="" src={excelIcon} width={15} />
                        <span
                          className="ml-1"
                          style={{ color: "#8c8ea0" }}
                          title={rowData.communityList
                            .filter(
                              (community: any) =>
                                community.uploadMethod === "Excel"
                            )
                            .map((community: any) => community.name)
                            .join(", ")}
                        >
                          {rowData.communityList
                            .filter(
                              (community: any) =>
                                community.uploadMethod === "Excel"
                            )
                            .map((community: any) => community.name)
                            .join(", ")
                            .slice(0, 10)}
                        </span>
                      </>
                    ) : null}
                  </div>
                </div>
              );
            }}
          >
            Campaign Name
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="createdAt"
            dataAlign="center"
            dataFormat={(createdAt, campaign) => {
              return moment(
                campaign.type === "immediate" ? createdAt : campaign.scheduledAt
              ).format("DD/MM/YYYY");
            }}
          >
            Scheduled At
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="creatorId"
            dataAlign="left"
            dataFormat={(cell: string) => <>{memberData.get(cell || "")}</>}
            className="ps-0"
          >
            Created By
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="totalCount"
            dataAlign="center"
            dataFormat={formatPrefixWithZero}
            width="8%"
          >
            Recipients
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="sentCount"
            dataAlign="center"
            dataFormat={formatPrefixWithZero}
            width="8%"
          >
            Sent
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="deliveredCount"
            dataAlign="center"
            dataFormat={formatPrefixWithZero}
            width="%"
          >
            Delivered
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="readCount"
            dataAlign="center"
            dataFormat={formatPrefixWithZero}
            width="8%"
          >
            Read
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="repliedCount"
            dataAlign="center"
            dataFormat={(value, rowData: any) => (
              <span>
                {formatPrefixWithZero(value)}
                <OverlayTrigger
                  trigger={["hover", "hover"]}
                  placement="bottom"
                  overlay={
                    <Popover>
                      <CampaignReplyTooltip campaign={rowData} />
                    </Popover>
                  }
                >
                  <Button variant="link" className="pe-0 ps-1 pt-0">
                    <BsInfoCircleFill color="#909EBD" size={14} />
                  </Button>
                </OverlayTrigger>
              </span>
            )}
            width="8%"
          >
            Replied
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="clickedCount"
            dataAlign="center"
            dataFormat={formatPrefixWithZero}
            width="8%"
          >
            Clicked
          </TableHeaderColumn>

          <TableHeaderColumn
            dataField="failedCount"
            dataAlign="center"
            dataFormat={formatPrefixWithZero}
            width="8%"
          >
            Failed
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="edit"
            dataAlign="center"
            dataFormat={renderEditColumn}
            width="125"
          >
            Status
          </TableHeaderColumn>
          <TableHeaderColumn
            dataAlign="center"
            dataFormat={(_, campaign: any) => (
              <>
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    !isCampaignCreationEnabled ? (
                      <Tooltip placement="bottom" id="no-coins">
                        You don't have enough credits
                      </Tooltip>
                    ) : !isCampaignCreationEnabled_Plan ? (
                      <Tooltip placement="bottom" id="no-coins">
                        Maximum Campaign limit exceeded for Standard plan.
                      </Tooltip>
                    ) : (
                      <></>
                    )
                  }
                >
                  <span
                    style={{
                      cursor: !isCampaignCreationEnabled
                        ? "not-allowed"
                        : !isCampaignCreationEnabled_Plan
                        ? "not-allowed"
                        : "pointer",
                    }}
                  >
                    <Button
                      variant="light"
                      className={`campaignActionButton`}
                      onClick={() => repeatCampaignHandler(campaign)}
                      disabled={
                        !isCampaignCreationEnabled ||
                        !isCampaignCreationEnabled_Plan
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                      >
                        <path
                          d="M10.5 3.75L12 5.25L10.5 6.75"
                          stroke="#333"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M11.5 5.25H5C4.33757 5.25198 3.70283 5.51601 3.23442 5.98442C2.76601 6.45283 2.50198 7.08757 2.5 7.75V8.25M6.5 12.25L5 10.75L6.5 9.25"
                          stroke="#333"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M5.5 10.75H12C12.6624 10.748 13.2972 10.484 13.7656 10.0156C14.234 9.54717 14.498 8.91243 14.5 8.25V7.75"
                          stroke="#333"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </Button>
                  </span>
                </OverlayTrigger>
                <StyleRoot style={{ display: "inline" }}>
                  <Style
                    scopeSelector=".campaignRowDownloadExcelButton"
                    rules={
                      campaign.status === "Pending"
                        ? {
                            cursor: "not-allowed",
                            border: "none",
                          }
                        : {}
                    }
                  />
                  <OverlayTrigger
                    trigger={["hover", "hover"]}
                    placement="bottom"
                    overlay={<Tooltip>Download Excel</Tooltip>}
                  >
                    <Button
                      variant="light"
                      className={[
                        "campaignActionButton",
                        "campaignRowDownloadExcelButton",
                      ].join(" ")}
                      disabled={campaign.status === "Pending"}
                      onClick={() => exportCampaignToExcelHandler(campaign)}
                    >
                      <img alt="Report" src={excelIcon} width={20} />
                    </Button>
                  </OverlayTrigger>
                </StyleRoot>
              </>
            )}
            width="8%"
          >
            Action
          </TableHeaderColumn>
        </BootstrapTable> */}
        <BizTable
          columns={columns}
          fetchData={fetchData}
          counts={campaignOverviewCount}
          refetchData={campaignOverviewQuery}
          tableName="CAMPAIGNOVERVIEW"
        />
      </div>
    );
  }

  let campaignCount = null;

  if (
    campaignCountQuery.isLoading ||
    campaignCountQuery.isError ||
    business?.freeTrial
  ) {
    campaignCount = null;
  } else {
    if (channelData?.channelAllData?.currentLimit) {
      campaignCount = (
        <Alert className="campaignAlert">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
          >
            <path
              d="M8.55519 1.5C10.4118 1.5 12.1924 2.23755 13.5053 3.5504C14.8181 4.86325 15.5557 6.64385 15.5557 8.5005C15.5557 10.3571 14.8181 12.1378 13.5053 13.4506C12.1924 14.7634 10.4118 15.501 8.55519 15.501C6.69854 15.501 4.91793 14.7634 3.60509 13.4506C2.29224 12.1378 1.55469 10.3571 1.55469 8.5005C1.55469 6.64385 2.29224 4.86325 3.60509 3.5504C4.91793 2.23755 6.69854 1.5 8.55519 1.5ZM9.60519 5.798C10.1252 5.798 10.5472 5.437 10.5472 4.902C10.5472 4.367 10.1242 4.006 9.60519 4.006C9.08519 4.006 8.66519 4.367 8.66519 4.902C8.66519 5.437 9.08519 5.798 9.60519 5.798ZM9.78819 11.425C9.78819 11.318 9.82519 11.04 9.80419 10.882L8.98219 11.828C8.81219 12.007 8.59919 12.131 8.49919 12.098C8.45382 12.0813 8.4159 12.049 8.39226 12.0068C8.36861 11.9646 8.36078 11.9154 8.37019 11.868L9.74019 7.54C9.85219 6.991 9.54419 6.49 8.89119 6.426C8.20219 6.426 7.18819 7.125 6.57119 8.012C6.57119 8.118 6.55119 8.382 6.57219 8.54L7.39319 7.593C7.56319 7.416 7.76119 7.291 7.86119 7.325C7.91046 7.34268 7.95083 7.37898 7.97364 7.42609C7.99645 7.47321 7.99988 7.52739 7.98319 7.577L6.62519 11.884C6.46819 12.388 6.76519 12.882 7.48519 12.994C8.54519 12.994 9.17119 12.312 9.78919 11.425H9.78819Z"
              fill="#A88300"
            />
          </svg>
          {campaignCountQuery.data.totalCount} Campaign messages sent in the
          last 24 hours for a limit of{" "}
          {campaignTotal(channelData?.channelAllData?.currentLimit)} messages
          per day. You can send another{" "}
          {campaignTotal(channelData?.channelAllData?.currentLimit) -
            campaignCountQuery.data.totalCount}{" "}
          messages for now.
        </Alert>
      );
    } else {
      campaignCount = null;
    }
  }

  const getStartDate = (
    subtractDays: number,
    unit: moment.unitOfTime.DurationConstructor = "days"
  ) => {
    return today.clone().subtract(subtractDays, unit).startOf(unit);
  };

  //   const exportCampaignToExcelHandler = async(campaign: any) => {
  //     console.log('CAP',campaign)
  //     const getStatusAndTimeFor = (
  //       mobileNumber: string
  //     ): { status: string; time: null | string } => {
  //       const getTimeFor = (mappingKey: string): null | string => {
  //         if (mobileNumber in campaign[mappingKey].map) {
  //           const statusValue = campaign[mappingKey]?.map[mobileNumber];
  //           const date = statusValue["status"];
  //           const unixTimestamp = date * 1000; // Convert to milliseconds
  //           const current = new Date(unixTimestamp);
  //           const options = {
  //             year: "numeric",
  //             month: "short",
  //             day: "numeric",
  //             hour: "numeric",
  //             minute: "numeric",
  //             hour12: true,
  //           } as any;

  //           const formattedDate = new Intl.DateTimeFormat(
  //             "en-US",
  //             options
  //           ).format(current);

  //           return formattedDate;
  //         }
  //         return null;
  //       };

  //       const statuses = {
  //         Sent: "sentCountContacts",
  //         Delivered: "deliveredCountContacts",
  //         Read: "readCountContacts",
  //         Replied: "repliedCountContacts",
  //         Failed: "failedCountContacts",
  //         Clicked: "clickedCountContacts",
  //       };
  //       let status = "Not sent";
  //       let time = null;

  //       for (const [key, value] of Object.entries(statuses)) {
  //         const tempTime = getTimeFor(value);
  //         if (tempTime !== null) {
  //           status = key;
  //           time = tempTime;
  //         }
  //       }

  //       return {
  //         status,
  //         time,
  //       };
  //     };

  //     const getReplyMessageFor = (mobileNumber: string): null | string => {
  //       if (getStatusAndTimeFor(mobileNumber)?.status === "Replied") {
  //         const message = campaign.buttonMap[mobileNumber]?.repliedText;
  //         return message;
  //       }
  //       // else if (getStatusAndTimeFor(mobileNumber)?.status === 'Failed') {
  //       //     return campaign.failedCountContacts.map[mobileNumber].error;
  //       // }
  //       return null;
  //     };

  //     const getFailedMessageFor = (mobileNumber: string): null | string => {
  //       if (getStatusAndTimeFor(mobileNumber)?.status === "Failed") {
  //         return campaign.failedCountContacts.map[mobileNumber].error;
  //       }
  //       return null;
  //     };

  //     const getFailedErrorCodeFor = (mobileNumber: string): null | string => {
  //       if (getStatusAndTimeFor(mobileNumber)?.status === "Failed") {
  //         return campaign.failedCountContacts.map[mobileNumber].errorCode;
  //       }
  //       return null;
  //     };

  //     const getClickedUrlFor = (mobileNumber: string): null | string => {
  //       if (getStatusAndTimeFor(mobileNumber)?.status === "Clicked") {
  //         return campaign.buttonUrlMap[mobileNumber].clickedUrl;
  //       }
  //       return null;
  //     };

  //     const dataToExport =
  //       campaign &&
  //       campaign?.totalCountContacts?.map((contact: any) => {
  //         const { status, time } = getStatusAndTimeFor(contact.mobileNumber);
  //         const emoji =
  //           campaign?.reactionCountContacts?.map[contact.mobileNumber]?.emoji ||
  //           "";

  //         return [
  //           contact.name,
  //           contact.mobileNumber,
  //           status,
  //           time,
  //           getReplyMessageFor(contact.mobileNumber),
  //           emoji,
  //           getClickedUrlFor(contact.mobileNumber),

  //           getFailedErrorCodeFor(contact.mobileNumber),
  //           getFailedMessageFor(contact.mobileNumber),
  //         ];
  //       });
  // console.log('Dta',dataToExport)
  //     const workbook = new ExcelJS.Workbook();
  //     const worksheet = workbook.addWorksheet("Sheet1");

  //     const headerRow = worksheet.getRow(1);
  //     headerRow.font = { bold: true, color: { argb: "FFFFFFFF" } };
  //     headerRow.fill = {
  //       type: "pattern",
  //       pattern: "solid",
  //       fgColor: { argb: "FFFF7342" },
  //     };
  //     headerRow.alignment = { horizontal: "center" };

  //     worksheet.columns = [
  //       { header: "Contact Name", width: 20 },
  //       { header: "Contact Mobile Number", width: 27 },
  //       { header: "Status", width: 12 },
  //       {
  //         header: "Sent At",
  //         width: 23,
  //         style: { numFmt: "dd mmm yyyy hh:mm AM/PM" },
  //       },
  //       { header: "Reply", width: 15 },
  //       { header: "Reactions", width: 15 },
  //       { header: "Clicked", width: 30 },
  //       { header: "Error Code", width: 23 },
  //       { header: "Error Description", width: 25 },
  //     ];
  //     worksheet.views = [
  //       { state: "frozen", xSplit: 0, ySplit: 1, topLeftCell: "A2" },
  //     ];
  //     worksheet.autoFilter = {
  //       from: "C1", // The "Status" column
  //       to: "C999", // Assuming a maximum of 999 rows, adjust as needed
  //     };

  //     dataToExport.forEach((row: any) => {
  //       worksheet.addRow(row);
  //     });

  //     workbook.xlsx.writeBuffer().then((buffer: any) => {
  //       const blob = new Blob([buffer], {
  //         type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //       });
  //       saveAs(blob, `${campaign.name}-CampaignReport.xlsx`);
  //     });
  //   };
  const exportCampaignToExcelHandler = async (campaign: any) => {
    try {
      const detailPayload = {
        broadcastUid: campaign?.uid,
      };
      const response = await campaignServices.getBroadcastDetail(
        channelUid,
        detailPayload
      );

      const dataToExport = response?.broadcastDetails;
      console.log("Data to Export:", dataToExport);

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Campaign Report");

      // Define columns with keys that match the data object keys
      // worksheet.columns = [
      //   { header: "Contact Name", key: "name", width: 20 },
      //   { header: "Contact Mobile Number", key: "mobileNumber", width: 27 },
      //   { header: "Status", key: "status", width: 12 },
      //   { header: "Sent At", key: "statusTime", width: 23, style: { numFmt: "dd mmm yyyy hh:mm AM/PM" }},
      //   { header: "Error Code", key: "errorCode", width: 15 },
      //   { header: "Error Description", key: "errorDetails", width: 35 }
      // ];

      worksheet.columns = [
        { header: "Contact Name", key: "name", width: 20 },
        { header: "Contact Mobile Number", key: "mobileNumber", width: 27 },
        { header: "Status", key: "status", width: 12 },
        {
          header: "Sent At",
          key: "statusTime",
          width: 23,
          style: { numFmt: "dd mmm yyyy hh:mm AM/PM" },
        },
        { header: "Replied", key: "repliedMap", width: 15 },
        { header: "Reactions", key: "reactionMap", width: 15 },
        { header: "Clicked", key: "clickedMap", width: 30 },
        { header: "Error Code", key: "errorCode", width: 23 },
        { header: "Error Description", key: "errorDetails", width: 35 },
      ];
      // Apply styles to header
      worksheet.views = [
        { state: "frozen", xSplit: 0, ySplit: 1, topLeftCell: "A2" },
      ];
      worksheet.autoFilter = {
        from: "C1", // The "Status" column
        to: "C999", // Assuming a maximum of 999 rows, adjust as needed
      };
      worksheet.getRow(1).eachCell((cell) => {
        cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFF7342" },
        };
        cell.alignment = { horizontal: "center" };
      });

      // Add data rows to the worksheet
      dataToExport.forEach((item: any) => {
        const keys = Object.keys(item?.repliedMap);
        const clicked = Object.keys(item?.clickedMap);
        const emoji = Object.keys(item.reactionMap);
        const statusTime = new Date(item.statusTime);
       const errorMsg =  item.bizErrorMessage === 'Unknown Error Occured' ? item.errorDetails  : item.bizErrorMessage ;
        const row = {
          name: item.name,
          mobileNumber: item.mobileNumber,
          status: item.status,
          repliedMap: keys?.join(', '),
          clickedMap: clicked?.join(','),
          reactionMap : emoji?.join(','),
          statusTime: statusTime.toLocaleString(), // Ensure this is a valid date
          errorCode: item.errorCode === 0 ? '' : item.errorCode,
          errorDetails: errorMsg,
        };
        worksheet.addRow(row);
      });

      // Save the workbook to a buffer and then to a file
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, `${campaign.name}-CampaignReport.xlsx`);
    } catch (error) {
      console.error("Failed to export campaign to Excel:", error);
    }
  };

  let myCampaignDetail = campaignDetail;
  // if (
  //   campaignDetail !== null &&
  //   campaignSyncQuery.isFetched &&
  //   campaignSyncQuery.data.broadcastDetails[0].uid === campaignDetail.uid
  // ) {
  //   myCampaignDetail = campaignSyncQuery.data.broadcastDetails[0];
  // }

  let previewTemplate: any = null;
  if (myCampaignDetail) {
    const { template, media, templateText } = myCampaignDetail as any;
    if (template?.components?.[0]?.example?.header_handle) {
      template.components[0].example.header_handle[0] = media?.url;
    }
    if (template?.components?.[1]?.example) {
      template.components[1].example.body_text[0] = templateText.map(
        (item: any) => item?.bodyText
      );
    }

    // template?.media = media;

    previewTemplate = myCampaignDetail.template;

    processTemplateVariable(
      previewTemplate,
      myCampaignDetail.templateTexts
        ? myCampaignDetail.templateTexts.map(
            (variable: string, index: number) => {
              return {
                id: "" + (index + 1),
                name: "" + (index + 1),
                value: [variable],
              };
            }
          )
        : [],
      []
    );
  }
  return (
    <>
      <Offcanvas
        direction="end"
        toggle={() => setCampaignDetail(null)}
        isOpen={campaignDetail !== null}
        className={campaignInfoStyle.campaignInfo}
      >
        <div className={campaignInfoStyle.header}>
          <div>
            <Button
              onClick={() => setCampaignDetail(null)}
              variant="link"
              style={{ outline: "none", color: "black" }}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
            <span className={campaignInfoStyle.title}>
              {campaignDetail ? campaignDetail.name : "Campaign Info"}
              {campaignDetail && campaignDetail.createdAt ? (
                <small>
                  (
                  {moment(campaignDetail.createdAt).format("DD MMM YY, hh:mma")}
                  )
                </small>
              ) : null}
            </span>
          </div>
          <div>
            <SyncButton
              onClick={async () => {
                try {

                   const response =    await axios.post(`broadcast/${channelUid}/getBroadcastDashboard`,{
                        broadcastUid:campaignDetail?.uid
                      })    
                      const {broadCastList} = response?.data;
                      if(broadCastList){
                        setCampaignDetail(broadCastList[0])
                      }            
                    // await campaignSyncQuery.refetch();

                  toast("success", "Campaign synced successfully");
                } catch (error) {
                  console.error(error);
                  toast("error", "Campaign failed to sync");
                }
              }}
            />
          </div>
        </div>
        <OffcanvasBody>
          {myCampaignDetail && (
            <>
              <div className="campaignStatusCon">
                <div className="stats">
                  <div className="status">
                    <div className="statusCount">
                      {myCampaignDetail.broadCastMessageList?.totalRecipients ??
                        "0"}
                    </div>
                    <p>
                      <svg
                      className="me-1"
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <path
                          d="M10.9857 9.84369C10.343 8.71574 9.33949 7.83686 8.13664 7.34853C8.7348 6.89991 9.17665 6.27445 9.39959 5.56076C9.62253 4.84706 9.61527 4.08131 9.37882 3.37197C9.14238 2.66264 8.68874 2.04568 8.08217 1.60848C7.4756 1.17129 6.74684 0.936035 5.99914 0.936035C5.25143 0.936035 4.52268 1.17129 3.91611 1.60848C3.30953 2.04568 2.8559 2.66264 2.61945 3.37197C2.38301 4.08131 2.37574 4.84706 2.59868 5.56076C2.82162 6.27445 3.26347 6.89991 3.86164 7.34853C2.65879 7.83686 1.65525 8.71574 1.01257 9.84369C0.972751 9.90772 0.946209 9.97911 0.934526 10.0536C0.922844 10.1281 0.926258 10.2042 0.944568 10.2773C0.962877 10.3505 0.995706 10.4192 1.0411 10.4794C1.0865 10.5396 1.14354 10.5901 1.20884 10.6278C1.27413 10.6655 1.34634 10.6897 1.42117 10.699C1.49601 10.7083 1.57194 10.7024 1.64446 10.6817C1.71697 10.661 1.78459 10.626 1.84329 10.5787C1.902 10.5313 1.95058 10.4727 1.98617 10.4062C2.83554 8.93807 4.33554 8.06244 5.99914 8.06244C7.66273 8.06244 9.16273 8.93853 10.0121 10.4062C10.0892 10.5302 10.2115 10.6193 10.3532 10.6548C10.4948 10.6903 10.6447 10.6692 10.7712 10.5962C10.8976 10.5231 10.9907 10.4038 11.0307 10.2634C11.0707 10.1229 11.0546 9.97243 10.9857 9.84369ZM3.56164 4.49994C3.56164 4.01785 3.70459 3.54658 3.97243 3.14574C4.24027 2.74489 4.62095 2.43247 5.06635 2.24798C5.51174 2.0635 6.00184 2.01523 6.47467 2.10928C6.9475 2.20333 7.38182 2.43548 7.72271 2.77637C8.0636 3.11726 8.29575 3.55158 8.3898 4.02441C8.48385 4.49724 8.43558 4.98734 8.25109 5.43273C8.0666 5.87813 7.75418 6.25881 7.35334 6.52665C6.95249 6.79448 6.48123 6.93744 5.99914 6.93744C5.3529 6.9367 4.73334 6.67965 4.27639 6.22269C3.81943 5.76573 3.56238 5.14618 3.56164 4.49994Z"
                          fill="#888888"
                        />
                      </svg>
                      Recipients
                    </p>
                  </div>
                  <div className="status">
                    <div className="statusCount">
                      {myCampaignDetail.broadCastMessageList?.totalSentCount ??
                        "0"}
                    </div>
                    <p>
                      <svg
                      className="me-1"
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <path
                          d="M2.0625 6.5625L4.6875 9.1875L9.9375 3.5625"
                          stroke="#888888"
                          strokeLinecap="round"
                          stroke-linejoin="round"
                        />
                      </svg> 
                      Sent
                    </p>
                  </div>
                  <div className="status">
                    <div className="statusCount">
                      {myCampaignDetail.broadCastMessageList
                        ?.totalDeliveredCount ?? "0"}
                    </div>
                    <p>
                      <svg
                      className="me-1"
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <path
                          d="M1.3125 7.3125L3.1875 9.1875M5.8125 6.1875L7.6875 4.3125M4.3125 7.3125L6.1875 9.1875L10.6875 4.3125"
                          stroke="#888888"
                          strokeLinecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Delivered
                    </p>
                  </div>
                  <div className="status">
                    <div className="statusCount">
                      {myCampaignDetail.broadCastMessageList?.totalReadCount ??
                        "0"}
                    </div>
                    <p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        className="me-1"
                      >
                        <path
                          d="M1.3125 7.3125L3.1875 9.1875M5.8125 6.1875L7.6875 4.3125M4.3125 7.3125L6.1875 9.1875L10.6875 4.3125"
                          stroke="#007EC3"
                          strokeLinecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Read
                    </p>
                  </div>
                  <OverlayTrigger
                    trigger={["hover", "hover"]}
                    placement="bottom"
                    overlay={
                      <Popover>
                        <CampaignReplyTooltip campaign={myCampaignDetail} />
                      </Popover>
                    }
                  >
                    <div className="status" style={{ cursor: "help" }}>
                      <div className="statusCount">
                        {myCampaignDetail.broadCastMessageList
                          ?.totalRepliedCount ?? "0"}
                      </div>
                      <p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          className="me-1"
                        >
                          <path
                            d="M4 2.5L1.5 5L4 7.5"
                            stroke="#888888"
                            strokeLinecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M1.5 5H5.5C8.2615 5 10.5 7.2385 10.5 10V10.5"
                            stroke="#888888"
                            strokeLinecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        Replied
                      </p>
                    </div>
                  </OverlayTrigger>
                  <div className="status">
                    <div className="statusCount">
                      {myCampaignDetail.broadCastMessageList
                        ?.totalFailedCount ?? "0"}
                    </div>
                    <p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                   className="me-1"
                      >
                        <g clipPath="url(#clip0_2402_33718)">
                          <path
                            d="M8.17086 4.6609C8.21889 4.61612 8.25763 4.56232 8.28487 4.50257C8.31211 4.44281 8.32731 4.37828 8.32961 4.31265C8.33191 4.24703 8.32125 4.18159 8.29826 4.12008C8.27527 4.05856 8.24039 4.00218 8.19561 3.95415C8.15083 3.90612 8.09703 3.86738 8.03727 3.84014C7.97752 3.81291 7.91299 3.7977 7.84736 3.7954C7.78173 3.79311 7.71629 3.80376 7.65478 3.82675C7.59327 3.84974 7.53689 3.88462 7.48886 3.9294L6.02586 5.2934L4.66186 3.8299C4.57059 3.73642 4.44636 3.68238 4.31576 3.67934C4.18515 3.67629 4.05854 3.72448 3.96301 3.81361C3.86749 3.90273 3.81064 4.0257 3.80463 4.1562C3.79862 4.28671 3.84393 4.41438 3.93086 4.5119L5.29486 5.9749L3.83136 7.3389C3.78163 7.38323 3.74126 7.43705 3.71262 7.49719C3.68399 7.55734 3.66766 7.6226 3.66459 7.68915C3.66153 7.75569 3.67179 7.82218 3.69478 7.8847C3.71777 7.94722 3.75302 8.00452 3.79847 8.05323C3.84391 8.10194 3.89863 8.14108 3.95941 8.16834C4.02019 8.19561 4.08581 8.21045 4.15241 8.21201C4.219 8.21356 4.28524 8.20179 4.34723 8.17739C4.40921 8.15298 4.46569 8.11644 4.51336 8.0699L5.97636 6.7064L7.34036 8.1694C7.3844 8.22005 7.43819 8.26131 7.49852 8.29072C7.55884 8.32014 7.62448 8.33711 7.6915 8.34062C7.75853 8.34413 7.82557 8.33411 7.88864 8.31116C7.95171 8.28821 8.00952 8.25279 8.05861 8.20702C8.1077 8.16126 8.14707 8.10607 8.17439 8.04476C8.2017 7.98345 8.21639 7.91727 8.21758 7.85017C8.21877 7.78306 8.20643 7.7164 8.18131 7.65416C8.15619 7.59193 8.1188 7.53538 8.07136 7.4879L6.70786 6.0249L8.17086 4.6609Z"
                            fill="#888888"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0.5 6C0.5 2.9625 2.9625 0.5 6 0.5C9.0375 0.5 11.5 2.9625 11.5 6C11.5 9.0375 9.0375 11.5 6 11.5C2.9625 11.5 0.5 9.0375 0.5 6ZM6 10.5C5.40905 10.5 4.82389 10.3836 4.27792 10.1575C3.73196 9.93131 3.23588 9.59984 2.81802 9.18198C2.40016 8.76412 2.06869 8.26804 1.84254 7.72207C1.6164 7.17611 1.5 6.59095 1.5 6C1.5 5.40905 1.6164 4.82389 1.84254 4.27792C2.06869 3.73196 2.40016 3.23588 2.81802 2.81802C3.23588 2.40016 3.73196 2.06869 4.27792 1.84254C4.82389 1.6164 5.40905 1.5 6 1.5C7.19347 1.5 8.33807 1.97411 9.18198 2.81802C10.0259 3.66193 10.5 4.80653 10.5 6C10.5 7.19347 10.0259 8.33807 9.18198 9.18198C8.33807 10.0259 7.19347 10.5 6 10.5Z"
                            fill="#888888"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2402_33718">
                            <rect width="12" height="12" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      Failed
                    </p>
                  </div>
                  <div className="status">
                    <div className="statusCount">
                      {myCampaignDetail.broadCastMessageList
                        ?.totalReactionCount ?? "0"}
                    </div>
                    <p>
                      <img src={Smile} alt="Smile"  className="me-1"></img> Reactions
                    </p>
                  </div>
                  <div className="status">
                    <div className="statusCount">
                      {myCampaignDetail.broadCastMessageList
                        ?.totalClicksCount ?? "0"}
                    </div>
                    <p>
                      <svg
                        className="me-1"
                        stroke="currentColor"
                        fill="none"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        focusable="false"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect x="5" y="2" width="14" height="20" rx="7"></rect>
                        <path d="M12 6v4"></path>
                      </svg>
                      Clicks
                    </p>
                  </div>
                </div>
              </div>
              <Row className={campaignInfoStyle.infoGrid}>
                <Col md={6}>
                  <Row>
                    <Card className="col mt-0">
                      <CardBody>
                        <CardSubtitle>Scheduled Type :-</CardSubtitle>
                        {myCampaignDetail.type === "immediate"
                          ? "Immediate"
                          : "Custom Date"}
                      </CardBody>
                    </Card>
                    <Card className="col mt-0">
                      <CardBody>
                        <CardSubtitle>Scheduled At :-</CardSubtitle>
                        <span style={{ fontSize: 10 }}>
                          {moment(
                            myCampaignDetail.type === "immediate"
                              ? myCampaignDetail.createdAt
                              : myCampaignDetail.scheduledAt
                          ).format("DD MMM YY | hh:mma")}
                        </span>
                      </CardBody>
                    </Card>
                  </Row>
                  <Row>
                    <Card className="col">
                      <CardBody>
                        <CardSubtitle>Status :-</CardSubtitle>
                        {myCampaignDetail.status === "Completed" ? (
                          <div className="badgeCon completed">Completed</div>
                        ) : myCampaignDetail.status === "Pending" ? (
                          <div className="badgeCon pending">Pending</div>
                        ) : myCampaignDetail.status === "Failed" ? (
                          <div className="badgeCon failed">Failed</div>
                        ) : (
                          <div className="badgeCon failed">Cancelled</div>
                        )}
                      </CardBody>
                    </Card>
                    <Card className="col">
                      <StyleRoot>
                        <Style
                          scopeSelector=".campaignRowDownloadExcelCard"
                          rules={
                            myCampaignDetail.status === "Pending"
                              ? {
                                  cursor: "not-allowed",
                                  position: "relative",
                                  ".disableOverlay": {
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    background: "rgba(0,0,0,0.15)",
                                  },
                                }
                              : {
                                  cursor: "pointer",
                                }
                          }
                        />
                        <CardBody
                          className={[
                            campaignInfoStyle.excelGrid,
                            "campaignRowDownloadExcelCard",
                          ].join(" ")}
                          onClick={() => {
                            if (myCampaignDetail.status !== "Pending") {
                              exportCampaignToExcelHandler(myCampaignDetail);
                            }
                          }}
                        >
                          <div className="disableOverlay"></div>
                          <img alt="Report" src={excelIcon} />
                          <div className={campaignInfoStyle.download}>
                            Excel Report
                            <button
                              disabled={myCampaignDetail.status === "Pending"}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="9"
                                height="9"
                                viewBox="0 0 9 9"
                                fill="none"
                              >
                                <path
                                  d="M2.25 7.5H6.75M4.5 1.5V6M4.5 6L5.8125 4.6875M4.5 6L3.1875 4.6875"
                                  stroke="#007EC3"
                                  strokeWidth="0.75"
                                  strokeLinecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              Download
                            </button>
                          </div>
                        </CardBody>
                      </StyleRoot>
                    </Card>
                  </Row>

                  <div className={campaignInfoStyle?.contactSelected}>
                    {myCampaignDetail?.communityList?.find(
                      (community: any) => community.uploadMethod === "Group"
                    ) ? (
                      <div className={campaignInfoStyle.item}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="18"
                          viewBox="0 0 15 15"
                          fill="none"
                        >
                          <path
                            d="M13.25 13.3332H5.125V1.6665H13.25C13.9375 1.6665 14.5 2.1915 14.5 2.83317V12.1665C14.5 12.8082 13.9375 13.3332 13.25 13.3332Z"
                            fill="#ededf5"
                          />
                          <path
                            d="M4.5 1.6665H5.125V13.3332H4.5C3.8125 13.3332 3.25 12.8082 3.25 12.1665V2.83317C3.25 2.1915 3.8125 1.6665 4.5 1.6665Z"
                            fill="#666E7D"
                          />
                          <path
                            d="M9.5 7.49984C10.1904 7.49984 10.75 6.9775 10.75 6.33317C10.75 5.68884 10.1904 5.1665 9.5 5.1665C8.80964 5.1665 8.25 5.68884 8.25 6.33317C8.25 6.9775 8.80964 7.49984 9.5 7.49984Z"
                            fill="#666E7D"
                          />
                          <path
                            d="M11.6875 9.24967C11.6875 9.24967 11.0938 8.08301 9.5 8.08301C7.90625 8.08301 7.3125 9.24967 7.3125 9.24967V9.83301H11.6875V9.24967Z"
                            fill="#666E7D"
                          />
                        </svg>
                        {myCampaignDetail?.communityList
                          .filter(
                            (community: any) =>
                              community.uploadMethod === "Group"
                          )
                          .reduce(
                            (sum: number, community: any) =>
                              sum + community?.contactCount,
                            0
                          )}{" "}
                        Contacts selected from Contacts
                      </div>
                    ) : null}
                    {myCampaignDetail?.communityList?.find(
                      (community: any) => community.uploadMethod === "Excel"
                    ) ? (
                      <div className={campaignInfoStyle?.item}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                        >
                          <path
                            d="M9.46634 7.15508L3.58594 6.11914V13.7739C3.58601 13.8571 3.60246 13.9394 3.63434 14.0162C3.66623 14.093 3.71294 14.1628 3.77179 14.2215C3.83064 14.2803 3.90049 14.3269 3.97734 14.3586C4.0542 14.3904 4.13656 14.4067 4.21972 14.4066H14.3666C14.4498 14.4068 14.5323 14.3906 14.6093 14.3589C14.6863 14.3272 14.7562 14.2807 14.8152 14.2219C14.8742 14.1631 14.921 14.0933 14.9529 14.0165C14.9849 13.9396 15.0014 13.8572 15.0014 13.7739V10.9535L9.46634 7.15508Z"
                            fill="#185C37"
                          />
                          <path
                            d="M9.46634 0.59375H4.21972C4.13656 0.59368 4.0542 0.609991 3.97734 0.64175C3.90049 0.673509 3.83064 0.720096 3.77179 0.778849C3.71294 0.837603 3.66623 0.907372 3.63434 0.984175C3.60246 1.06098 3.58601 1.14331 3.58594 1.22647V4.04688L9.46634 7.5L12.58 8.53594L15.0014 7.5V4.04688L9.46634 0.59375Z"
                            fill="#21A366"
                          />
                          <path
                            d="M3.58594 4.04688H9.46634V7.5H3.58594V4.04688Z"
                            fill="#107C41"
                          />
                          <path
                            opacity="0.1"
                            d="M7.7945 3.35645H3.58594V11.9893H7.7945C7.9622 11.9884 8.12281 11.9215 8.24154 11.8031C8.36027 11.6847 8.42756 11.5242 8.42881 11.3565V3.98916C8.42756 3.82147 8.36027 3.66102 8.24154 3.54259C8.12281 3.42416 7.9622 3.35728 7.7945 3.35645Z"
                            fill="black"
                          />
                          <path
                            opacity="0.2"
                            d="M7.44866 3.70117H3.58594V12.334H7.44866C7.61635 12.3332 7.77697 12.2663 7.8957 12.1478C8.01443 12.0294 8.08171 11.869 8.08297 11.7013V4.33389C8.08171 4.1662 8.01443 4.00575 7.8957 3.88732C7.77697 3.76888 7.61635 3.70201 7.44866 3.70117Z"
                            fill="black"
                          />
                          <path
                            opacity="0.2"
                            d="M7.44866 3.70117H3.58594V11.6434H7.44866C7.61635 11.6425 7.77697 11.5756 7.8957 11.4572C8.01443 11.3388 8.08171 11.1783 8.08297 11.0106V4.33389C8.08171 4.1662 8.01443 4.00575 7.8957 3.88732C7.77697 3.76888 7.61635 3.70201 7.44866 3.70117Z"
                            fill="black"
                          />
                          <path
                            opacity="0.2"
                            d="M7.10281 3.70117H3.58594V11.6434H7.10281C7.27051 11.6425 7.43113 11.5756 7.54986 11.4572C7.66859 11.3388 7.73587 11.1783 7.73713 11.0106V4.33389C7.73587 4.1662 7.66859 4.00575 7.54986 3.88732C7.43113 3.76888 7.27051 3.70201 7.10281 3.70117Z"
                            fill="black"
                          />
                          <path
                            d="M0.759313 3.70117H7.10137C7.26937 3.70103 7.43055 3.7676 7.54949 3.88624C7.66844 4.00488 7.73541 4.16589 7.73569 4.33389V10.6653C7.73541 10.8333 7.66844 10.9943 7.54949 11.113C7.43055 11.2316 7.26937 11.2982 7.10137 11.298H0.759313C0.676108 11.2982 0.593692 11.2819 0.516775 11.2502C0.439857 11.2185 0.369946 11.1719 0.311038 11.1131C0.25213 11.0544 0.205379 10.9846 0.173457 10.9077C0.141536 10.8309 0.12507 10.7485 0.125 10.6653V4.33389C0.12507 4.25069 0.141536 4.16831 0.173457 4.09147C0.205379 4.01464 0.25213 3.94484 0.311038 3.88608C0.369946 3.82732 0.439857 3.78075 0.516775 3.74902C0.593692 3.71729 0.676108 3.70103 0.759313 3.70117Z"
                            fill="url(#paint0_linear_11_13)"
                          />
                          <path
                            d="M2.08984 9.55744L3.42381 7.49407L2.20194 5.44238H3.18316L3.84987 6.75616C3.9115 6.88048 3.95613 6.97291 3.97631 7.03454H3.98534C4.02891 6.93466 4.07512 6.83851 4.12347 6.74448L4.83641 5.44451H5.73953L4.48631 7.48451L5.77141 9.55904H4.81037L4.04006 8.11882C4.0043 8.0569 3.9739 7.99203 3.94922 7.92491H3.93647C3.91401 7.9903 3.88406 8.05287 3.84722 8.11138L3.05406 9.55744H2.08984Z"
                            fill="white"
                          />
                          <path
                            d="M14.3656 0.593751H9.46484V4.04688H14.9999V1.22647C14.9999 1.14327 14.9834 1.06089 14.9515 0.984053C14.9196 0.907215 14.8728 0.837422 14.8139 0.778662C14.755 0.719901 14.6851 0.673326 14.6082 0.641598C14.5312 0.60987 14.4488 0.593611 14.3656 0.593751Z"
                            fill="#33C481"
                          />
                          <path
                            d="M9.46484 7.5H14.9999V10.9531H9.46484V7.5Z"
                            fill="#107C41"
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_11_13"
                              x1="1.44994"
                              y1="3.20392"
                              x2="6.41075"
                              y2="11.7953"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#18884F" />
                              <stop offset="0.5" stop-color="#117E43" />
                              <stop offset="1" stop-color="#0B6631" />
                            </linearGradient>
                          </defs>
                        </svg>
                        {myCampaignDetail?.communityList?.filter(
                            (community: any) =>
                              community.uploadMethod === "Excel"
                          )
                          .reduce(
                            (sum: number, community: any) =>
                              sum + community?.contactCount,
                            0
                          )}{" "}
                        Contacts uploaded from Excel
                      </div>
                    ) : null}
                    {myCampaignDetail?.replyAssignTo > 0 ? (
                      <ReplyAssignedTo campaign={myCampaignDetail} />
                    ) : null}
                  </div>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      !isCampaignCreationEnabled ? (
                        <Tooltip placement="bottom" id="no-coins">
                          You don't have enough credits
                        </Tooltip>
                      ) : !isCampaignCreationEnabled_Plan ? (
                        <Tooltip placement="bottom" id="no-coins">
                          Maximum Campaign limit exceeded for Standard plan.
                        </Tooltip>
                      ) : (
                        <></>
                      )
                    }
                  >
                    <Button
                      className="sendButton w-50 d-flex justify-content-center m-auto align-items-center"
                      onClick={() => repeatCampaignHandler(myCampaignDetail)}
                      disabled={
                        !isCampaignCreationEnabled ||
                        !isCampaignCreationEnabled_Plan
                      }
                      style={{
                        cursor: !isCampaignCreationEnabled
                          ? "not-allowed"
                          : !isCampaignCreationEnabled_Plan
                          ? "not-allowed"
                          : "pointer",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                        className="mr-1"
                      >
                        <path
                          d="M10.5 3.75L12 5.25L10.5 6.75"
                          stroke="white"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M11.5 5.25H5C4.33757 5.25198 3.70283 5.51601 3.23442 5.98442C2.76601 6.45283 2.50198 7.08757 2.5 7.75V8.25M6.5 12.25L5 10.75L6.5 9.25"
                          stroke="white"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M5.5 10.75H12C12.6624 10.748 13.2972 10.484 13.7656 10.0156C14.234 9.54717 14.498 8.91243 14.5 8.25V7.75"
                          stroke="white"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Repeat Campaign
                    </Button>
                  </OverlayTrigger>
                </Col>
                <Col md={6}>
                  { myCampaignDetail?.template ? (
                    <TemplateCard
                      readOnly
                      template={previewTemplate}
                      isInvalidAccess={false}
                      // templateVariables={myCampaignDetail.templateText}
                    />
                  ) : (
                    "No template"
                  )}
                </Col>
              </Row>
            </>
          )}
        </OffcanvasBody>
      </Offcanvas>
      <div className="row campaignLsitContaner">
        <div className="col-md-6 col-12">
          <h6>Campaign Overview</h6>
        </div>
        <div className="col-md-6 col-12 rightSideContainer">
          <SyncButton
            onClick={() => {
              dashboardForCampaign();
              // campaignOverviewQuery.refetch();
              fetchData(0, 20, "", 0);
              // campaignFilterQuery.refetch();

              campaignCountQuery.refetch();
              totalCreditsQuery.refetch();
              setTimeout(() => {
                toast("success", "Campaign synced successfully");
              }, 1000);
            }}
          />
          <OverlayTrigger
            placement="bottom"
            overlay={
              !isCampaignCreationEnabled ? (
                <Tooltip placement="bottom" id="no-coins">
                  You don't have enough credits
                </Tooltip>
              ) : !isCampaignCreationEnabled_Plan ? (
                <Tooltip placement="bottom" id="no-coins">
                  Maximum Campaign limit exceeded for Standard plan.
                </Tooltip>
              ) : (
                <></>
              )
            }
          >
            <div
              style={{
                cursor: !isCampaignCreationEnabled
                  ? "not-allowed"
                  : !isCampaignCreationEnabled_Plan
                  ? "not-allowed"
                  : "pointer",
              }}
            >
              <Button
                color="primary"
                className="sendButton ml-2"
                onClick={createToCampaign}
                disabled={
                  !isCampaignCreationEnabled || !isCampaignCreationEnabled_Plan
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15.834 9.16658H10.834V4.16659C10.834 3.70575 10.4607 3.33325 10.0007 3.33325C9.54065 3.33325 9.16732 3.70575 9.16732 4.16659V9.16658H4.16732C3.70732 9.16658 3.33398 9.53909 3.33398 9.99992C3.33398 10.4608 3.70732 10.8333 4.16732 10.8333H9.16732V15.8333C9.16732 16.2941 9.54065 16.6666 10.0007 16.6666C10.4607 16.6666 10.834 16.2941 10.834 15.8333V10.8333H15.834C16.294 10.8333 16.6673 10.4608 16.6673 9.99992C16.6673 9.53909 16.294 9.16658 15.834 9.16658Z"
                    fill="white"
                  />
                </svg>
                New Campaign
              </Button>
            </div>
          </OverlayTrigger>
        </div>
      </div>
      {campaignCount}
      {channelUid && <>{campaignOverview}</>}
      <div className="row campaignTableHeader">
        <div className="col-md-3 col-12">
          <h6>Campaign History</h6>
        </div>
        <div className="col-md-9 col-12 rightSideCon">
          {/* <Button className='exportBtn'>
                        <i className="fa fa-download"></i>
                        Active Export
                    </Button> */}
          {
            // !campaignFilterQuery.isError
            campaignOverviewQuery ? (
              <>
                <div className="col-3">
                  <div className="">
                    <Input
                      className="dateFilter"
                      type="select"
                      size={1}
                      value={dateFilter}
                      style={{ fontFamily: '"Public Sans", sans-serif' }}
                      onChange={changeDateFilterHandler}
                    >
                      <option value={DateFilterType.Past7Days}>
                        Past 7 days
                      </option>
                      <option value={DateFilterType.Past30Days}>
                        Past 30 days
                      </option>
                      <option value={DateFilterType.Past90Days}>
                        Past 90 days
                      </option>
                      <option value={DateFilterType.ThisWeek}>This week</option>
                      <option value={DateFilterType.PreviousWeek}>
                        Previous week
                      </option>
                      <option value={DateFilterType.ThisMonth}>
                        This month
                      </option>
                      <option value={DateFilterType.PreviousMonth}>
                        Previous month
                      </option>
                      <option value={DateFilterType.CustomRange}>
                        Custom range
                      </option>
                    </Input>
                  </div>
                </div>
                <div
                  className="form-control calenderInput col-3 ps-1 pe-0"
                  ref={datepickertarget}
                  onClick={() => {
                    setShowDatePicker((current) => !current);
                    // setSearch("");
                    // setStatusFilters([]);
                  }}
                >
                  <BsCalendar />
                  <span className="value ml-2">
                    {dateRangeValue.start.format("DD/MM/YYYY")}
                    {" - "}
                    {dateRangeValue.end.format("DD/MM/YYYY")}
                  </span>
                </div>
                <Overlay
                  target={datepickertarget.current}
                  show={showDatePicker}
                  placement="bottom"
                >
                  <Popover id="popover-basic" style={{ maxWidth: "350px" }}>
                    <DateRangePicker
                      value={dateRangeValue}
                      onSelect={(value: any) => {
                        setSearch("");
                        // setStatusFilters([]);
                        setDateRangeValue(
                          moment.range(
                            moment(value.start).startOf("day"),
                            moment(value.end).endOf("day")
                          )
                        );
                        setDateFilter(DateFilterType.CustomRange);
                        setShowDatePicker(false);
                        // campaignFilterQuery.refetch();
                        // fetchData(0, 20, "", 0);
                        setIsFilter(false);
                      }}
                      singleDateRange={true}
                    />
                  </Popover>
                </Overlay>

                <div className="col-4">
                <SearchBox
                  placeholder="Search Campaign name"
                  value={search}
                  onChange={(event) => {
                    const { value } = event.target;
                    setSearch(value);
                    setIsFilter(value !== '');
                  }}
                />
                </div>
                <Button
                  ref={filtertarget}
                  onClick={() => setShowFilter((current) => !current)}
                  className="filter"
                  variant=""
                >
                  {statusFilters.length > 0 ? (
                    <BsFunnelFill size={20} />
                  ) : (
                    <BsFunnel size={20} />
                  )}
                </Button>
                <Overlay
                  target={filtertarget.current}
                  show={showFilter}
                  placement="bottom"
                >
                  <Popover>
                    <StatusFilterList
                      filters={statusFilters}
                      setFilters={setStatusFilters}
                      onClose={() => setShowFilter(false)}
                    />
                  </Popover>
                </Overlay>
              </>
            ) : null
          }
        </div>
      </div>
      {campaignHistory}
    </>
  );
};
export default Radium(CampaignList);
