import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import "../../main.scss";
import {
  getConnection,
  updateConnection,
} from "../../../../services/integrationService";
import { useSelector } from "react-redux";
import { wooCommerceCartFlow } from "../../assets";
import WorkFlowHeader from "../../Component/WorkFlowHeader";
import WorkFlowForm from "../../Component/WorkFlowForm";

interface ShiprocketProps {
  integrationUid?: string; // Make it optional if it can be undefined
  activeTab: string;
  connectionUid: string;
  callDb: boolean;
  setCallDb: React.Dispatch<React.SetStateAction<boolean>>;
  setWorkFlowEnable: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  isInvalidAccess: boolean;
}

interface FormValues {
  integrationName: string;
  webhookSecret: string;
  webhookUrl: string;
  channelName: { value: string; label: string } | null;
}

const validationSchema = Yup.object({
  integrationName: Yup.string().required("Integration Name is required"),
  webhookUrl: Yup.string().required("WebHook URL is required"),
  channelName: Yup.object().nullable().required("Channel Name is required"),
});

const CartFlow: React.FC<ShiprocketProps> = (props) => {
  const [initialValues, setInitialValues] = useState<FormValues>({
    integrationName: "",
    webhookSecret: "",
    webhookUrl: "",
    channelName: null,
  });

  const [webhookSecretCopied, setWebhookSecretCopied] = useState(false);
  const [webhookUrlCopied, setWebhookUrlCopied] = useState(false);
  const [activeWorkflowId, setActiveWorkflowId] = useState<number | null>(null);
  const [isWorkFlow, setIsWorkFlow] = useState([]);
  const channelData = useSelector(
    (state: any) => state.cartreducer.channelData
  );
  useEffect(() => {
    if (props.integrationUid) {
      getConnection(props.integrationUid)
        .then((data: any) => {
          setInitialValues({
            integrationName:
              data.integrationConfigurationDTO.integrationName || "",
            webhookSecret:
              data.integrationConfigurationDTO.webhookData.webhookSecret || "",
            webhookUrl:
              data.integrationConfigurationDTO.webhookData.webhookUrl || "",
            channelName: data.integrationConfigurationDTO.channelDto
              ? {
                  value: data.integrationConfigurationDTO.channelDto.uid,
                  label: data.integrationConfigurationDTO.channelDto.name,
                }
              : null,
          });
          props.setWorkFlowEnable(
            Object.keys(data.integrationConfigurationDTO?.channelDto).length >
              0 || false
          );

          setIsWorkFlow(data.workFlows);
        })
        .catch((error) => {
          console.error("Error fetching connection data:", error);
        });
    }
  }, [props.integrationUid, props.callDb]);

  useEffect(() => {
    if (props.callDb) {
      setTimeout(() => {
        props.setCallDb(false);
      }, 1000); // set callDb to false after 2 seconds
    }
  }, [props.callDb]);

  const handleCopy = (
    text: string,
    setCopied: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);
    });
  };

  const handleSubmit = async (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => {
    const data = {
      integrationConfigurationDTO: {
        channelDto: {
          uid: values.channelName?.value,
        },
        integrationName: values.integrationName,
      },
    };

    try {
      const response = await updateConnection(props.connectionUid, data);
      props.setCallDb(true);
      props.setActiveTab("Workflow");
      // formikHelpers.resetForm();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const channelOptions = channelData.map((channel: any) => ({
    value: channel.uid,
    label: channel.name,
  }));

  const findWorkFlowById = (id: number) => {
    const workFlowType = wooCommerceCartFlow.find((types: any) => types.id === id);

    const workFlow = isWorkFlow.find(
      (wf: any) => wf.workFlowType === workFlowType?.value
    );

    return workFlow || null;
  };

  return (
    <>
      {props.activeTab === "Overview" && (
        <div className="shipRocketOverview">
          <div className="headerOverview">
            Experience the effortless revival of abandoned carts through the
            power of automated nudges. With Gallabox, you can recover those lost
            sales and reconnect with potential customers directly on WhatsApp,
            thereby significantly boosting your sales. By leveraging the
            convenience and effectiveness of WhatsApp, you can engage with
            hesitant shoppers, send personalized messages, and offer enticing
            incentives to encourage them to complete their purchases.
          </div>
        </div>
      )}

      {props.activeTab === "Configuration" && (
        <div className="shipRocket-configuration">
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <div className="form-group">
                  <label htmlFor="integrationName">
                    Brand Name
                    <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                  </label>
                  <Field
                    type="text"
                    name="integrationName"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="integrationName"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="channelName">
                    Channel Name
                    <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                  </label>
                  <Select
                    options={channelOptions}
                    name="channelName"
                    value={values.channelName}
                    onChange={(option) => setFieldValue("channelName", option)}
                    className="channel-select"
                  />
                  <ErrorMessage
                    name="channelName"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="webhookUrl">WebHook URL</label>
                  <div className="d-flex">
                    <Field
                      type="text"
                      name="webhookUrl"
                      className="form-control"
                      disabled
                    />
                    <button
                      type="button"
                      className="copyButton"
                      onClick={() =>
                        handleCopy(
                          document.querySelector<HTMLInputElement>(
                            'input[name="webhookUrl"]'
                          )?.value || "",
                          setWebhookUrlCopied
                        )
                      }
                    >
                      <svg
                        className="me-2"
                        stroke="currentColor"
                        fill="none"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        focusable="false"
                        height="1.2em"
                        width="1.2em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
                        ></path>
                      </svg>
                      {webhookUrlCopied ? "Copied" : "Copy"}
                    </button>
                  </div>
                  <ErrorMessage
                    name="webhookUrl"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div
                  className={`d-flex justify-content-center mt-3 ${
                    props?.isInvalidAccess ? "notAllowed" : "pointer"
                  } `}
                >
                  <button
                    type="submit"
                    className="btn btn-primary d-flex justify-content-between"
                    disabled={props?.isInvalidAccess}
                  >
                    Update Configuration
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}

      {props.activeTab === "Workflow" && (
        <div>
          {activeWorkflowId ? (
            <WorkFlowForm
              id={activeWorkflowId}
              title={
                wooCommerceCartFlow.find((wf) => wf.id === activeWorkflowId)?.title ||
                ""
              }
              description={
                wooCommerceCartFlow.find((wf) => wf.id === activeWorkflowId)
                  ?.description || ""
              }
              value={
                wooCommerceCartFlow.find((wf) => wf.id === activeWorkflowId)
                  ?.value || ""
              }
              setActiveWorkflowId={setActiveWorkflowId}
              connectionUid={props.connectionUid}
              channelDtoUid={initialValues.channelName?.value}
              integrationName={initialValues.integrationName}
              workflowData={findWorkFlowById(activeWorkflowId)}
              setCallDb={props.setCallDb}
              isInvalidAccess={props.isInvalidAccess}
            />
          ) : (
            wooCommerceCartFlow.map((el: any) => {
              return (
                <WorkFlowHeader
                  key={el.id}
                  id={el.id}
                  title={el.title}
                  description={el.description}
                  setActiveWorkflowId={setActiveWorkflowId}
                  workflowData={findWorkFlowById(el.id)}
                  connectionUid={props.connectionUid}
                  channelDtoUid={initialValues.channelName?.value}
                  integrationName={initialValues.integrationName}
                  setCallDb={props.setCallDb}
                  isInvalidAccess={props.isInvalidAccess}
                />
              );
            })
          )}
        </div>
      )}
    </>
  );
};

export default CartFlow;
