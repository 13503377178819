import React, {
  RefObject,
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
} from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
  Spinner,
} from "reactstrap";
import { useSelector } from "react-redux";
import "./conversation.scss";
import ProgressBar from "../../common/circularProgressbar";
import Select from "react-select";
import {
  assignConversation,
  assignStatus,
  sendConvImage,
  updatePinState,
} from "../../services/conversationservice";
import axios from "../../utils/axios";
import { sweetalert, toast, toastAlert } from "../../common/alert";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  capitalizeFirstLetter,
  processMobileNumber,
} from "../../common/commonfns";
import ConversationInput from "./conversationInputs";
import { useData } from "../../contexts/tempContext";
import { getBots } from "../../botFlows/services/bots";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import initiateChat from "../../assets/img/initiateChat.png";
import Modall from "../../common/modall";
import Contacts from "./contact";
import FilePlaceholder from "../../botFlows/components/designFlow/nodes/sendMedia/filePlaceholder";
import conversationSendMessageContext from "./conversationSendMessageContext";
import FollowupReminder from "./followupReminder";
import pinFill from "../../assets/img/svgs/pin_fill.svg";
import pinOutline from "../../assets/img/svgs/pin_outline.svg";
import Avatar from "react-avatar";
import TooltipReference from "../../common/tooltip";
import ConversationTags from "./conversationTags";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SourceIcons from "../../common/contactSource";
import NewLineInput from "../../common/newlineInput";
import ConversationDropdown from "./conversationDropdown";
import Messages from "./messages";
import { customStyles, customSelectStyles } from "../../common/react-select-style";
import { sliceText } from "../../common/sliceText";
import { useNavigate } from "react-router-dom";
import { useUserLoginContext } from "../../contexts/userLoginContext";
import { auth } from "../../Firebase/firebase";
import { clearWindowCache } from '../../common/commonfns';
interface ConversationMessagesProps {
  selectedConversation: any;
  messages: any[];
  messagesContainerRef: RefObject<HTMLDivElement>;
  handleScroll: (e: React.UIEvent<HTMLDivElement>) => void;
  socket: any;
  handleTabChange: (tab?: string) => void;
  scrollToBottom: (messageRef: RefObject<HTMLDivElement>) => void;
  allBots: Map<number, string>;
  noContacts: boolean;
  getSingleConversation: (conv: any) => void;
  setFollowupReminder: (conv: any, reminderDuration: number) => void;
  convFromContact?: boolean;
  component?: string;
  getconversation?: (tab: string, filter: boolean, component: string) => void;
  loading: boolean;
  getConvFromContact?: () => void;
  removeConversation?: (convuid: string) => void;
  searchTerm?: string;
  filterApplied?: boolean;
  activeTab?: string;
  isMessageFetching: any;
  closePop?: () => void;
  popoveropen?: any;
  scrollMessages: boolean;
  contactUid?: string;
  handleConvSearch: () => void;
  zohoData?: any[];
}
interface OptionType {
  label: string;
  value: any;
}
interface MemberOption {
  label: string;
  value: number | null;
}
type CustomRef = {
  hideShowConv: (val: boolean) => void;
};
const ConversationMessages = React.forwardRef<
  CustomRef,
  ConversationMessagesProps
>(
  (
    {
      selectedConversation,
      messages,
      messagesContainerRef,
      handleScroll,
      socket,
      handleTabChange,
      scrollToBottom,
      allBots,
      noContacts,
      getSingleConversation,
      setFollowupReminder,
      convFromContact,
      component,
      getconversation,
      removeConversation,
      searchTerm,
      filterApplied,
      activeTab,
      handleConvSearch,
      zohoData,
      ...props // capture any other props if necessary
    },
    ref
  ) => {
    const [currentMessage, setCurrentMsg] = useState("");
    const [selectedMember, setSelectedMember] = useState<OptionType | null>(
      null
    );
    const [errMsg, setErrMsg] = useState("Access Denied");
    const business = useSelector((state: any) => state.cartreducer.business);
    const channelUid = useSelector(
      (state: any) => state.cartreducer.channelUid
    );
    const [memberOpt, setMemberOpt] = useState<MemberOption[]>([]);
    const [isOpen, setIsOpen] = useState(false);
    const assignOptions = ["New", "Pending", "Qualified", "Spam"];
    const [status, setStatus] = useState("");
    const [hidInput, setHideInput] = useState(true);
    const [mediaType, setMediaType] = useState("");
    // const [companyName, setCompanyName] = useState([]);
    const hasMounted = useRef(false);
    const [mediaRes, setMediaRes] = useState({});
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [replyMessage, setReplyMessage] = useState({
      message: null,
      name: null,
      direction: null,
    });
    const [emojiData, setEmoji] = useState({ messageId: null, emoji: null });
    const { messageId, emoji } = emojiData;
    const memberData = useSelector(
      (state: any) => state.cartreducer.memberData
    );
    const mappedMemberData = useSelector(
      (state: any) => state.cartreducer.mappedMemberData
    );
    const [files, setFiles] = useState<
      {
        imgUrl: string;
        imgName: string;
        image: File;
        type: string;
        size: number;
        innerType: "video" | "image" | "document"; // for internal state reference
      }[]
    >([]);
    const [imgCaption, setImgCaption] = useState("");
    const { data, clearData, componentName } = useData();
    const [templateData, setTemplateData] = useState({});
    const [contactModal, setContactModal] = useState(false);
    const [isSelectingTemplate, setIsSelectingTemplate] = useState(false); // track is <ChatCamTemplate /> open
    const [uploadMediaLoading, setUploadMediaLoading] = useState(false);
    const navigate = useNavigate();
    const { setJustLoggedIn } = useUserLoginContext();
    const handleReplySelected = (message: any, name: any, direction: any) => {
      // console.log('props', message);
      setReplyMessage({ message, name, direction });
    };
    const handleEmojiSelected = (messageId: any, emoji: any) => {
      // console.log('fds', messageId, emoji);
      setEmoji({ messageId, emoji });
    }
    const handleScrollClosePopover = () => {
      setPopoverOpen(false);
    };
    useEffect(() => {
      if (Object.keys(data).length) {
        setTemplateData(data);
      }
    }, [data]);
    useEffect(() => {
      if (
        Object.keys(templateData).length &&
        componentName === "conversation"
      ) {
        setMediaType("template");
      }
    }, [templateData, componentName]);
    useEffect(() => {
      setReplyMessage({ message: null, name: null, direction: null });
      if (selectedConversation && Object.keys(selectedConversation).length) {
        setStatus(
          selectedConversation?.status === "OPEN"
            ? "New"
            : capitalizeFirstLetter(selectedConversation?.status)
        );
        let labelValue;
        if (selectedConversation.assignTo === 2) {
          const memberLabel = mappedMemberData.get(
            selectedConversation.assignId
          );
          labelValue = memberLabel
            ? { label: memberLabel, value: `member_${selectedConversation.assignId}` }
            : { label: "Select", value: null };
        } else if (selectedConversation.assignTo === 1) {
          const botLabel = allBots.get(selectedConversation.assignId);
          labelValue = botLabel
            ? { label: botLabel, value: `bot_${selectedConversation.assignId}` }
            : { label: "Select", value: null };
        } else {
          labelValue = { label: "Select", value: null };
        }
        setSelectedMember(labelValue);
      }
    }, [selectedConversation]);
    useEffect(() => {
      const handleKeyPress = (event: KeyboardEvent) => {
        if (event.key === "Enter" && !(event.shiftKey || event.altKey)) {
          if (files.length) {
            uploadImg();
          }
        }
      };
      window.addEventListener("keydown", handleKeyPress);
      return () => {
        window.removeEventListener("keydown", handleKeyPress);
      };
    }, [files, imgCaption]);
    useEffect(() => {
      const memberOptions: MemberOption[] =
        memberData.map((item: any) => ({
          value: item.id,
          label: item.name,
        })) || [];
      setMemberOpt(memberOptions);
    }, []);
    const uploadImg = () => {
      setUploadMediaLoading(true);
      const payload = {
        uid: channelUid.value,
        caption: imgCaption,
        fileData: files[0],
      };
      sendConvImage(payload).then((res) => {
        setUploadMediaLoading(false);
        setMediaRes(res);
        setMediaType("image");
        if (messagesContainerRef.current) scrollToBottom(messagesContainerRef);
      });
    };

    // useEffect(() => {
    //   const fetchCompanyName = async () => {
    //     try {
    //       // Call the getCompanyNameByUid function with the selected conversation's UID
    //       const name = await getCompanyNameByUid(selectedConversation.contact.uid);
    //       setCompanyName(name);
    //     } catch (error) {
    //       console.error('Error fetching company name:', error);
    //       // Optionally handle errors or display a toast message here
    //     }
    //   };

    //   // Call fetchCompanyName when selectedConversation changes
    //   if (selectedConversation && selectedConversation.contact && selectedConversation.contact.uid) {
    //     fetchCompanyName();
    //   }
    // }, [selectedConversation]);

    useEffect(() => {
      if (!hasMounted.current) {
        hasMounted.current = true;
      } else {
        // if (mediaType && !isSelectingTemplate) {
        if (mediaType) {
          setMessage();
        }
      }
    }, [mediaType]);
    useEffect(() => {
      if (noContacts) {
        setErrMsg("Initiate Conversation");
      } else {
        setErrMsg("");
      }
    }, [noContacts]);

    const loadOptions = async (action: string) => {
      try {
        const params = {
          businessUid: business?.business?.uid,
          limit: 1000,
          page: 0,
          status: ["ACTIVE", "PENDING", "INACTIVE"],
        };
        const membersResponse = await axios.post("member/getMembers", params);
        const members = membersResponse.data?.dataDTO || [];
        const memberOptions: MemberOption[] = members.map((item: any) => ({
          value: `member_${item.id}`,
          label: item.name,
          type: 2,
        }));
        const botResponse = await getBots("");
        const botOptions: MemberOption[] = botResponse.bots.map((bot: any) => ({
          value: `bot_${bot.id}`,
          label: bot.name,
          type: 1,
        }));
        const groupedOptions: any = [
          { label: "Members", options: memberOptions },
          { label: "Bots", options: botOptions },
        ];
        setMemberOpt(groupedOptions);
      } catch (error: any) {
        console.error("Error loading options:", error);
      }
    };

    const hideShowConv = (val: boolean) => {
      setFiles([]);
      if (!val) {
        setErrMsg("Access Denied");
      }
      setHideInput(val);
    };
    useImperativeHandle(ref, () => ({
      hideShowConv,
    }));
    const handleInputChange = (message: string) => {
      setCurrentMsg(message);
    };
    const setCaptionMsg = (e: React.ChangeEvent<HTMLInputElement>) => {
      setImgCaption(e.target.value);
    };
    const setMessage = () => {
      const payload = {
        conversationUid: selectedConversation?.uid,
        id: business.id,
        assignTo: 2,
      };
      let mediaTypeSplit = mediaType.split("/");
      if (
        mediaType !== "template" &&
        business.id !== selectedConversation.assignId
      ) {
        assignConversation(payload).then((res) => {
          if (res === "SUCCESS") {
            const convindex = memberOpt.findIndex(
              (item: any) => item.value === business.id
            );
            if (convindex !== -1) {
              setSelectedMember(memberOpt[convindex]);
            } else {
              setSelectedMember(selectedMember);
            }
          }
        });
      }
      // console.log("type of the msg", mediaType)
      const dataToSend =
        mediaType === "text" || mediaType === "notes"
          ? {
            businessId: business.business.uid,
            channelId: channelUid.value,
            conversationUid: selectedConversation?.uid,
            contactId: selectedConversation?.contact?.uid,
            content: currentMessage,
            type: "text",
            subType: mediaType,
            mobileNumber: selectedConversation?.contact?.mobileNumber,
            note: mediaType === "notes",
            assignId: business?.id,
            assignTo: 2,
            reaction: { messageId, emoji },
            replyMessageId: replyMessage.direction,
          }
          : mediaType === "template"
            ? {
              businessId: business.business.uid,
              channelId: channelUid.value,
              conversationUid: selectedConversation?.uid,
              contactId: selectedConversation?.contact?.uid,
              content: currentMessage,
              type: "component",
              subType: "template",
              mobileNumber: selectedConversation?.contact?.mobileNumber,
              template: templateData,
              assignId: business?.id,
              assignTo: 2,
            }
            : {
              businessId: business.business.uid,
              channelId: channelUid.value,
              conversationUid: selectedConversation?.uid,
              contactId: selectedConversation?.contact?.uid,
              //content: currentMessage,
              type: "media",
              subType: files[0]?.innerType ? files[0]?.innerType : 'image',
              mobileNumber: selectedConversation?.contact?.mobileNumber,
              media: mediaRes,
              assignId: business?.id,
              assignTo: 2,
            };
      socket.off("onMessage");
      socket.off("onMessageState");
      if (!(window as any).ConvMsgSending) {
        socket.emit("message", dataToSend);
      }
      (window as any).ConvMsgSending = true;
      setTimeout(() => {
        (window as any).ConvMsgSending = false;
      }, 1000);
      setCurrentMsg("");
      setImgCaption("");
      setMediaType("");
      setTimeout(() => {
        setReplyMessage({ message: null, name: null, direction: null });
      }, 500);
      setFiles([]);
      clearData();
    };
    const handleChange = (option: any) => {
      const payload = {
        conversationUid: selectedConversation?.uid,
        id: option.value.replace(/^.+?_/, ""),
        assignTo: option.type,
      };
      assignConversation(payload).then((res) => {
        if (res === "SUCCESS") {
          if (business.role.type === "OWNER" || business.id === parseInt(option.value.replace(/^.+?_/, "")) || option.type === 1) {
            toastAlert(
              "bottom",
              `This conversation is assigned to ${option.label.substring(
                0,
                10
              )}${option?.label?.length > 10 ? '...' : ''}`,
              "success"
            );
            setHideInput(true);
          } else {
            setHideInput(false);
            if (removeConversation)
              removeConversation(selectedConversation?.uid);
            setErrMsg(
              `You are not authorized to view this conversation as it's assigned to ${option.label}`
            );
          }
        }
      });
      setSelectedMember(option);
    };
    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };
    const assignConStatus = (
      s: string,
      convUid: string,
      prevStatus: string
    ) => {
      const assignPayload = {
        conversationUid: convUid,
        status:
          selectedConversation?.statusType === "BLOCK" &&
            (s === "New" || s === "Pending" || s === "Qualified")
            ? `${s === "New" ? "OPEN" : s.toUpperCase()}&statusType=NONE`
            : s === "New"
              ? "OPEN"
              : s.toUpperCase(),
      };
      assignStatus(assignPayload).then((res) => {
        if (!searchTerm && !filterApplied) {
          if (removeConversation) removeConversation(convUid);
          if (s === activeTab) handleTabChange(s === "New" ? "Open" : s);
        }
        setStatus(s);
      });
    };
    const formatGroupLabel = (data: any) => (
      <div>
        <span>{data.label}</span>
        <hr />
      </div>
    );
    const openContactModal = () => {
      setContactModal(true);
    };
    const closeContactModal = () => {
      setContactModal(false);
    };
    const markConversationAsSpamHandler = async () => {
      sweetalert(
        "question",
        "Are you sure you want to mark this conversation as spam?",
        async () => {
          if (selectedConversation) {
            try {
              await assignStatus({
                conversationUid: selectedConversation.uid,
                status: "SPAM",
              });
              handleTabChange("SPAM");
              setStatus("Spam");
              toast("success", "Conversation marked as spam");
            } catch (error) {
              toast("error", "Conversation failed to mark as spam");
            }
          }
        },
        () => { }
      );
    };
    const toggleBlockHandler = async () => {
      const shouldBlock = selectedConversation?.statusType !== "BLOCK";
      sweetalert(
        "question",
        `Are you sure you want to ${shouldBlock ? "block" : "unblock"
        } this contact`,
        async () => {
          if (selectedConversation) {
            try {
              await assignStatus({
                conversationUid: selectedConversation.uid,
                status: shouldBlock
                  ? "SPAM&statusType=BLOCK"
                  : "SPAM&statusType=NONE",
              });
              handleTabChange("Spam");
              setStatus("Spam");
              toast(
                "success",
                `Conversation ${shouldBlock ? "blocked" : "unblocked"}`
              );
            } catch (error) {
              toast("error", "Failed to " + shouldBlock ? "block" : "unblock");
            }
          }
        },
        () => { }
      );
    };
    const swapAccount = async (position: string, tags: any) => {
      let account = selectedConversation;
      try {
        let contactId;
        if (account.contact) {
          contactId = account.contact.uid;
          // account.contact.contactType = position;
          // const mobileNumber = account.contact.mobileNumber.slice(
          //   account.contact.countryCode.length
          // );
          // account.contact.mobileNumber = mobileNumber;

          // account.contact.uid = business?.business?.uid;
        }
        await axios
          .post(`contact/${contactId}/modifyContact`, { contactType: position, tags: tags })
          .then((res) => {
            if (getconversation && activeTab) {
              getconversation(activeTab, false, 'leadAccount');
              toastAlert("top-end", "Contact updated successfully", "success");
            }
          });
      } catch (error: any) {
        toastAlert("top-end", error.response.data.message, "error");
      }
    };
    const updatePinStateHandler = async (
      conversationUid: string,
      shouldPin: boolean
    ) => {
      try {
        await updatePinState(conversationUid, shouldPin);
        toast("success", "Conversation " + (shouldPin ? "pinned" : "unpinned"));
        if (searchTerm)
          handleConvSearch();
        else
          handleTabChange();
      } catch (error) {
        toast(
          "error",
          "Conversation failed to " + (shouldPin ? "pin" : "unpin")
        );
      }
    };
    function millisecondsToHoursAndMinutes(
      durationInMilliseconds: number
    ): string {
      const hours: number = Math.floor(durationInMilliseconds / 3600000);
      const minutes: number = Math.floor(
        (durationInMilliseconds % 3600000) / 60000
      );
      let formattedDuration: string = "";
      if (hours > 0) {
        formattedDuration += `${hours} ${hours === 1 ? "hr" : "hrs"}`;
      }
      if (minutes > 0) {
        if (formattedDuration.length > 0) {
          formattedDuration += " and ";
        }
        formattedDuration += `${minutes} ${minutes === 1 ? "min" : "mins"}`;
      }
      return formattedDuration;
    }
    // const mobileNumber = processMobileNumber(selectedConversation?.contact?.countryCode, selectedConversation?.contact?.mobileNumber);
    const CustomOption = ({
      innerProps,
      innerRef,
      data,
      isSelected,
      isFocused,
    }: any) => (
      <div
        ref={innerRef}
        {...innerProps}
        className={`custom-option font ${isSelected ? "selected" : ""} ${isFocused ? "focused" : ""
          }`}
      >
        <span className="fontWeight">
          <Avatar
            fgColor="#4c5a80"
            name={data.label}
            round
            size="23"
            textSizeRatio={2.5}
          />
        </span>
        <TooltipReference placement="right" content={data.label} tooltipId={`memberBotDropdown-${data.value}`}>
          <span className={`ml-1 large text-muted`}>{sliceText(data.label, 10)}</span>
        </TooltipReference>
      </div>
    );
    const numberMask = localStorage.getItem('numberMask') === 'true';

    return (
      <React.Fragment>
        <Col
          md={zohoData?.length ? "12" : "8"}
          className={`${hidInput && !noContacts ? "messageColumn" : "assignBg colWidth"
            } mt-2`}
        >
          {hidInput && !noContacts ? (
            <>
              {zohoData?.length ?
                <>
                  <div className="float-right mb-2">
                    <Button className="sendButton ml-2" onClick={() => {
                      setJustLoggedIn(false);
                      localStorage.clear();
                      clearWindowCache();
                      auth.signOut();
                      if (socket)
                        socket.disconnect();
                      navigate(`${process.env.PUBLIC_URL}/login`);
                    }}>Logout</Button>
                  </div>
                  <div className="m-2">
                    <span><img
                      src="/images/whatsapp-icon.svg"
                      alt="WhatsApp"
                      width={20}
                      className=""
                    /></span>
                    <span className="ml-1">{channelUid?.label}</span>
                  </div>
                </> : ''}
              <Card className={`messageCardWidth ${zohoData?.length ? 'zohoCard' : ''}`}>
                <CardHeader className="p-1 bg-white">
                  <div className="contactHeader w-100 mb-0">
                    <Row className="w-100">
                      <Col sm={zohoData?.length ? 6 : 12} lg={6}>
                        <div className="d-flex align-items-center">
                          <div>
                            <div className="contact_names">
                              {selectedConversation?.contact?.name && (
                                <Avatar
                                  fgColor="#4c5a80"
                                  name={selectedConversation?.contact.name}
                                  round
                                  size="35"
                                  textSizeRatio={2.5}
                                />
                              )}
                            </div>
                          </div>
                          <div>
                            <TooltipReference
                              placement="bottom"
                              content={selectedConversation?.contact?.name}
                              tooltipId="name"
                            >
                              <div className="contactFont">
                                {selectedConversation?.contact?.name?.length > 17
                                  ? selectedConversation?.contact?.name?.slice(
                                    0,
                                    17
                                  ) + "..."
                                  : selectedConversation?.contact?.name}
                              </div>
                            </TooltipReference>
                            {selectedConversation?.contact ? (
                              <div className={`${numberMask ? "numberMasking_Blur" : ''} messageFont`} >
                                {processMobileNumber(
                                  selectedConversation?.contact?.countryCode,
                                  selectedConversation?.contact?.mobileNumber
                                )}
                                {/* {mobileNumber?.length > 15 && mobileNumber? mobileNumber?.slice(0,14) + '...' : mobileNumber} */}
                              </div>
                            ) : null}
                            <div>
                              {/* This is based on API {
                              companyName.length > 0 ? 
                              <>
                              {" "}
                                <span className="memberName">
                                  <FcBusinessContact size={15} />{" "}
                                  {companyName.map((el:any) =>el.name)}
                                </span>
                              </>
                              :null
                            } */}
                              {/* This is based on socket io
                             {selectedConversation?.contact?.companyName && (
                              <>
                                {" "}
                                <span className="memberName">
                                  <FcBusinessContact size={15} />{" "}
                                  {selectedConversation?.contact?.companyName}
                                </span>
                              </>
                            )} */}
                            </div>
                          </div>
                          <div className="ms-4">
                            {selectedConversation?.contact?.name && (
                              <div>
                                <Button
                                  color="link"
                                  id="pinConversationTooltip"
                                  style={{ cursor: 'default' }}
                                  onClick={() =>
                                    updatePinStateHandler(
                                      selectedConversation?.uid,
                                      !selectedConversation?.pinned
                                    )
                                  }
                                >
                                  {selectedConversation?.pinned ? (
                                    <img src={pinFill} alt="" />
                                  ) : (
                                    <img src={pinOutline} alt="" />
                                  )}
                                </Button>
                                <UncontrolledTooltip
                                  placement="bottom"
                                  target="pinConversationTooltip"
                                >
                                  {selectedConversation?.pinned
                                    ? "Unpin"
                                    : "Pin"}{" "}
                                  Conversation
                                </UncontrolledTooltip>
                              </div>
                            )}
                          </div>
                          <div>
                            <ConversationTags
                              selectedConversation={selectedConversation}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col
                        sm={zohoData?.length ? 6 : 12}
                        lg={6}
                        className="p-0 d-flex justify-content-end align-items-center"
                      >
                        <div className="d-flex justify-content-end align-items-center">
                          <div className="d-flex">
                            {!selectedConversation?.contact?.source?.includes(
                              "others"
                            ) && (
                                <div className="sourceIcons">
                                  <TooltipReference
                                    placement="bottom"
                                    content={
                                      selectedConversation?.contact?.source
                                        ? selectedConversation?.contact?.source
                                        : "Bizmagnets"
                                    }
                                    tooltipId="source"
                                  >
                                    <SourceIcons
                                      source={
                                        selectedConversation?.contact?.source
                                          ? selectedConversation?.contact?.source
                                          : ""
                                      }
                                    />
                                  </TooltipReference>
                                </div>
                              )}
                            {selectedConversation?.remainingTime > 0 ? (
                              <>
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip className="single-line-tooltip">
                                      The chat will expire in{" "}
                                      {millisecondsToHoursAndMinutes(
                                        selectedConversation?.remainingTime
                                      )}
                                      . Once expired, WhatsApp will only permit
                                      the sending of template messages.
                                    </Tooltip>
                                  }
                                  placement="bottom"
                                  trigger={["hover", "focus"]}
                                >
                                  <button className="no-button">
                                    <ProgressBar
                                      totalMilliseconds={
                                        selectedConversation?.remainingTime
                                      }
                                      width={40}
                                      height={40}
                                      color="#00B598"
                                    />
                                  </button>
                                </OverlayTrigger>
                              </>
                            ) : null}
                            <div className="p-0 me-3">
                              <Select
                                value={selectedMember}
                                onChange={handleChange}
                                options={memberOpt}
                                onFocus={() => loadOptions("onSelect")}
                                className="ml-2 memberWidth"
                                placeholder="Select"
                                formatGroupLabel={formatGroupLabel}
                                components={{ Option: CustomOption }}
                                styles={customSelectStyles}
                              />
                            </div>
                          </div>
                          <div className="d-flex">
                            <Dropdown
                              isOpen={isOpen}
                              toggle={toggleDropdown}
                              className="ml-2 statusDropdown"
                            >
                              <DropdownToggle
                                caret
                                className="assignStatus custom-caret"
                              >
                                {status === "Temp" ? "Select Status" : status}
                              </DropdownToggle>
                              <DropdownMenu>
                                {assignOptions
                                  .filter((item) =>
                                    convFromContact
                                      ? item !== status
                                      : item === "New"
                                        ? "OPEN" !== selectedConversation?.status
                                        : item.toUpperCase() !==
                                        selectedConversation?.status
                                  )
                                  .map((item) => (
                                    <DropdownItem
                                      key={item}
                                      onClick={() =>
                                        assignConStatus(
                                          item,
                                          selectedConversation?.uid,
                                          status
                                        )
                                      }
                                    >
                                      {item}
                                    </DropdownItem>
                                  ))}
                              </DropdownMenu>
                            </Dropdown>
                            {selectedConversation?.contact?.name && (
                              <>
                                {selectedConversation?.status !== "SPAM" ? (
                                  <FollowupReminder
                                    conversation={selectedConversation}
                                    setFollowupReminder={setFollowupReminder}
                                  />
                                ) : null}
                                <ConversationDropdown
                                  component={component}
                                  selectedConversation={selectedConversation}
                                  markConversationAsSpamHandler={
                                    markConversationAsSpamHandler
                                  }
                                  toggleBlockHandler={toggleBlockHandler}
                                  swapAccount={swapAccount}
                                />
                              </>
                            )}
                          </div>
                        </div>
                        {/* ///////// */}
                      </Col>
                      {/* <Col sm="6" lg={component !== 'supportInbox' ? '2' : '3'} className="p-0 d-lg-flex justify-content-lg-end">
                                                <div className={component !== 'supportInbox' ? 'contact-members-sales' : 'contact-members-support'}>
                                                    {
                                                        !selectedConversation?.contact?.source?.includes('others') &&
                                                        <div className="sourceIcons">
                                                            <TooltipReference placement="bottom"
                                                                content={selectedConversation?.contact?.source ?
                                                                    selectedConversation?.contact?.source :
                                                                    'Bizmagnets'}
                                                                tooltipId="source" >
                                                                <SourceIcons source={selectedConversation?.contact?.source ?
                                                                    selectedConversation?.contact?.source : ''} />
                                                            </TooltipReference>
                                                        </div>}
                                                    {selectedConversation?.remainingTime > 0 ? (
                                                        <>
                                                            <OverlayTrigger
                                                                overlay={(
                                                                    <Tooltip className="single-line-tooltip">
                                                                        The chat will expire in {millisecondsToHoursAndMinutes(selectedConversation?.remainingTime)}. Once expired, WhatsApp will only permit the sending of template messages.
                                                                    </Tooltip>
                                                                )}
                                                                placement="bottom"
                                                                trigger={["hover","focus"]}
                                                            >
                                                                <button className="no-button">
                                                                    <ProgressBar
                                                                        totalMilliseconds={selectedConversation?.remainingTime}
                                                                        width={40}
                                                                        height={40}
                                                                        color="#00B598"
                                                                    />
                                                                </button>
                                                            </OverlayTrigger>
                                                        </>
                                                    ) : null}
                                                    <Select
                                                        value={selectedMember}
                                                        onChange={handleChange}
                                                        options={memberOpt}
                                                        onFocus={() => loadOptions("onSelect")}
                                                        className="ml-2 memberWidth"
                                                        placeholder="Select"
                                                        formatGroupLabel={formatGroupLabel}
                                                        components={{ Option: CustomOption }}
                                                        styles={customStyles}
                                                    />
                                                    
                                                </div>
                                            
                                            </Col> */}
                      {/* <Col sm="6" lg={component !== 'supportInbox' ? '3 w-60' : '1'}
                                                className="p-0 d-lg-flex justify-content-lg-end"
                                            >
                                                <div className="d-flex">
                                                {component !== 'supportInbox' &&
                                                    <Dropdown
                                                        isOpen={isOpen}
                                                        toggle={toggleDropdown}
                                                        className="ml-2 statusDropdown"
                                                    >
                                                        <DropdownToggle
                                                            caret
                                                            className="assignStatus custom-caret"
                                                        >
                                                            {status === 'Temp' ? 'Select Status' : status}
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            {assignOptions
                                                                .filter(
                                                                    (item) =>
                                                                        convFromContact ? (item !==
                                                                            status) : item === 'New' ? 'OPEN' !==
                                                                                selectedConversation?.status :
                                                                            item.toUpperCase() !==
                                                                            selectedConversation?.status
                                                                )
                                                                .map((item) => (
                                                                    <DropdownItem
                                                                        key={item}
                                                                        onClick={() =>
                                                                            assignConStatus(item, selectedConversation?.uid, status)
                                                                        }
                                                                    >
                                                                        {item}
                                                                    </DropdownItem>
                                                                ))}
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                }
                                                {selectedConversation?.contact?.name && (
                                                    <>
                                                        {selectedConversation?.status !== "SPAM" ? (
                                                            <FollowupReminder
                                                                conversation={selectedConversation}
                                                                setFollowupReminder={setFollowupReminder}
                                                            />
                                                        ) : null}
                                                        <ConversationDropdown component={component} selectedConversation={selectedConversation}
                                                            markConversationAsSpamHandler={markConversationAsSpamHandler} toggleBlockHandler={toggleBlockHandler}
                                                            swapAccount={swapAccount} />
                                                    </>
                                                )}
                                                </div>
                                            </Col> */}
                    </Row>
                  </div>
                </CardHeader>
                <hr></hr>
                {uploadMediaLoading ? (
                  <div className="convOverlayLoading">
                    <div className="convOverlayLoading__wrapper">
                      <Spinner size="sm" color="primary" />
                    </div>
                  </div>
                ) : null}
                <CardBody
                  className={`${files.length
                    ? `assignBg ${files.length ? "imgUploadHeight" : ""}`
                    : "conversationBgImg"
                    }`}
                >
                  {files.length ? (
                    <>
                      <Button
                        color="link"
                        className="fa fa-times float-right fontSize cursor-pointer"
                        onClick={() => {
                          setFiles([]);
                          setImgCaption("");
                          if (messagesContainerRef.current)
                            scrollToBottom(messagesContainerRef);
                        }}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </Button>
                      <div className="errMsg">
                        {files[0].innerType === "image" ? (
                          <div className="convUploadPreview">
                            <img
                              src={files[0].imgUrl}
                              alt="Uploaded Image Preview"
                              className="uploadConvImg"
                            />
                          </div>
                        ) : files[0].innerType === "video" ? (
                          <div className="convUploadPreview">
                            <video
                              src={files[0].imgUrl}
                              className="uploadConvImg"
                              controls
                            ></video>
                          </div>
                        ) : (
                          <FilePlaceholder
                            mediaType="document"
                            size="large"
                            fileName={files[0].imgName}
                            url={files[0].imgUrl}
                          />
                        )}
                        <div className="alignCaptionInput">
                          <NewLineInput
                            value={imgCaption}
                            placeholder="Enter message"
                            className="convUploadMessage"
                            setValue={setImgCaption}
                          />
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="55"
                            height="55"
                            viewBox="0 0 32 32"
                            fill="none"
                            onClick={() => {
                              uploadImg();
                            }}
                            className="ml-2 convTypesBorder cursor-pointer"
                          >
                            <path
                              d="M16.0013 0.166748C18.0806 0.166748 20.1395 0.576289 22.0605 1.37199C23.9814 2.16769 25.7269 3.33396 27.1972 4.80422C28.6674 6.27448 29.8337 8.01994 30.6294 9.94093C31.4251 11.8619 31.8346 13.9208 31.8346 16.0001C31.8346 20.1993 30.1665 24.2266 27.1972 27.1959C24.2278 30.1653 20.2006 31.8334 16.0013 31.8334C13.922 31.8334 11.8631 31.4239 9.94215 30.6282C8.02116 29.8325 6.27571 28.6662 4.80544 27.1959C1.83612 24.2266 0.167969 20.1993 0.167969 16.0001C0.167969 11.8008 1.83612 7.77355 4.80544 4.80422C7.77477 1.8349 11.802 0.166748 16.0013 0.166748ZM10.0165 9.35711C9.85155 9.28634 9.66797 9.40735 9.66797 9.58687V14.277C9.66797 14.4023 9.76076 14.5083 9.885 14.5248L19.1104 15.7523C19.3998 15.7908 19.3998 16.2094 19.1104 16.2479L9.885 17.4754C9.76076 17.4919 9.66797 17.5979 9.66797 17.7232V22.4133C9.66797 22.5928 9.85155 22.7138 10.0165 22.643L24.9658 16.2298C25.1677 16.1432 25.1677 15.857 24.9658 15.7703L10.0165 9.35711Z"
                              fill="#458d80"
                            />
                          </svg>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="messagesContainer"
                        ref={messagesContainerRef}
                        onScroll={handleScroll}
                      >
                        {props.loading ? (
                          <div className="convLoading__wrapper">
                            <div className="convLoading">
                              <Spinner size="sm" />
                              <div className="loadingText">Loading...</div>
                            </div>
                          </div>
                        ) : (
                          <Messages
                            messages={messages}
                            selectedConversation={selectedConversation}
                            onReplySelected={handleReplySelected}
                            onEmojiSelected={handleEmojiSelected}
                            selectedMember={selectedMember}
                            mappedMemberData={mappedMemberData}
                            isFetching={props.isMessageFetching}
                            closePopover={props.closePop}
                            popopen={props.popoveropen}
                            socket={socket}
                            allBots={allBots}
                            scrollMessages={props.scrollMessages}
                          />
                        )}
                      </div>
                      <conversationSendMessageContext.Provider
                        value={{
                          sendMessage: setMessage,
                          setIsSelectingTemplate,
                        }}
                      >
                        {selectedConversation?.status === "SPAM" &&
                          selectedConversation.statusType === "BLOCK" ? (
                          <div className="blockedConvoMescsage">
                            <FontAwesomeIcon icon={faBan} className="mr-1" />
                            This Contact is Blocked. Click to Unblock
                            <Button
                              color="light"
                              onClick={() => toggleBlockHandler()}
                            >
                              <FontAwesomeIcon icon={faBan} className="mr-1" />
                              Unblock
                            </Button>
                          </div>
                        ) : (
                          <>
                            {replyMessage.message && (
                              <div className="reply-card">
                                <div className="reply-header">
                                  <button
                                    className="close-button"
                                    onClick={() =>
                                      setReplyMessage({
                                        message: null,
                                        name: null,
                                        direction: null,
                                      })
                                    }
                                  >
                                    ×
                                  </button>
                                  <div className="reply-content">
                                    <div className="sender-info">
                                      {/* Assuming replyMessage is an object with name, message, etc. */}
                                      <span className="sender-name">
                                        {replyMessage.name}
                                      </span>
                                      {/* Uncomment and populate these fields if the information is available */}
                                      {/* <span className="sender-number">{replyMessage.senderNumber}</span>
                                    <span className="timestamp">{replyMessage.timestamp}</span> */}
                                    </div>
                                    <p className="message-text">
                                      {replyMessage.message}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                            <div>
                              <ConversationInput
                                handleInputChange={handleInputChange}
                                replyMessage={replyMessage}
                                setMediaType={setMediaType}
                                selectedConversation={selectedConversation}
                                setFiles={setFiles}
                                setMediaRes={setMediaRes}
                                socket={socket}
                                component={'conversationInbox'}
                              />
                            </div>
                          </>
                        )}
                      </conversationSendMessageContext.Provider>
                    </>
                  )}
                </CardBody>
              </Card>
            </>
          ) : (
            <div className="errMsg">
              {!noContacts ? (
                <FontAwesomeIcon
                  icon={faBan}
                  className="fa fa-ban accessDeniedMsg iconsColor"
                />
              ) : (
                <img src={initiateChat} height={35} width={35} />
              )}
              <h5 className="pt-2">{errMsg}</h5>
              {noContacts ? (
                <Button
                  className="sendButton initiateButton"
                  onClick={openContactModal}
                >
                  <span className="pl-2">
                    Select or Add Contacts to Initiate a Chat
                  </span>
                </Button>
              ) : (
                ""
              )}
              <Modall
                isOpen={contactModal}
                onClose={closeContactModal}
                size="md"
                component="initiateConversation"
              >
                <Contacts
                  handleClose={closeContactModal}
                  status="initiateConversation"
                  getSingleConversation={getSingleConversation}
                ></Contacts>
              </Modall>
            </div>
          )}
        </Col>
      </React.Fragment>
    );
  }
);
export default ConversationMessages;
