import axios from "../utils/axios";
import { showAlert, toast, sweetalert } from "../common/alert";

export const getAnalyticsDashboard = async (
  fromTime: number,
  toTime: number,
  channelUid: string
) => {
  try {
    const params = {
      startTime: fromTime,
      endTime: toTime,
      channelUid: channelUid
    };
  
    const response = await axios.get('/analytics/dashboard/summary', { params });
  
    return response.data
  } catch (error: any) {
    console.log(error, "error");
    toast("error", error.message);
  }
};

export const getConversationCharges = async (
  fromTime: number,
  toTime: number,
  channelUid: string
) => {
  try {
    const data = {
      fromTimeUTC: fromTime,
      toTimeUTC: toTime,
      channelId: channelUid, // 'channelId' instead of 'channelUid' to match your backend
    };
  
    const response = await axios.post('/payment/getConversationCharges', data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  
    return response.data;
  } catch (error: any) {
    console.error(error, "error");
    toast("error", error.message);
  }
};