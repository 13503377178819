import React, { Component } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col
} from "reactstrap";
import "./template.scss";
import Select from "react-select";
import { statusOptions } from "../../common/category";
import CreateTemplate from "./createTemplate";
import {
  getAllTemplates,
  syncTemplates,
  deleteTemplate,
  draftPublish,
  defaultTemplates,
} from "../../services/templateService";
import { toast } from "../../common/alert";
import TooltipReference from "../../common/tooltip";
import { sliceText } from "../../common/sliceText";
import { capitalize, searchFilter } from "../../common/commonfns";
import {
  faInfoCircle,
  faList,
  faPlus,
  faSearch,
  faSync,
  faTh,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import Loader from "../../shade/Loaders/Loaders";
import ChatCamTemplate from "./chatCamTemplate";
import { createTemplate } from "../../services/templateService";
import { countryCode } from "../../services/auth/actions";
import phone from "../../assets/img/phonesymbol.png";
import TemplateCard from "./TemplateCard";
import { FaThemeisle } from "react-icons/fa";
import {
  faClone,
  faEye,
  faEdit,
  faTrash,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { Position } from "reactflow";
import { ErrorImage } from "../../common/ErrorMessage";
import SyncButton from "../../common/syncButton";
import SearchInput from "../../common/searchComponent";
import TemplatesTable from "./templateListView";
import {
  Breadcrumb,
  Button,
  Collapse,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

interface TemplateListProps {
  viewType?: string;
  templateModal?: boolean;
  closeModal?: () => boolean;
  tempIdCollections?: (tempId: any, variables: any, showlink: boolean) => void;
  templateVariables?: (
    tempVar: any,
    showlink: boolean,
    templateVariables: any,
    groupedCarousel: any,
  ) => void;
  channelUid: {
    value: string;
    label: string;
  };
  component?: string;
  handleClose?: () => void;
  uploadExcel?: any;
  modalTitle?: (title: string) => void;
  profile: any;
  // callbackChecked?:any;
  selectedConversation?: any; // for using in conversation module
  setModalSize?: (v: string) => void; // for using in conversation module,
  handleSelect?: (tempalte: any) => boolean;
  setSelectTemplateId?: (templateId: string, name: string) => void;
  setTemplateVariables?: (
    templateVariables: [{ variables: {}; media: any, type: string }]
  ) => void;
  setRemainderTemplateId?: (templateId: string, name: string) => void;
  setEndTemplateId?: (templateId: string, name: string) => void;
  setDeleteTemplateId?: (templateId: string, name: string) => void;
  type?: string;
  roleRules: any;
}

class TemplateList extends React.Component<TemplateListProps, any> {
  private syncInterval: NodeJS.Timeout | null = null;
  private nestedTimeoutId: NodeJS.Timeout | null = null;
  state = {
    loading: true,
    activeTab: "myTemplates",
    isPasswordFocused: false,
    category: "",
    status: "",
    redirectToCreateTemplate: false,
    templatesList: [],
    defaultTemplates: [],
    tempTempList: [],
    defaultList: [],
    getAllTemplatesIsLoading: false,
    type: "",
    index: 0,
    viewType: "",
    // templateModal: false,
    tooltips: {
      REJECTED: false,
      APPROVED: false,
      DRAFT: false,
      PENDING: false,
    },
    templateData: [],
    cardSelect: "-1",
    tempId: "",
    isLoading: false,
    singleTemplate: false,
    isSingleTemplateCampaign: false,
    syncLoad: false,
    // isShowPreview:false,
    isError: false,
    searchTerm: null,
    page: 0,
    loadMore: false,
    templatesCount: 0,
    isInvalidAccess: false,
    roleRules: []
  };

  checkInvalidAccess() {
    const { roleRules } = this.props;
    if (roleRules) {
      const isInvalidAccess = roleRules.canViewWhatsappTemplates === true && roleRules.canManageWhatsappTemplates === false;
      this.setState({ isInvalidAccess });
      this.setState({roleRules});
    }
  }

  componentDidUpdate(prevProps: TemplateListProps, prevState: any) {
    if (this.props.channelUid.value) {
      if (this.props.channelUid.value !== prevProps.channelUid.value) {
        this.getStatuscategoryBasedTemplates();
        this.getDefaultTemplates();
      }
    }
    // if(prevState.singleTemplate !== this.state.isSingleTemplateCampaign){
    //   this.setState({isShowPreview:true})
    // }
    if (this.state.roleRules !== this.props.roleRules) {
      this.checkInvalidAccess();
    }
  }
  componentDidMount(): void {

    // if (categoryOptions.length < 4) {
    //   categoryOptions.unshift({ value: "all", label: "All" });
    // }

    setTimeout(() => {
      this.setState({ loading: false });
    }, 1000);
    if (this.props?.viewType) {
      this.setState({ viewType: this.props.viewType });
    } else {
      this.setState({ viewType: "grid" });
    }

    if (this.props.channelUid.value) {
      this.getStatuscategoryBasedTemplates();
      this.getDefaultTemplates();
    }
    if (!this.props.profile.business?.freeTrial) {
      this.syncInterval = setInterval(() => {
        syncTemplates(this.props.channelUid?.value, "false");
        this.nestedTimeoutId = setTimeout(() => {
          this.getStatuscategoryBasedTemplates();
        }, 3 * 60 * 1000);
      }, 4 * 60 * 60 * 1000);
    }
  }

  getStatuscategoryBasedTemplates = () => {
    const tempStatus = this.props.templateModal ||
      this.props.component === "conversation" ||
      this.props.component === "ticket" ||
      this.props.component === "CAMPAIGN" ||
      this.props.component === "APPOINTMENT" ||
      this.props.component === "SHIPROCKET" ? 'APPROVED' : '';
    const tempCategory = this.props.component === 'OTP' ? 'AUTHENTICATION' : '';
    this.setState({ status: tempStatus, category: tempCategory, page: 0 }, () => {
      if (this.state.viewType !== 'list')
        this.getAllTemp()
    })
  }

  getAllTemp = () => {
    this.setState({ isError: false });
    if (this.props.component !== "ticket") {
      if (!this.state.page)
        this.setState({ isLoading: true });
    }
    if (this.props.component !== "conversation") {
      if (!this.state.page)
        this.setState({ isLoading: true });
    }
    const { channelUid } = this.props;
    if (this.state.searchTerm !== null) {
      const alltemplatespayload = {
        "channelId": channelUid?.value,
        "page": this.state.page,
        "limit": 12,
        "search": this.state.searchTerm,
        "types": this.state.status && this.state.status !== 'all' ? [this.state.status.toUpperCase()] : [],
        "category": this.state.category && this.state.category !== 'all' ? [this.state.category.toUpperCase()] : []
      }
      getAllTemplates(alltemplatespayload)
        .then((res) => {
          if (res && res?.templates) {
            if (!this.state.page)
              this.setState({ getAllTemplatesIsLoading: true, isError: false, isLoading: false, templatesCount: res.count });
            // this.setState({ isLoading: false });
            // this.setState({ isError: false });
            let tempdata: any[];
            if (this.state.page)
              tempdata = [...this.state.templatesList, ...res.templates];
            else
              tempdata = res.templates;
            this.setState({ templatesList: tempdata, loadMore: false });
            // const sortedData = [...tempdata].sort((a, b) => {
            //   const dateA = new Date(a.createdAt);
            //   const dateB = new Date(b.createdAt);
            //   return dateB.getTime() - dateA.getTime();
            // });

            // const templateModal = this.props.templateModal;
            // const approvedTemplates = sortedData.filter(
            //   (temp: any) => temp.templateStatus === "APPROVED"
            // );

            // const approvedTemplatesSequence = approvedTemplates.filter(
            //   (temp: any) => !(temp.category === 'MARKETING' && temp?.components.some((component: any) => component?.type === 'CAROUSEL'))
            // );

            // const authenticatedTemplates = approvedTemplates?.filter(
            //   (temp: any) => temp?.category === "AUTHENTICATION");

            // if (
            //   templateModal ||
            //   this.props.component === "conversation" ||
            //   this.props.component === "ticket" ||
            //   this.props.component === "CAMPAIGN" ||
            //   this.props.component === "APPOINTMENT" ||
            //   this.props.component === "SHIPROCKET" ||
            //   this.props.component === "OTP"
            // ) {
            //   if (this.props?.component === "OTP") {
            //     this.setState({
            //       templatesList: authenticatedTemplates,
            //       tempTempList: authenticatedTemplates,
            //       isLoading: false,
            //     });
            //   } else {
            //     this.setState({
            //       templatesList: approvedTemplates,
            //       tempTempList: approvedTemplates,
            //       isLoading: false,
            //     });
            //   }
            //   // }
            // } else {
            //   this.setState({
            //     templatesList: sortedData,
            //     tempTempList: tempdata,
            //     isLoading: false,
            //   });
            // }
          }
          else {
            if (res?.data?.message !== "No templates found") {
              toast("error", res.data.message);
            }
            this.setState({ isError: true });
            this.setState({ TemplateList: '' })
            this.setState({ isLoading: false });
            this.setState({ getAllTemplatesIsLoading: true });
          }
        })
        .catch((err) => {
          if (err?.response?.data?.message !== "No templates found") {
            toast("error", err.response.data.message);
          }
          this.setState({ isError: true });
          this.setState({ TemplateList: '' })
          this.setState({ isLoading: false });
          this.setState({ getAllTemplatesIsLoading: true });
        });
    }
    else {
      this.setState({ isError: true });
      this.setState({ TemplateList: '' })
      this.setState({ isLoading: false });
      this.setState({ getAllTemplatesIsLoading: true });
    }
  };

  onSearchChange = (searchTerm: string) => {
    const searchTermCheck = searchTerm.length > 0 ? searchTerm : '';
    if (this.state.searchTerm !== searchTermCheck) {
      this.setState({ searchTerm: searchTermCheck, page: 0 }, () => {
        if (this.state.viewType !== 'list')
          this.getAllTemp()
      }
      );
    }
  };

  getDefaultTemplates = () => {
    const payload = {
      page: 0,
      limit: 10,
      name: "demo",
    };

    defaultTemplates(payload)
      .then((res: any) => {
        const tempdata = res.data;
        const sortedData = [...tempdata].sort((a, b) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);
          return dateB.getTime() - dateA.getTime();
        });
        this.setState({
          defaultTemplates: sortedData,
          defaultList: tempdata,
          isLoading: false,
        });
      })
      .catch((err) => {
        this.setState({ defaultTemplates: [], isLoading: false });
        console.log("No templates found");
      });
  }

  handleTabChange = (tab: string) => {
    this.setState({ activeTab: tab });
  };

  handlePasswordFocus = () => {
    this.setState({ isPasswordFocused: true });
  };

  handlePasswordBlur = () => {
    this.setState({ isPasswordFocused: false });
  };

  handleCreateTemplate = () => {
    this.setState({ redirectToCreateTemplate: true, type: "create" });
  };

  extractVariableName = (text: string) => {
    const regex = /{{(.*?)}}/g;
    const matches = text.match(regex);
    if (matches) {
      return matches.map((match: string) => match.replace(/{{|}}/g, ""));
    }
    return [];
  };

  wordsExtractedFromSymbols = (symbol: string, text: string) => {
    var regex;
    if (symbol === "*") {
      regex = /\*(.*?)\*/g;
    } else if (symbol === "_") {
      regex = /_([^_]+)_/g;
    } else {
      regex = /~([^~]+)~/g;
    }
    const extractedWords = [];
    let match;
    while ((match = regex.exec(text)) !== null) {
      extractedWords.push(match[1]);
    }
    return extractedWords;
  };

  renderMessage = (values: any, text: string) => {
    let renderedText = text;
    if (values?.example?.body_text?.length) {
      const variableNames = this.extractVariableName(text);
      variableNames.forEach((variableName, index) => {
        const replacement = `<span class="sampleVarColor">${values.example.body_text[0][index]}</span>`;
        renderedText = renderedText.replace(`{{${variableName}}}`, replacement);
      });
    }
    if (renderedText.includes("*")) {
      const boldWords = this.wordsExtractedFromSymbols("*", renderedText);
      boldWords.forEach((b) => {
        const replaceAsBold = `<span class="fontWeight">${b}</span>`;
        renderedText = renderedText.replace(`*${b}*`, replaceAsBold);
      });
    }
    if (renderedText.includes("_")) {
      const italicWords = this.wordsExtractedFromSymbols("_", renderedText);
      italicWords.forEach((i) => {
        const replaceAsItalic = `<i>${i}</i>`;
        renderedText = renderedText.replace(`_${i}_`, replaceAsItalic);
      });
    }
    if (renderedText.includes("~")) {
      const tildeWords = this.wordsExtractedFromSymbols("~", renderedText);
      tildeWords.forEach((t) => {
        const replaceAsTilde = `<span class="strikeThrough">${t}</span>`;
        renderedText = renderedText.replace(`~${t}~`, replaceAsTilde);
      });
    }
    return <div dangerouslySetInnerHTML={{ __html: renderedText }} />;
  };
  componentWillUnmount(): void {
    document.removeEventListener("click", this.handleDocumentClick);
    clearInterval(this.syncInterval as NodeJS.Timeout);
    clearTimeout(this.nestedTimeoutId as NodeJS.Timeout);
  }
  handleDocumentClick = (event: MouseEvent) => {
    // const popoverTarget = this.state.popoverTarget as HTMLElement | null;
    // const isPopoverClicked =
    //   popoverTarget && popoverTarget.contains(event.target as Node);
    // if (!isPopoverClicked && this.state.popoverOpen) {
    //   this.setState({ popoverOpen: false, popoverTarget: null });
    // }
  };
  handleTemplateActions = (action: string, rowData: any) => {
    if (action === "publish") {
      draftPublish(
        rowData.countryCode,
        rowData,
        rowData.id,
        this.props.channelUid.value
      );
    } else {
      this.setState({
        redirectToCreateTemplate: true,
        type: action,
        templateData: rowData,
      });
    }
  };
  delTemplate = (id: string, name: string) => {
    deleteTemplate(id)
      .then((res) => {
        toast(
          "success",
          `Template ${name} deleted successfully. It will take minimum of 15 days to create a template with this name`
        );
        this.setState({ page: 0 }, () => {
          if (this.state.viewType !== 'list')
            this.getAllTemp()
        });
      })
      .catch((err) => {
        toast("error", err.response.data.message);
      });
  };
  changeViewType = (view: string) => {
    this.setState({ searchTerm: '', status: null, category: null }, () => {
      if (view !== 'list')
        this.getAllTemp();
    });
    this.setState({ viewType: view });
  };

  toggleTooltip = (status: string) => {
    this.setState({ [status]: true });
  };

  renderBodyMessage = (text: string, rowData: any) => {
    const replacedSentence = text?.replace(/{{(\d+)}}/g, (match, index) => {
      const wordIndex = parseInt(index, 10) - 1;
      return rowData?.bodyColumns
        ? "{{" + rowData.bodyColumns[wordIndex]?.value + "}}"
        : match;
    });
    return replacedSentence;
  };

  formatActions = (row: any) => {
    return (
      <div className="mr-2">
        <TooltipReference
          placement="bottom"
          content="Duplicate"
          tooltipId={`dupTemplate-${row.original.id}`}
        >
          <FontAwesomeIcon
            icon={faClone}
            className={`iconColor iconFontSize ml-2 ${this.state.isInvalidAccess ? "disabledState" : ""}  `}
            onClick={() => {
              this.handleTemplateActions("duplicate", row.original);
            }}
          />
        </TooltipReference>
        <TooltipReference
          placement="bottom"
          content="View"
          tooltipId={`viewTemplate-${row.original.id}`}
        >
          <FontAwesomeIcon
            icon={faEye}
            className="iconColor ml-2 iconFontSize"
            onClick={() => {
              this.handleTemplateActions("view", row.original);
            }}
          />
        </TooltipReference>

        <>
          <TooltipReference
            placement="bottom"
            content="Edit"
            tooltipId={`editTemplate-${row.original.id}`}
          >
            <FontAwesomeIcon
              icon={faEdit}
              className={`iconColor ml-2 iconFontSize ${this.state.isInvalidAccess ? "disabledState" : ""}`}
              onClick={() => {
                this.handleTemplateActions("edit", row.original);
              }}
            />
          </TooltipReference>
        </>
        <>
          <TooltipReference
            placement="bottom"
            content="Delete"
            tooltipId={`delTemplate-${row.original.id}`}
          >
            <FontAwesomeIcon
              icon={faTrash}
              className={`iconColor ml-2 iconFontSize ${this.state.isInvalidAccess ? "disabledState" : ""}`}
              onClick={() => {
                this.delTemplate(row.original.id, row.original.name);
              }}
            />
          </TooltipReference>
        </>
      </div>
    );
  };

  handleClick = (temp: any, index: number) => {
    this.setState({
      cardSelect: index,
      tempId: temp.id,
      isSingleTemplateCampaign: true,
      index: index,
    });
    // if (this.props.tempIdCollections) {
    //   const { tempIdCollections } = this.props;
    //   tempIdCollections(temp.id);
    // }
  };

  // cardBody = () => {
  //   const { handleClose } = this.props;
  //   if (handleClose) {
  //     handleClose();
  //   }
  // };

  setSelectTemplateId = (templateId: string, name: string) => {
    if (this.props.setSelectTemplateId) {
      this.props.setSelectTemplateId(templateId, name);
    }
  };

  setDeleteTemplateId = (templateId: string, name: string) => {
    if (this.props.setDeleteTemplateId) {
      this.props.setDeleteTemplateId(templateId, name);
    }
  };

  categoryOpt = [
    { value: 'all', label: 'All' },
    { value: 'marketing', label: 'Marketing' },
    //{ value: 'catalog_marketing', label: 'Product Marketing' },
    //{ value: 'coupon_marketing', label: 'Coupon Marketing' },
    //{ value: 'carousel', label: 'Carousel Marketing' },
    { value: 'utility', label: 'Utility' },
    { value: 'authentication', label: 'Authentication (OTP)' }
  ]

  setRemainderTemplateId = (templateId: string, name: string) => {
    if (this.props.setRemainderTemplateId) {
      this.props.setRemainderTemplateId(templateId, name);
    }
  };

  setEndTemplateId = (templateId: string, name: string) => {
    if (this.props.setEndTemplateId) {
      this.props.setEndTemplateId(templateId, name);
    }
  };
  setVariables(variable: [{ variables: {}; media: any, type: string }]) {
    if (this.props.setTemplateVariables)
      this.props.setTemplateVariables(variable);
  }

  handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchCol = [
      "name",
      "language",
      "category",
      "templateStatus",
      "components",
      "createdAt",
    ];
    const listKey =
      this.state.activeTab === "templateLibrary"
        ? "defaultTemplates"
        : "templatesList";
    const searchList =
      this.state.activeTab === "templateLibrary"
        ? this.state.defaultList
        : this.state.tempTempList;

    const searchedarr = searchFilter(event.target.value, searchCol, searchList);

    this.setState({ [listKey]: searchedarr });
  };

  goToSingleTemplate = (ind: number) => {
    this.setState({ singleTemplate: true, index: ind });
  };
  render() {
    const customStyles = {
      control: (provided: any) => ({
        ...provided,
        width: "170",
      }),
      menu: (provided: any) => ({
        ...provided,
        width: "auto",
        maxWidth: 170,
        overflowY: "hidden",
      }),
      menuList: (provided: any) => ({
        ...provided,
        overflowX: "hidden",
        overflowY: "scroll",
        position: "relative",
        left: "8px",
      }),
      menuPortal: (base: any) => ({
        ...base,
        zIndex: 9999,
      }),
    };
    
    const { profile } = this.props;
    const { activeTab, redirectToCreateTemplate, type, cardSelect, tempId } =
      this.state;
    const templateModal = this.props;
    if (redirectToCreateTemplate) {
      return (
        <CreateTemplate
          type={type}
          data={this.state.templateData}
          renderBodyMessage={this.renderBodyMessage}
          viewType={this.state.viewType}
          templateModal={this.props.templateModal}
          uid={this.props.channelUid?.value}
          component={this.props.component}
          handleClose={this.props.handleClose}
          tempIdCollections={this.props.tempIdCollections}
        />
      );
    }
    if (this.state.singleTemplate) {
      return (
        <ChatCamTemplate
          singleTempData={this.state.templatesList[this.state.index]}
          selectedConversation={this.props.selectedConversation}
          handleClose={this.props.handleClose}
          typeOfComponent={this.props.component}
          tempIdCollections={this.props.tempIdCollections}
          templateVariableList={this.props.templateVariables}
          modalTitle={this.props.modalTitle}
          setModalSize={this.props.setModalSize}
          setId={(templateId: string, name: string) =>
            this.setSelectTemplateId(templateId, name)
          }
          setSVariables={(variable: [{ variables: {}, media: any, type: string }]) =>
            this.setVariables(variable)
          }
          setRemainderId={(templateId: string, name: string) =>
            this.setRemainderTemplateId(templateId, name)
          }
          type={this.props.type}
          setEndId={(templateId: string, name: string) =>
            this.setEndTemplateId(templateId, name)
          }
          setDeleteId={(templateId: string, name: string) =>
            this.setDeleteTemplateId(templateId, name)
          }
        />
      );
    }
    return (
      <React.Fragment>
        <div className="ml-3" id="template-page">
          <div className="breadcrumb-header">
            {(templateModal && this.props.templateModal) ||
              this.props.component === "CAMPAIGN" ||
              this.props.component === "conversation" ||
              this.props.component === "ticket" ||
              this.props.component === "SEQUENCE" ||
              this.props.component === "APPOINTMENT" || this.props.component === "SHIPROCKET"
              || this.props.component === "OTP"
              ? (
                <div className="limitMsg1">
                  <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
                  <span>
                    For newly approved templates, please initiate campaign after
                    30 minutes.
                  </span>
                </div>
              ) : (
                <div className="d-flex">
                  <span>
                    <h5>Templates</h5>
                  </span>
                  <span className="ml-2 alignInfo">
                    <h6>
                      <TooltipReference
                        placement="bottom"
                        icon={faInfoCircle}
                        content="Template that gets approved can be used only after 15 minutes of approval"
                        tooltipId="approval"
                      />
                    </h6>
                  </span>
                </div>
              )}
            <div className="searchWidth ml-auto">
              <SearchInput
                component="community"
                onSearchChange={this.onSearchChange}
                placeHolder="Search Templates"
                isSearch={this.state.searchTerm ? true : false} />
            </div>
          </div>

          <div className="float-right pageView">
            {(templateModal && this.props.templateModal) ||
              this.props.component === "CAMPAIGN" ||
              this.props.component === "SEQUENCE" ||
              this.props.component === "conversation" ||
              this.props.component === "ticket" ||
              this.props.component === "APPOINTMENT" || this.props.component === "SHIPROCKET" ||
              this.state.activeTab === "templateLibrary" || this.props.component === "OTP"
              ? (
                <></>
              ) : (
                <>
                  {/* <div
                  id="sync-now-btn"
                  onClick={async () => {
                    try {
                      if (!profile.business?.freeTrial) {
                        this.setState({ syncLoad: true });
                        await syncTemplates(
                          this.props.channelUid?.value,
                          "true"
                        );
                        this.setState({ syncLoad: false });
                        toast("success", "Templates synced successfully");
                        this.getAllTemp();
                      }
                    } catch (error) {
                      console.error("An error occurred:", error);
                    }
                  }}
                  className="h5 mt-2"
                >
                  <TooltipReference
                    placement="bottom"
                    icon={faSync}
                    content="Sync Now"
                    tooltipId="syncTemplate"
                  ></TooltipReference>
                </div> */}

                  <div className="ml-2" id="sync-now-btn" title="Sync Now">

                    <SyncButton
                      onClick={async () => {
                        try {
                          if (!profile.business?.freeTrial) {
                            this.setState({ syncLoad: true });
                            await syncTemplates(
                              this.props.channelUid?.value,
                              "true"
                            );
                            this.setState({ syncLoad: false });
                            toast("success", "Templates synced successfully");
                            this.setState({ page: 0 }, () => {
                              if (this.state.viewType !== 'list')
                                this.getAllTemp()
                            });
                          }
                        } catch (error) {
                          console.error("An error occurred:", error);
                        }
                      }}
                    /></div>
                  <div id="change-view-btn">
                
                      {/* <TooltipReference
                        placement="bottom"
                        icon={faTh}
                        content="Grid View"
                        tooltipId="gridView"
                      ></TooltipReference> */}
                              <span
                           className={`listView rounded mr-2 ${this.state.viewType === "grid" ? "active1 p-2" : "p-2"
                             }`}
                           onClick={() => {
                             this.changeViewType("grid");
                           }}
                           tabIndex={0}
                           id="grid-view"
                         >
                         <div style={{ display: 'inline-block' }}> {/* Optional styling */}
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip>Grid View</Tooltip>}
                      >
                      <div style={{ cursor: 'pointer' }}>
                      {/* <PiInfoLight size={15} /> */}
                      <FontAwesomeIcon icon={faTh} />
                      </div>
                    </OverlayTrigger>
                  </div>
                    </span>
                    <span
                      className={`listView rounded ${this.state.viewType === "list" ? "active1 p-2" : "p-2"
                        }`}
                      onClick={() => {
                        this.changeViewType("list");
                      }}
                      tabIndex={0}
                      id="list-view"
                    >
                      {/* <TooltipReference
                        placement="bottom"
                        icon={faList}
                        content="List View"
                        tooltipId="listView"
                      ></TooltipReference> */}
                           <div style={{ display: 'inline-block' }}> {/* Optional styling */}
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip>List View</Tooltip>}
                    >
                      <div style={{ cursor: 'pointer' }}>
                      {/* <PiInfoLight size={15} /> */}
                      <FontAwesomeIcon icon={faList} />
                      </div>
                    </OverlayTrigger>
                  </div>
                    </span>
                  </div>
                </>
              )}
            {this.state.activeTab !== "templateLibrary" && this.props?.component !== "OTP" && (
              <div>
                <Select
                  className="selectWidth"
                  options={this.categoryOpt}
                  menuPortalTarget={document.body}
                  styles={customStyles}
                  value={this.state.category ? { label: capitalize(this.state.category), value: this.state.category } : null}
                  onChange={(
                    selectedOption: { value: string; label: string } | null
                  ) => {
                    this.setState({ category: selectedOption?.value, page: 0 }, () => {
                      if (this.state.viewType !== 'list')
                        this.getAllTemp()
                    });
                    // var tempList = [];
                    // if (selectedOption?.value !== "all" && selectedOption?.value !== "catalog_marketing" && selectedOption?.value !== "coupon_marketing" && selectedOption?.value !== "authentication" && selectedOption?.value !== "carousel") {
                    //   tempList = this.state.tempTempList.filter(
                    //     (temp: any) =>
                    //       temp.category === selectedOption?.value.toUpperCase()
                    //   );
                    // }
                    // else if (selectedOption?.value === "catalog_marketing") {
                    //   tempList = this.state.tempTempList.filter(
                    //     (temp: any) =>
                    //       temp.category === "MARKETING" && temp.isCatalog
                    //   )
                    // }
                    // else if (selectedOption?.value === "authentication") {
                    //   tempList = this.state.tempTempList.filter(
                    //     (temp: any) =>
                    //       temp.category === "AUTHENTICATION"
                    //   )
                    // }
                    // else if (selectedOption?.value === "carousel") {
                    //   tempList = this.state.tempTempList.filter(
                    //     (temp: any) =>
                    //       temp?.components?.length > 1 && temp.components[1]?.type === "CAROUSEL"
                    //   )
                    // }
                    // else if (selectedOption?.value === "coupon_marketing") {
                    //   tempList = this.state.tempTempList.filter(
                    //     (temp: any) =>
                    //       temp.category === "MARKETING" && temp.isCoupon
                    //   )
                    // }
                    // else {
                    //   tempList = this.state.tempTempList;
                    // }
                    // this.setState({ templatesList: tempList });
                  }}
                  placeholder="Select Category"
                />
              </div>
            )}
            {(templateModal && this.props.templateModal) ||
              this.props.component === "conversation" ||
              this.props.component === "ticket" ||
              this.props.component === "CAMPAIGN" ||
              this.props.component === "SEQUENCE" ||
              this.props.component === "APPOINTMENT" || this.props.component === "SHIPROCKET" ||
              this.state.activeTab === "templateLibrary" || this.props.component === "OTP" ? (
              <></>
            ) : (
              <div>
                <Select
                  className="selectWidth "
                  options={statusOptions}
                  menuPortalTarget={document.body}
                  styles={customStyles}
                  value={this.state.status ? { label: this.state.status.toUpperCase(), value: this.state.status } : null}
                  onChange={(
                    selectedOption: { value: string; label: string } | null
                  ) => {
                    this.setState({ status: selectedOption?.value, page: 0 }, () => {
                      if (this.state.viewType !== 'list')
                        this.getAllTemp()
                    });
                    // var tempList = [];
                    // if (selectedOption?.value !== "all") {
                    //   tempList = this.state.tempTempList.filter(
                    //     (temp: any) =>
                    //       temp.templateStatus ===
                    //       selectedOption?.value.toUpperCase()
                    //   );
                    // } else {
                    //   tempList = this.state.tempTempList;
                    // }
                    // this.setState({ templatesList: tempList });
                  }}
                  placeholder="Select Status"
                />
              </div>
            )}
            <div className="crt-template-btn">
              <Button
                type="button"
                className="createTemplate"
                onClick={this.handleCreateTemplate}
                disabled={this.state.isInvalidAccess}
              >
                <FontAwesomeIcon icon={faPlus} />
                <span className="pl-2">Create Template</span>
              </Button>
            </div>
          </div>

          <Nav tabs className="hideBorder">
            <NavItem className="templateTabs">
              <NavLink
                className={activeTab === "myTemplates" ? "tabActive" : ""}
                onClick={() => this.handleTabChange("myTemplates")}
              >
                My Templates
              </NavLink>
            </NavItem>
            <NavItem className="templateTabs">
              <NavLink
                className={activeTab === "templateLibrary" ? "tabActive" : ""}
                onClick={() => this.handleTabChange("templateLibrary")}
              >
                Template Library
              </NavLink>
            </NavItem>
          </Nav>
          <hr />
          {(this.state.isLoading || (!this.state.isError && !this.state.templatesList.length)) && this.state.viewType !== 'list' ? (
            <div className="centerItemsWithWidth">
              <div className="spinner-border text-success" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <TabContent activeTab={activeTab} className="custom-tab-content">
              <TabPane tabId="myTemplates">
                <div className="tab-pane-content">
                  <Row>
                    {this.state.viewType === "grid" ? (
                      <>
                        {this.state.templatesList.length && !this.state.isError ? (
                          <>
                            {this.state.templatesList.map(
                              (temp: any, index: number) => {
                                return (
                                  <Col
                                    key={temp.id}
                                    sm="12"
                                    md="4"
                                    lg="3"
                                    className="mt-0"
                                  >
                                    <TemplateCard
                                      onClick={() => {
                                        if (
                                          this.props.handleSelect &&
                                          !this.props.handleSelect(temp)
                                        ) {
                                          return;
                                        }
                                        if (
                                          this.props.component &&
                                          this.props.component ===
                                          "conversation"
                                        ) {
                                          this.goToSingleTemplate(index);
                                        } else if (
                                          this.props.component &&
                                          this.props.component === "ticket"
                                        ) {
                                          this.goToSingleTemplate(index);
                                        } else if (
                                          this.props.component &&
                                          this.props.component === "SEQUENCE"
                                        ) {
                                          this.goToSingleTemplate(index);
                                        } else if (
                                          this.props.component &&
                                          this.props.component === "CAMPAIGN"
                                        ) {
                                          this.goToSingleTemplate(index);
                                          // if(this.state.isShowPreview){
                                          //   this.handleClick(temp, index);
                                          // }
                                        }
                                        else if (
                                          this.props.component &&
                                          this.props.component === "SHIPROCKET"
                                        ) {
                                          this.goToSingleTemplate(index);
                                          // if(this.state.isShowPreview){
                                          //   this.handleClick(temp, index);
                                          // }
                                        }
                                        else if (this.props.component &&
                                          this.props.component === "OTP") {
                                          this.goToSingleTemplate(index);
                                        }
                                        else {
                                          if (this.props.component &&
                                            this.props.component === "APPOINTMENT") {
                                            this.goToSingleTemplate(index);
                                          }
                                        }

                                      }}
                                      template={temp}
                                      handleTemplateActions={
                                        this.handleTemplateActions
                                      }
                                      cardSelect={cardSelect}
                                      index={index}
                                      delTemplate={this.delTemplate}
                                      //  cardBody={this.cardBody}
                                      tab="template"
                                      isInvalidAccess={this.state.isInvalidAccess}
                                    />
                                  </Col>
                                );
                              }
                            )}
                            {!this.state.loadMore && this.state.templatesList.length < this.state.templatesCount &&
                              <div className="centerItems mb-2">
                                <Button className="sendButton p-1" onClick={() => this.setState({ loadMore: true, page: this.state.page + 1 },
                                  () => this.getAllTemp()
                                )}>
                                  Load More</Button>
                              </div>}
                            {this.state.loadMore &&
                              <div className="centerItems">
                                <div
                                  className="spinner-border text-success"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              </div>}
                          </>
                        ) : (
                          <div className="noTempFound">
                            <ErrorImage />
                            {/* <Loader /> */}
                          </div>
                        )}
                      </>
                    ) : (
                      <div>
                        <TemplatesTable formatActions={this.formatActions}
                          templatesList={this.state.templatesList} searchTerm={this.state.searchTerm} status={this.state.status}
                          category={this.state.category} renderBodyMessage={this.renderBodyMessage} syncLoad={this.state.syncLoad} />
                      </div>
                    )}
                  </Row>
                </div>
              </TabPane>
              <TabPane tabId="templateLibrary">
                <div className="tab-pane-content">
                  <Row>
                    <>
                      {this.state.getAllTemplatesIsLoading === false ? (
                        <>
                          <div className="centerItemsWithWidth">
                            <div
                              className="spinner-border text-success"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {this.state.defaultTemplates?.length ? (
                            <>
                              {this.state.defaultTemplates.map(
                                (temp: any, index: number) => {
                                  return (
                                    <Col key={temp.id} md="3" className="mt-0">
                                      <TemplateCard
                                        onClick={() => {
                                          if (
                                            this.props.component &&
                                            this.props.component ===
                                            "conversation"
                                          ) {
                                            this.goToSingleTemplate(index);
                                          } else if (
                                            this.props.component &&
                                            this.props.component ===
                                            "ticket"
                                          ) {
                                            this.goToSingleTemplate(index);
                                          } else if (
                                            this.props.component &&
                                            this.props.component === "SEQUENCE"
                                          ) {
                                            this.goToSingleTemplate(index);
                                          } else if (
                                            this.props.component &&
                                            this.props.component === "CAMPAIGN"
                                          ) {
                                            this.goToSingleTemplate(index);
                                            // if(this.state.isShowPreview){
                                            //   this.handleClick(temp, index);
                                            // }
                                          }
                                          else if (
                                            this.props.component &&
                                            this.props.component === "SHIPROCKET"
                                          ) {
                                            this.goToSingleTemplate(index);
                                            // if(this.state.isShowPreview){
                                            //   this.handleClick(temp, index);
                                            // }
                                          }
                                          else if (this.props.component &&
                                            this.props.component === "OTP") {
                                            this.goToSingleTemplate(index);
                                          }
                                          else {
                                            if (this.props.component &&
                                              this.props.component === "APPOINTMENT") {
                                              this.goToSingleTemplate(index);
                                            }
                                          }
                                        }}
                                        template={temp}
                                        handleTemplateActions={
                                          this.handleTemplateActions
                                        }
                                        cardSelect={cardSelect}
                                        index={index}
                                        delTemplate={this.delTemplate}
                                        tab="default"
                                        isInvalidAccess={this.state.isInvalidAccess}
                                      //  cardBody={this.cardBody}
                                      />
                                    </Col>
                                  );
                                }
                              )}
                            </>
                          ) : (
                            <div className="noTempFound">
                              <ErrorImage />
                            </div>
                          )}
                        </>
                      )}
                    </>
                  </Row>
                </div>
              </TabPane>
            </TabContent>
          )}
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    channelUid: state.cartreducer.channelUid,
    profile: state.cartreducer.business,
    roleRules: state.cartreducer.roleRules,
  };
};

export default connect(mapStateToProps)(TemplateList);
