import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import "../../main.scss";
import {
  getConnection,
  updateConnection,
} from "../../../../services/integrationService";
import { useSelector } from "react-redux";
import { workFlowWooCommerce } from "../../assets";
import WorkFlowHeader from "../../Component/WorkFlowHeader";
import WorkFlowForm from "../../Component/WorkFlowForm";

interface WooCommerceProps {
  integrationUid?: string; // Make it optional if it can be undefined
  activeTab: string;
  connectionUid: string;
  callDb: boolean;
  setCallDb: React.Dispatch<React.SetStateAction<boolean>>;
  setWorkFlowEnable: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  isInvalidAccess: boolean;
}

interface FormValues {
  integrationName: string;
  channelName: { value: string; label: string } | null;
}

const validationSchema = Yup.object({
  integrationName: Yup.string().required("Brand Name is required"),
  channelName: Yup.object().nullable().required("Channel Name is required"),
});

const WooCommerce: React.FC<WooCommerceProps> = (props) => {
  const [initialValues, setInitialValues] = useState<FormValues>({
    integrationName: "",
    channelName: null,
  });

  const [activeWorkflowId, setActiveWorkflowId] = useState<number | null>(null);
  const [isWorkFlow, setIsWorkFlow] = useState([]);
  const channelData = useSelector(
    (state: any) => state.cartreducer.channelData
  );
  useEffect(() => {
    if (props.integrationUid) {
      getConnection(props.integrationUid)
        .then((data: any) => {
          setInitialValues({
            integrationName:
              data.integrationConfigurationDTO.integrationName || "",
            channelName: data.integrationConfigurationDTO.channelDto
              ? {
                  value: data.integrationConfigurationDTO.channelDto.uid,
                  label: data.integrationConfigurationDTO.channelDto.name,
                }
              : null,
          });
          props.setWorkFlowEnable(
            Object.keys(data.integrationConfigurationDTO?.channelDto).length >
              0 || false
          );

          setIsWorkFlow(data.workFlows);
        })
        .catch((error) => {
          console.error("Error fetching connection data:", error);
        });
    }
  }, [props.integrationUid, props.callDb]);

  useEffect(() => {
    if (props.callDb) {
      setTimeout(() => {
        props.setCallDb(false);
      }, 1000); // set callDb to false after 2 seconds
    }
  }, [props.callDb]);

  const handleCopy = (
    text: string,
    setCopied: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);
    });
  };

  const handleSubmit = async (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => {
    props.setActiveTab("Workflow");
    const data = {
      integrationConfigurationDTO: {
        channelDto: {
          uid: values.channelName?.value,
        },
        integrationName: values.integrationName,
      },
    };

    try {
      const response = await updateConnection(props.connectionUid, data);
      props.setCallDb(true);
      props.setActiveTab("Workflow");
      // formikHelpers.resetForm();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const channelOptions = channelData.map((channel: any) => ({
    value: channel.uid,
    label: channel.name,
  }));

  function toCamelCase(str: string | undefined): string {
    return (
      str?.split(" ").reduce((acc, word, index) => {
        if (index === 0) {
          return word.toLowerCase();
        } else {
          return (
            acc + word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          );
        }
      }, "") || ""
    );
  }

  const findWorkFlowById = (id: number) => {
    const workFlowType = workFlowWooCommerce.find(
      (types: any) => types.id === id
    );
    const workFlow = isWorkFlow.find(
      (wf: any) => wf.workFlowType === workFlowType?.value
    );
    return workFlow || null;
  };
  return (
    <>
      {props.activeTab === "Overview" && (
        <div className="shipRocketOverview">
          <div className="headerOverview">
            Integrate your WooCommerce Store with WhatsApp to automate order
            updates and shipping updates. Send store notifications on WhatsApp
            to recover abandoned carts and view real-time analytics. Connect
            with your customers on their favourite messaging platform.
          </div>

          <div>
            <h5 className="fw-bold mt-4 mb-4">What can I do?</h5>
          </div>

          <div className="body">
            <div className="overview row">
              <div className="col d-flex align-items-center">
                <div className="me-2 ">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAhCAYAAABX5MJvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAO1SURBVHgBvVhLTBNBGP5mqaIx6hqDiaXGFvSgHmhP6kVK4oMLoTHgQQ6UKAcvUrh6oMQYE0wMePEioRzkoAHbmxIS6gk40Yt4UXYJBUI0UkhQEbrjP8sjrYXuLA+/BLYz+8/M1//xzUwZJDHGNVXFobJVpAMK4AeYCnD3+usU/ekcTKe+qAIlXsKck7JzMysDsfgxHGyiyUPUVCEJDsSJVMd5Vhyzss1LQuMzTQaMsJ3Fc8kw8gwL5fPMliQ+81n3AaS7men2PUGKPBM6x1w9kCEhCByEMZQR7z0EC5ey4rac3v9HYANKqJQ5O7N6MhsiBPtLQMDo0PhsObYi8ZVPt9rJgcXUIro7XqEv8hZ2YSAdEVW30XaIfyIMQDpkZ6Iq700kJ5PrhBbQELpvZ7h7rexh5oeZE1/4NFUCD8qMHk98woPAPRS7z+CC95JJYE6fRX+0H6fUIizjD+bwAyRqVlORL1c8PuZJOdaZ+CGBpD6FuopavHzXhSv+q5v9RSQjR19HgVgMhdXVKKqrwSy+W01HIlhYT89ORePT1bLJ+CL8HA9bW7IICBxGITA4CCwtAb29a20JcBgB8VQMcD8kMRD7gBuBypz+JfwGyAMoKQEaG/ETv2SmMyMgEpRN8OQQlwxHKXNRFSVz+mnDwkkcx0pqGRP6BE55i2VywgRZ+RQi4MUuQfsLvmEeXdEInna2SxMQII0oEzqx481pL8CgqIqscV/kDS6XX81rI0p2MPoe9sBNEikZU1EZt4N38tpcJBIXyi6hk2ylKYDrjORasypR4YWR+AieRawnF1pS5buFsflxyICqs0J4Im5luEDaZnkE2yGOoEBz0KknYYjDWB7UUBgeh1rhO3HRjLvIDZfbtflebGbTehKjH4cxRZ5oIkGThO6kExfTSCwMHJiXGSEWG6DEe9IcxvXqyoz+BcwQiUcd4Rw1tUCEtKfB9LIdwRK466/FZVqsKdxiEqujdj3tojXBWtjBYShu4QmzRBkKwnYGt1OCjsaHTQW95rlCVVNrm4A4jTvXD7+b+WbXG7vFhhfE502xIm80QFIzdg8WdmZcAbIqj85+9eLohf1FgsLoy+zIkm0PO90jWGL/oFMYAv925uwda/eCfSGSULDic25xE9tWCJN8rmwZq9G9uAIYAN1JXc3bvbdUY3EVoEdwJ2Ro8ngavO08OxO3sLOGZl4JUM6RDlqX8drPA7QVxKwWt0Uim5CmrsLhpSuCWgDHWVpMpW9LqmekCpBOeJhHh038Bbn3akTlgY4tAAAAAElFTkSuQmCC"
                    alt=""
                  />
                </div>
                <div>Send Automated Order Notifications Through WhatsApp</div>
              </div>
              <div className="col d-flex align-items-center">
                <div className="me-2 ">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAhCAYAAABX5MJvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAO/SURBVHgBvZdNaBNBFMf/MyabqrSuBxW12I0fBz3Y9KQIYg7iTUwFDyJiWhQ8FFsPehJc0YMo+HETPJgiCH5h/DipYCwI6qWxhxbFmi1UlHpo2gptk3bHN5O2Jk12s+nXD5bO7LzO/PPemzezDBUgRKeObPUhCBambggCOv016EmDwSKDNLhIgvnizB9873Ve5sVIZFP7MDlpknkY3rHouYUAizO2uQ9zFSFGewww/70KFy8Ww4XJtK3tqFRE7tfbcWrqWAiYiEEbOctYQ7poqJS9GEu1AvYtLDwWhSc8OzxsCQXkCRluyPdIgYhcDmgpLDoizqq2Nk73eMEY095hSWARMdbbWiRCjPdeRG7PLxWmqjv5IqjwRJ2sm06dU0+yqxvu+AD/ekDbRM01ub4zOjI1yhsqJ0Tm+wnYLOZkHX/xGvGXb5S5eeGM46zGtj3Ag4fA27fA/v3A0cNA9hdcSLOqLatzIsZ+UC6IsJt18ks3GnYdRF1dbWkBhoHEwydAc/P/l48ekTP+UMN2nngZD0/5y12AWoQW1/UaWF+djgSKbHYlsHYtMDAArFsHrNCAjO0+sZiI+ETmWwhl7CRSgLFpo8qL0M4dJSxokqoscPUq0NUF7KHQTAyWn9jmBgf318Ej9fXbKSw9zgaTg7CGe2F+IG/5KQz2X3ggxJHNroZHQuVEEOblGzBqa8jNHtybQ/ehAsJ7d6Pp/nlYff0lx9NDw3jf8RGxu9dQAZWJkNtUCggfOOZo47aFHbB88PsHMVHedeaV22i//xSdn16pnbJwCIsJkdIxbpdNYyoqePb4Du2M7a52+qoatZM8wxDzMRZM02Fiocy50doSReOR06pY9VFIZhct+U7Xq1X74oVWtLU0wZsInpgq2703aZu3udnKcMh8iN29jiidI6H6HQULSU+lpgqZrKyDvzvhicByg0+piZezlb8yPTRCxaoHiY7PlBcbi8YTHZ/Uo6+qhkfoEryhT+0OeT2n8yPhVr7bWpphWT8RoZBEjx9G5OCBgvF3rx8gevK8ygeZO54Q9m21/kw/018PezyJpYISkgW2qHjO3CeYVvuFeot5t8zHgrbcnFl79qiXY33e+HiE+YLPp7u8yCDAGkna4oWF7kX5AnKvSpArYOLZAnuEvlN5G9OC7cW6XFCXXwET84c+BQMRlXcl4G7/Sdl7CYIHZSZjbsivdRMB3uAkQK0Dj4jRlAFuS8+E4V7i6cuKUU6xOAJol8cCyuBZRIEgmTNjEyFqGQUDk/4kVsLysnA+/wCXsVCieuwPTgAAAABJRU5ErkJggg=="
                    alt=""
                  />
                </div>
                <div>Abandoned cart notification to users</div>
              </div>
            </div>
            <div className="overview row">
              <div className="col d-flex align-items-center">
                <div className="me-2">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAhCAYAAABX5MJvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAOeSURBVHgBvVc/TBNRGP+9o2IcwG4m7RFaURN1ABYEl54D6iYKOLC0RhYXC+6GM7hq6EgioS4O/gkYFxETykR0oQyWQZEaqsTJwqbIPb/vaCut5e4dof6S43j3vt77ve/7fd/7TsADVtdl5Pc2egTQJgRCkHT9RVoKZOl5iuanjwfEF9X3CjeDxVXpb6xHnBYcoqEf6khLYOyELh67GTqSWP0m45YF0+PilcgKDYaTZ6qS4N03HMIUTRo4KAiYLUFxr/pUBZbXZajewlxFvGtKpIxEIf6LNSHgQETbPWiox2RNCTAkzBXSGsp4FfBpXUbFNpL4P8iTWNuKYrU9wToQO1mghNxaFpHOEFp0YV+RrjAyH9LwAD9lXbI4sEmQEKNewvBuIYXTZ9uwkpP21dEZwXLGEwkOgcHFr/A/sPJVrqqQ4MUnHyVKCwb1nZ8skxf0phAaGv3ovR5Fb38MKrComJ3UxbCP2Vjb7gRmZ6Yxag4jPjyC2GC8qg2HKfHARCOR6b7U4/ZKDkOMbsM+IuBuTXjxNInbd0acd9kFHD3qt21VSBD87AQfH0ZSwXpzcwNNBfczODQsxs3NPIUgBr0wxyHhZ6qwJNp8cuc0VMIGvZzDwrrI2Dpotp/zzvWmsK2H4C6iSiQshHwqgizu+NbNqzjXFaGwmOjsMpB4aNrzcRq/IXJM5v3CPBooJLlctuQdJ5Au/JqbEe98oP8CxfgKFjM/8ORZyiZQiYukgfGJabyaWSTdRDHQZ5DHxqACH10cwD2Panb9+MQUecCACjhV2TNnqI6wZ24MDrn+RqNK4aiioN7stRp6A3VjGmnScQWujJnMErxi9vW0kvdID2mtjnpCJ6M+Sr+39EIvacfgFO6+7F4rDgsisWVh3smIq981Sr37pntsixgl247zhkp2ZAN0kmqndJGmgpVysmShcUke6DfsHe4FnuNM4vtd0z0zKBQm3+0DjEpn1FLoJZ6T2pMTY3RgVdcI1xDWAZNWQPYINcDsiVJT8zkn5+RBNrZu2NXmlUjkvsvWn1uoYS6WIUvNULg4KFVM/ZhY0jSoq2//yHMYdj8oK9vhgEiwm1BDaAKxQMWH0D9nhx2n2hDJE4GecFC8rJzY8zPwgD4BiyhlQrXJPU9RDo1Wh3aimcT+kWevaltoD3j9Fq0E1ZEQeWUEnMIqXTkdSvQ3qf1CIhwWeXdzj/i4Jg1fHVq5I0J5qPLbwNKhLaRo4Sw84A8haGDUNpxzPQAAAABJRU5ErkJggg=="
                    alt=""
                  />
                </div>
                <div>Shipping updates through WhatsApp</div>
              </div>
              <div className="col d-flex align-items-center">
                <div className="me-2">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAhCAYAAABX5MJvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAANvSURBVHgBtVi7T+pgFD8fV00MEepqTCw+Bl2EOxg3MFE3A9e4OYibk3AXJ5NbEydd4B+44KCbRhMnh0uvk7rApIuPkuB8K04m2t5zPgryKm0N/SXQ73Ho+fW8vlMY2ISezwsgCNPw/h4DjyeCSwJ+RGNbxY8Cuq7g9RT3ZTY6WrR5a2BWAly5z5fAYdJQbJO1LuMnxSYmzqxEO5LQn54SoGmSI+WtZMgyyU6WaUtCv7sTobc3A4xFoDtQkUySjY8fgB0SnEBfXw4+/d1NSGxsbAc6kXCZQBVJJJKuX/A0bJML3CVASGGshaEdCf3h4ZfTGFDLZUhlMpA9PuZj29C0LM86A9wdhhvy4DALxHAYIjMzfKw8P4N8dOTk57X4qJC4v6dMiDu4AUjpNCilEmT39/l8MBiE/Pk5iMPDdm+hQrkcYKGQ2sOnHdygvr7Cy8sLH/v9fhAGBrjyA3RBznhycgXJOSBAoCK4htd0DwZJFH0ktpMiE39fWgK/z8fnRVT+5/AQ4ltbIG1u1pQSqeDkJDiGrsc4CSQQMZOhp43Oz0Nmb4/Psycn8GNjg6/FV1YayI44s0IF6AEK0B4cBJGRqVyziQfRHVIi0bBGVijc3kIg3JB5EF1YgNT2NnSE1yv2IIEgOEBkdraFGM2Vy0vuliooTpK7uzyFk+vr5jf0eKYpML9+ODWhHTlLMCZ4wEXI19cQQ5dYgJNQwQXIV1c8fiytgY2QayQok8IYP5bQ9SKRkMEFkCviy8vWgl7vkwejswAO8A8roxUoXRmmfXBqykpUYUND3BIHZhJUotU6pVS+Ty8ueOp1Ap2q0cVFsAGZvioH2ONjDn0TaZagvJ9bXcUsqvQ+KpL4jdWTiPy9uYEclvDmwONuwLIut9lrQX+/SJaokKAmQ9NkM9lqERLwDBGMc4ROUXriIpbsepD1qMzHrCyB3Tj2nHM0rLV3ZtZwDYYVaPhZrBij2upKuraBVCXAVdfvoFvWqPUCd1HAjipUv9BQtlkgQJkigXtQ0A2x5sWWs8Po+yToPgpYk0L1bqjpNPuFXipNw9vbKXTjFUDT6J30p9m29QsxvQoAxL9EhjEZPj52kIDcUQxsAANWxEsYUzhuI40VoL8HNO3MSrkjEk2EBPyPIkjNCHz7NoLKBHzaIvpbxXkBg1sBh/gPzm1d23tB6R4AAAAASUVORK5CYII="
                    alt=""
                  />
                </div>
                <div>WhatsApp Broadcast to Targeted Customers</div>
              </div>
            </div>
            <div className="overview row">
              <div className="col d-flex align-items-center">
                <div className="me-2">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAhCAYAAABX5MJvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAANxSURBVHgBvVfPTxNBFP5mu60xUbpnQ0IL8aAHrYlBTnRNxBuWxJsHLYlHtT1ovBCp8WwC8Q+gxYQbhhpvkFA8aTxQPOhF7FaRqwuaEKDs+GbbLi3dXwLLl2x3d+Z15tv3a95j8Am+zJWagsuoYYRLUGlIoSvWmNbp0jiHxoC5sIQS62VVv2szLwGx+U4XMiSYbWzskzURCSEXjrMlL1FXEjsVnoGB3H9t3kkmT5rJuWnGlsTWVx4LhTFFsyqOAZxMFTKQlc+zIvyQMAlEsIh9ex8biEzuVB97DjcSQRKwICEdibOCI4mdVb5MtwSChS7MHOllK7B4NbC9ysdPgICAQqE81zpgkhBmIJXkcEKgvWK79Y9uvpMZvnERCWmnP73/WMLKlzL0Td12XulS8HA0a71Pz+Zxa2jEHHeBHt5EnF1hutygojpJXh1OQDlLqfJCwnlR3v76eraAnu4YktdUuEDZO4Mk3YtyrcJThmEfDcX5OZPAwkwJQcBgZhYuStxw1sJnMoHH17RBW9NM4gLVn/vPjmD1QJC5eODOctNvCuQTnukf8zOL5v3xiyw2/mxg6WkJY4/GkSLfcIHC13mPTARcw3KwP4m7t9NwXanhKzHyg/mZEobuqHiQzuBZJgcv7G0jIRzT1YXFwskBFX4h5D+9K3tFRgsLKBICgG8CDQgSOgJAq5O6IgQ9MBI/iMSrqQlPOV7DhvCJEl1pL+GlDyUzCUWjUbwcm8D9J6PWXHPsMJD/oiyTQcpUPdlCJCptXTOfRQYcHEhac8NDKQrFuhKjNj7wm+Z8+EbZTNthoLAL2H7GpYsJFBfqdhVeH+tOW3Opm67xj7fkD4K4B/Lip36AfeeLlC9UO6kbFPPVX1Uk+1VatAdeqK5VSV4zHXOBEpgbkfBpxNg5VjVJ7FZ4ktJ3yUlY+INYuEoLe0FsGiUzitziYY58pI+ZjmVVVm7aCAJNLYhnK1ntMQhWgYTrQYgCqkmgjcTpONMkyTtUj0zAwET4QMXdlrblOPUFErIIDmVZRkfJ33F2UDk+GQiReid2ncVZh8kd28CtCvUgxrH0IGLTHEXCpJOA4ykqfIT+GG/4iYbDIU9RkHAjIODZlTdBteg9yiUjVISpcKlBGKN8Qx25LGHSTvVHItEK6tYTrGY2MTFrMIwyHQGa341b8Q+smCp5yNLPogAAAABJRU5ErkJggg=="
                    alt=""
                  />
                </div>
                <div>Send Product Catalogues to Users</div>
              </div>
              <div className="col d-flex align-items-center">
                <div className="me-2">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAhCAYAAABX5MJvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAOeSURBVHgBvVdNTBNBFP5mWyUcID0jiVswJnBp8aI3FpSbkT9PkkDlJBdQE70Ji/Eu9aI3ionckKI3USmewMRQLpAoypLg0dh4U2DH95alFujuThvqlyx0d9/MfjPvve+9ESgBm1K27troEhJxIaDTI73gdVZKWPQ8IzSkG4TYUpwWIshgRcpIrY0R+nmbrgjUwaQmzoXFVJChL4nNPTliA2aJHz8Ki3bG8NuZoiR49TV7mKWtNXByMBtDYhwqJNal1E/bWMBhf1eUyCESrv9XKkTAk4hWeEMumKwwAYfEV4q1wgf5ndiQckDYSOH/IEfBGj8IVmcnOA6IgKk6w/aWhcnkBObT6fyzX7kcSkDE3vu3YIcEBeIAFNywvJjBjfY2XL3Qgvm5NN68msu/u9XTjdbGKGamUlABZx6LX54EIRE0KDlu4t7gTQyPjiH74yd6+48P6bjW6djxpQJWX4eEy0ZHAAFe+fS7BVwyDE+7js4uTL/POLYqRDR38ZrtsvHCWjaLmedTePYyjXpdRxDYhomwW5YzmSDzCG9CmIuR9BHvR3fvOC5QIbC2ms3nW+9AAsmHJqaNjO8Y2oR4WGrkClncYNuynEy4ThMGoSkWc9zA1wFqIhEna2oj3qWHapMeJgK6l8E6rayZJlfBg8cTKAeaRETzM+B4aIrFUWmE6WKVKatUfyTd4LT1Q2//gG9G7ZMQREIWJ1F/VsfSh8WiAy/SxMPwx+STCayTO31JCOo3Nmw5SxlSNE05qFgFV0icykFrg44XlK5+maVp6NJCEhkvA45qjnpVKS7ETCqFM/TxoNSuojZQfP4j46GQ00MUxRIJzlBvN15/WlHSCgandl+7gackcM1x38C2qLeIaudPi6zw2Q32Z2J4BH2X25zJVQgM9XShh7QlgACnp+n85z8i5N9HjIyZ6KEoZyJ+rmEX8A5coRrCYwJgVYX2F58X7G+7ckEGNLa8yvuDCXzf2nL0o8kVsm26f0tKyffDo2ZgSrrIt3l5EttSxn7byKqMZjIsZKyoDE5lTlnVmIEbCwc3h0qXe84oT3/Vkaum1q6u4BxySLajIZEE1Nu8ckC6kKg7chA6VjtcP5k4eeRYmKJCzB194dlJnNAR8AAWucCo8zgKelZRdg0xb6GfKZQPLo4mz1NX6ln0KKgF06kDGqOfBlQOR1SUqCim6ONJ2v5csHmJ+LIjjbCGmL1PptBVuT2J1VM7yESrhYUS8Bf1rl4l79slcAAAAABJRU5ErkJggg=="
                    alt=""
                  />
                </div>
                <div>Send Product Catalogues to Users</div>
              </div>
            </div>
            <div className="overview">
              <div className="me-2">
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAhCAYAAABX5MJvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAM4SURBVHgBvVdNTxNRFD1vLBgXkLptGmmFFRvKSnaURFkYjY2yILiguCC6ogtkpVj1D7T+AD42xgVEjIYFmlB2uGLYwEJxhtgfMGFnhHncO2Wa6VDmi5aTNJ2P13dP77nvvPsEQkD7K4ePTeSEREYAKdQ+NlQJ6EKgIjqwdjshDgNOC+E3YEeT8W4FM5Ao0G0cwaFKgVJfj1j2G+hJQjuUM6ZEMWRwN3TRiaxXZpqS4H/fJfCZXmbRKggUe3vE2+avXNjXZKpTYBONereVSAMJS3+BnbYQ8CCiOG9IgsW2EmBQjR1QraGB1xl+H8pJWnpLuBoYVKwZu1gtEq2og5/bFeztqdb1vdEcksmU53jylEpfSozwtSVHp4LJyxAol4p4OTuFalW3iEyMZ7G4UPL8Da88Nj++jp3RyiMiONj3jTV8W99Bd3fNTpjMg/uDeDKWrz9rBnZf+toSzMY8QQURwQE5kDvY+3cFS5KpZwWvnxu9KXEzZtbYRIaX9kdHBnwQ5yQovBkhJBZIgonxETyklM/N5q1suIP/IImGhrK+c1ESMjEZsiC5CLkGXs2XLAm2aVW8mM7h67paJ/CUCvMx1cOdICQovjjQpURA2AQ+fqo0LTibwF1aojOFYrBJBZYUBISbgFvvSATOwEuUZ4uHIcB4Pl0zJF6GTOADjYlCgMFyaPCoC9Z8jozI6QMMLkb2iH0ypy56zi45RoTCM0CRC1MVHiTYjkdHH52rAc7C63lvVwwCRUBVrglvo+q6wPFYAvfSjILrMSLx38SW1yBO8erKckNAuwhXV5ZwSegJ2kmtXfSPLjelRyvH5rS8UKZ1P2zJwkXKPhClCJ0gKfJpaoQtEtTQTpo+vQRnYoOCM/r7M4Hc0Af6DWqA65lg+GWj5XC0eXUS1aoc+HcMFVcDnXbPtH1Td8xkUuySRgW0HwbL4HzQYNtUJGVOE9oIRUE+4ToInds7LJ3aQ8QgArn0LfHF/eLCY2CLjoA26iuh2csLd1GWRpEY5K0W0WFwVnmeRNizqBsaHQlMBW+oIc4iWBOkW32CiXI6LXx7vEAknPilyWxMwYBZI+OUyjgxsdtB5wkKrCMETgHcR1H2xuJi+gAAAABJRU5ErkJggg=="
                  alt=""
                />
              </div>
              <div>Send Feedback and Review Links to Customers</div>
            </div>
          </div>
        </div>
      )}

      {props.activeTab === "Configuration" && (
        <div className="shipRocket-configuration">
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <div className="form-group">
                  <label htmlFor="integrationName">
                    Connection Name
                    <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                  </label>
                  <Field
                    type="text"
                    name="integrationName"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="integrationName"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="channelName">
                    Channel Name
                    <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                  </label>
                  <Select
                    options={channelOptions}
                    name="channelName"
                    value={values.channelName}
                    onChange={(option) => setFieldValue("channelName", option)}
                    className="channel-select"
                  />
                  <ErrorMessage
                    name="channelName"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className={`d-flex justify-content-center mt-3 ${props?.isInvalidAccess ? "notAllowed" : "pointer"} `}>
                  <button
                    type="submit"
                    className="btn btn-primary d-flex justify-content-between"
                    disabled={props?.isInvalidAccess}
                  >
                    Update Configuration
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}

      {props.activeTab === "Workflow" && (
        <div>
          {activeWorkflowId ? (
            <WorkFlowForm
              id={activeWorkflowId}
              title={
                workFlowWooCommerce.find((wf) => wf.id === activeWorkflowId)
                  ?.title || ""
              }
              description={
                workFlowWooCommerce.find((wf) => wf.id === activeWorkflowId)
                  ?.description || ""
              }
              setActiveWorkflowId={setActiveWorkflowId}
              connectionUid={props.connectionUid}
              channelDtoUid={initialValues.channelName?.value}
              integrationName={initialValues.integrationName}
              workflowData={findWorkFlowById(activeWorkflowId)}
              setCallDb={props.setCallDb}
              value={
                workFlowWooCommerce.find((wf) => wf.id === activeWorkflowId)
                  ?.value || ""
              }
              isInvalidAccess={props.isInvalidAccess}
            />
          ) : (
            workFlowWooCommerce.map((el: any) => {
              return (
                <WorkFlowHeader
                  key={el.id}
                  id={el.id}
                  title={el.title}
                  description={el.description}
                  setActiveWorkflowId={setActiveWorkflowId}
                  workflowData={findWorkFlowById(el.id)}
                  connectionUid={props.connectionUid}
                  channelDtoUid={initialValues.channelName?.value}
                  integrationName={initialValues.integrationName}
                  setCallDb={props.setCallDb}
                />
              );
            })
          )}
        </div>
      )}
    </>
  );
};

export default WooCommerce;
